import React from "react";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import Text from "../../../../../../core/src/components/Text/index";
import Row from "../../../../../../core/src/components/Row";
import Button from "../../../../../../core/src/components/Button";
import Modal from "../../../../../../core/src/components/Modal";
import Form from "./Form";
import Firmware from "./Firmware";
import { getRolePermissions } from "../../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  options: {
    width: 24
  },
  options_bar: {
    display: "flex",
    paddingBottom: unit * 4,
    width: "100%",
    justifyContent: "flex-end"
  }
});

const GroupsFirmwareList = ({ children, product, group, refetch }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const [visable, setVisable] = React.useState(false);

  return (
    <div className="">
      <div className={cx(styles.options_bar)}>
        {permissions.groups.includes("CREATE") && (
          <Button onClick={() => setVisable(true)}>Add</Button>
        )}
      </div>
      <div className={cx(styles.header)}>
        <Row
          after={
            <div className={cx(styles.header_children)}>
              <div className={cx(styles.header_items)}>
                <Text small>Status</Text>
              </div>
              {(permissions.groups.includes("UPDATE") ||
                permissions.groups.includes("DELETE")) && (
                <div className={cx(styles.options)} />
              )}
            </div>
          }
        >
          <Text small>Firmware + Description</Text>
        </Row>
      </div>
      {children}
      {permissions.groups.includes("CREATE") && visable && (
        <Modal
          title={"Add a firmware assignment to group"}
          onClose={() => setVisable(false)}
        >
          <Form
            product={product}
            group={group}
            refetch={() => refetch()}
            onClose={() => {
              setVisable(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export { Firmware };
export default GroupsFirmwareList;
