import Core from "../../src";

export default function formatPrice(price, currency, options) {
  if (!options) {
    options = {};
  }

  const locale = options.locale || Core.locale();
  const priceNum = Number(price);
  const negative = priceNum < 0;
  let amount = priceNum; // Prepare the amount

  if (options.round) {
    amount = Math.round(amount);
  } else if (options.precision) {
    amount = parseFloat(Number(price).toFixed(options.precision));
  } // We dont want to show the minus sign

  if (negative) {
    amount = Math.abs(amount);
  }

  let amountStr = ""; // Format as a currency

  if (currency) {
    amountStr = amount.toLocaleString(locale, {
      style: "currency",
      currency,
      currencyDisplay: options.display || "symbol"
    }); // Format as a number
  } else {
    amountStr = amount.toLocaleString(locale);
  } // Remove ending zeros

  if (options.trimTrailingZeros) {
    amountStr = amountStr.replace(/\.0+$/, "");
  } // Wrap the value in parenthesis if negative

  if (negative) {
    amountStr = "(" + amountStr + ")";
  }

  return amountStr;
}
