import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import Row from "../../../../core/src/components/Row";
import MenuToggle from "../../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../icons/src/interface/IconMenuDots";
import DateTime from "../../../../core/src/components/DateTime";
import Spacing from "../../../../core/src/components/Spacing";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Transaction = ({ children, transaction, product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <Row>
        <div
          className={cx({
            display: "flex",
            alignItems: "center",
            width: "100%"
          })}
        >
          <Link
            className={cx({ width: "100%" })}
            to={`/sales/transactions/${transaction.id}`}
          >
            {children}
          </Link>

          <Spacing left={4}>
            <MenuToggle
              accessibilityLabel="Actions"
              closeOnClick
              toggleIcon={
                <IconMenuDots decorative color={theme.color.core.neutral[4]} />
              }
              toggleLabel="Actions"
              zIndex={10}
              onShow={handleShow}
              onHide={handleHide}
            >
              <MenuItem href={`/sales/transactions/${transaction.id}`}>
                View
              </MenuItem>
            </MenuToggle>
          </Spacing>
        </div>
      </Row>
    </div>
  );
};

export default Transaction;
