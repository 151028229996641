import React from "react";
import _pt from "prop-types";
import useStyles from "../../../../core/src/hooks/useStyles";

const styleSheet = ({ breakpoints, color, unit }) => ({
  layout: {
    display: "flex",
    width: "100%",
    height: "100vh",
    justifyContent: "space-between",
    overflow: "hidden"
  },

  main: {
    flexGrow: 1,
    padding: unit * 2,
    background: `linear-gradient(to top,${color.accent.base} 0% ,#ffffff 100%)`,
    overflowY: "auto"
  },

  main_noBackground: {
    background: "transparent"
  },

  main_noPadding: {
    padding: 0
  },

  main_relative: {
    position: "relative"
  },

  mainContent: {
    maxWidth: breakpoints.medium,
    margin: "0 auto"
  }
});

const Layout = ({
  after,
  before,
  children,
  fluid,
  noBackground,
  noPadding,
  noMaxwidth,
  relative
}) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className={cx(styles.layout)}>
      {before}

      <main
        role="main"
        className={cx(
          styles.main,
          noBackground && styles.main_noBackground,
          noPadding && styles.main_noPadding,
          relative && styles.main_relative
        )}
      >
        <div className={cx(!fluid && !noMaxwidth && styles.mainContent)}>
          {children}
        </div>
      </main>

      {after}
    </div>
  );
};

Layout.propTypes = {
  children: _pt.any.isRequired,
  fluid: _pt.bool,
  noBackground: _pt.bool,
  noPadding: _pt.bool,
  noMaxwidth: _pt.bool,
  after: _pt.node,
  before: _pt.node,
  relative: _pt.bool
};

Layout.defaultProps = {
  after: false,
  before: false,
  fluid: false,
  noBackground: false,
  noMaxwidth: false,
  noPadding: false,
  relative: false
};

export default Layout;
