import gql from "graphql-tag";

const GET_FEATURES = gql`
  query allProductFeatures($productid: ID!) {
    allProductFeatures(productid: $productid) {
      edges {
        node {
          id
          name
          description
          deactivated
          defaultfeature
        }
      }
    }
  }
`;

const UPDATE_FEATURE = gql`
  mutation updateFeature(
    $id: ID!
    $productid: ID!
    $name: String
    $description: String
    $deactivated: Boolean
    $defaultfeature: Int
  ) {
    updateFeature(
      featureData: {
        id: $id
        productid: $productid
        name: $name
        description: $description
        deactivated: $deactivated
        defaultfeature: $defaultfeature
      }
    ) {
      feature {
        id
        name
        description
        deactivated
        defaultfeature
      }
    }
  }
`;

const DESTROY_FEATURE = gql`
  mutation destroyFeature($productid: ID!, $id: ID!) {
    destroyFeature(data: { productid: $productid, id: $id }) {
      feature {
        id
        __typename
      }
      __typename
    }
    __typename
  }
`;

const GET_RESERVED_FEATURES = gql`
  query allReservedFeatures($id: ID!) {
    allReservedFeatures {
      edges {
        node {
          rowid
          id
          name
          productreserved(productid: $id) {
            edges {
              node {
                id
                deactivated
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_PRODUCT_RESERVED_FEATURE = gql`
  mutation createProductReservedFeature(
    $productid: ID!
    $reservedfeatureid: ID!
  ) {
    createProductReservedFeature(
      data: { productid: $productid, reservedfeatureid: $reservedfeatureid }
    ) {
      productReservedFeature {
        id
        deactivated
      }
    }
  }
`;

const CREATE_FEATURE = gql`
  mutation createFeature(
    $productid: ID!
    $name: String!
    $description: String!
    $defaultfeature: Int!
    $deactivated: Boolean!
  ) {
    createFeature(
      featureData: {
        productid: $productid
        name: $name
        description: $description
        defaultfeature: $defaultfeature
        deactivated: $deactivated
      }
    ) {
      feature {
        id
        name
        description
        deactivated
        defaultfeature
      }
    }
  }
`;

const UPDATE_RESERVED_FEATURE = gql`
  mutation updateProductReservedFeature(
    $id: ID!
    $productid: ID!
    $deactivated: Boolean
  ) {
    updateProductReservedFeature(
      data: { id: $id, productid: $productid, deactivated: $deactivated }
    ) {
      productReservedFeature {
        id
        deactivated
      }
    }
  }
`;

export {
  GET_FEATURES,
  CREATE_FEATURE,
  UPDATE_RESERVED_FEATURE,
  UPDATE_FEATURE,
  CREATE_PRODUCT_RESERVED_FEATURE,
  GET_RESERVED_FEATURES,
  DESTROY_FEATURE
};
