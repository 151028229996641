import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CREATE_MANUFACTURE_ACCOUNT } from "./queries";
import useStyles, { aesthetic } from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import IconObviousLogo from "../../../icons/src/interface/IconObviousLogo";
import Title from "../../../core/src/components/Title";
import Input from "../../../core/src/components/Input";
import Spacing from "../../../core/src/components/Spacing";
import Button from "../../../core/src/components/Button";
import Link from "../../../core/src/components/Link";
import IconCaretRight from "../../../icons/src/interface/IconCaretRight";
import Divider from "../../../core/src/components/Divider";
import { validateEmail, primaryColorGenerator } from "../../../helpers";
import Alert from "../../../core/src/components/Alert";
import ResponsiveImage from "../../../core/src/components/ResponsiveImage";
import history from "../../../history";
import CheckBox from "../../../core/src/components/CheckBox/index";
import Text from "../../../core/src/components/Text";
import Grid, { Col } from "../../../core/src/components/Grid";

const styleSheet = ({ color, unit }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh"
  },
  header: {
    height: "135px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    width: "446px",
    margin: "auto",
    paddingBottom: "135px",
    maxWidth: "90vw",
    verticalAlign: "middle"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  error_danger: {
    backgroundColor: color.core.danger[2],
    color: color.core.neutral[4]
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  }
});

const Create = () => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);
  const [agreement, setAgreement] = React.useState(false);

  const [company, setCompany] = React.useState({
    company: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    color: "00B1A9",
    logo: ""
  });

  const [error, setError] = React.useState({
    company: { status: false },
    firstName: { status: false },
    lastName: { status: false },
    email: { status: false },
    password: { status: false },
    agreement: { status: false },
    color: { status: false }
  });

  const [createManufactureAccount] = useMutation(CREATE_MANUFACTURE_ACCOUNT);

  const updateField = (value, event) => {
    setCompany({
      ...company,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};

    if (!agreement) {
      errors = {
        ...errors,
        agreement: { status: true }
      };
      submitError = true;
    }

    if (!company.company) {
      errors = {
        ...errors,
        company: { status: true }
      };
      submitError = true;
    }

    if (!company.email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!validateEmail(company.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!company.firstName) {
      errors = {
        ...errors,
        firstName: { status: true }
      };
      submitError = true;
    }

    if (!company.password || company.password.length < 8) {
      errors = {
        ...errors,
        password: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }
    setLoading(true);
    createManufactureAccount({
      variables: {
        ...company
      }
    })
      .catch(res => {
        setServerError(true);
        setError({
          ...error,
          email: { status: true }
        });
        setLoading(false);
      })
      .then(res => {
        history.push({
          pathname: "/login",
          state: {
            success: true,
            msg: "Your company portal has been created."
          }
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.form)}>
            <div className={cx(styles.header)}>
              <IconObviousLogo width="200" height="48" decorative dark />
            </div>
            <Spacing vertical={theme.unit / 2}>
              <div className={cx(styles.title)}>
                <Title level={2}>Create your portal</Title>
                <Link
                  href="/login"
                  afterIcon={<IconCaretRight decorative size={24} />}
                >
                  Log in
                </Link>
              </div>
            </Spacing>
            {serverError && (
              <Spacing bottom={theme.unit / 2}>
                <Alert
                  title="Email address already in use."
                  danger
                  full
                  hideStatusIcon
                />
              </Spacing>
            )}
            <form
              id={id}
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <Spacing bottom={theme.unit / 4}>
                <Input
                  name="company"
                  placeholder="Company"
                  label="Company"
                  hideLabel
                  large
                  value={company.company}
                  onChange={updateField}
                  autoComplete="company"
                  noSpacing
                  invalid={error.company.status}
                  errorMessage="Company name is required"
                />
              </Spacing>
              <Spacing bottom={theme.unit / 4}>
                <Input
                  name="email"
                  placeholder="Email"
                  label="Email"
                  hideLabel
                  large
                  type="email"
                  value={company.email}
                  onChange={updateField}
                  autoComplete="email"
                  noSpacing
                  invalid={error.email.status}
                  errorMessage="Email is required"
                />
              </Spacing>
              <Spacing bottom={theme.unit / 4}>
                <Input
                  name="firstName"
                  placeholder="Full Name"
                  label="Full Name"
                  hideLabel
                  large
                  value={company.firstName}
                  onChange={updateField}
                  autoComplete="firstName"
                  noSpacing
                  invalid={error.firstName.status}
                  errorMessage="Full name is required"
                />
              </Spacing>
              <Spacing bottom={theme.unit / 4}>
                <Input
                  name="password"
                  placeholder="Password - 8+ characters"
                  hideLabel
                  large
                  type="password"
                  value={company.password}
                  onChange={updateField}
                  autoComplete="password"
                  noSpacing
                  invalid={error.password.status}
                  errorMessage="Password needs to be 8+ characters"
                />
              </Spacing>

              <Spacing vertical={theme.unit / 2}>
                <div
                  className={cx({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  })}
                >
                  <div>
                    <CheckBox
                      name="agreement"
                      label="Agreement"
                      hideLabel
                      onChange={() => setAgreement(!agreement)}
                      checked={agreement}
                      invalid={error.agreement.status}
                    />
                  </div>
                  <Spacing left={1}>
                    <Text small>
                      By creating an account, you agree to the{" "}
                      <Link openInNewWindow href="/static/pdf/terms.pdf">
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link openInNewWindow href="/static/pdf/privacy.pdf">
                        Privacy policy
                      </Link>
                      .
                    </Text>
                  </Spacing>
                </div>
              </Spacing>
              <Spacing vertical={theme.unit / 2}>
                <Button
                  large
                  block
                  onClick={handleSubmit}
                  loading={loading}
                  type="submit"
                >
                  Jump in
                </Button>
              </Spacing>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Create.propTypes = {};

Create.defaultProps = {};

export default Create;
