import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import useStyles from "../../../core/src/hooks/useStyles";
import PageHeader from "../../../components/PageHeader";
import Tabs, { Item } from "../../../components/Tabs";
import mdAndroidAPI from "../markdown/android-api.md";
import mdAndroidIntegration from "../markdown/android-integration.md";
import Document from "../Document";
import AndroidIntegration from "./Integration";
import AndroidApi from "./Api";

const Android = ({ match }) => {
  return (
    <div>
      <PageHeader
        id="contentMenu"
        title="Android"
        tabs={
          <Tabs>
            <Item name="API" to={`/docs/android/api`} />
            <Item name="Integration" to={`/docs/android/integration`} />
          </Tabs>
        }
      />
      <Switch>
        <Redirect exact from="/docs/android" to="/docs/android/api" />
        <Route exact path="/docs/android/api" component={AndroidApi} />
        <Route
          exact
          path="/docs/android/integration"
          component={AndroidIntegration}
        />
      </Switch>
    </div>
  );
};

export default Android;
