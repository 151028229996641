import React from "react";
import _pt from "prop-types";
import useStyles from "../../core/src/hooks/useStyles";
import Spacing from "../../core/src/components/Spacing";
import IconChevronDown from "../../icons/src/interface/IconChevronDown";
import ButtonOrLink from "../../core/src/components/basic/ButtonOrLink";
import useTheme from "../../core/src/hooks/useTheme";
import Text from "../../core/src/components/Text";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";
import Dropdown from "../../core/src/components/Dropdown";
import Menu, { Item, Separator } from "../../core/src/components/Menu";

const styleSheet = ({ aside, color, pattern, transition }) => ({
  account_dropdown_container: {
    position: "relative",
    display: "inline-block",
    width: "100%",
    border: "solid",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: color.core.neutral[3]
  },
  account_dropdown_selector: {
    ...pattern.resetButton,
    ...transition.box,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
    height: 64
  },
  account_dropdown_text: {
    flexGrow: 1
  }
});

const AccountDropdown = ({ color }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className={cx(styles.account_dropdown_container)}>
      <ButtonOrLink
        className={cx(styles.account_dropdown_selector)}
        onClick={handleToggle}
      >
        <Spacing right={3}>
          <ProfilePhoto>TN</ProfilePhoto>
        </Spacing>
        <div className={cx(styles.account_dropdown_text)}>
          <Text inverted={color === "dark"} small bold startAlign truncated>
            Tyler Nixon
          </Text>
          <Text inverted={color === " dark"} micro startAlign truncated>
            Account Settings
          </Text>
        </div>
        <div width="auto">
          <IconChevronDown
            accessibilityLabel="Open Account Settings"
            size={24}
            color={theme.color.base}
          />
        </div>
      </ButtonOrLink>
      {open && (
        <Dropdown
          visible
          onClickOutside={handleToggle}
          bottom="100%"
          left={0}
          zIndex={50}
          offset="left"
          full
        >
          <Menu accessibilityLabel="Account Setting Dropdown">
            <Item info href="/settings">
              Settings
            </Item>
            <Item info href="/notifications">
              Notifications
            </Item>
            <Separator />
            <Item danger href="/logout">
              Log out
            </Item>
          </Menu>
        </Dropdown>
      )}
    </div>
  );
};

AccountDropdown.propTypes = {
  color: _pt.oneOf(["light", "dark"])
};

AccountDropdown.defaultProps = {
  color: "dark"
};

export default AccountDropdown;
