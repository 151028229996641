import { LOCALES } from '../constants';

const DIVIDER = /-|_/;
export default function parseLocale(value) {
  let locale = value;

  if (locale) {
    locale = locale.toLowerCase(); // We currently only support the language, so strip the territory

    if (locale.match(DIVIDER)) {
      [locale] = locale.split(DIVIDER);
    }

    const typedLocale = locale; // And we only want a locale that matches our whitelist

    if (LOCALES.includes(typedLocale)) {
      return typedLocale;
    }
  }

  return undefined;
}
