import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import queryString from "query-string";
import { useMutation } from "@apollo/react-hooks";
import { CONFIRM_RESET_USER_PASSWORD, VERIFY_RESET_PASSWORD } from "./queries";
import useStyles, { aesthetic } from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import IconObviousLogo from "../../../icons/src/interface/IconObviousLogo";
import Title from "../../../core/src/components/Title";
import Input from "../../../core/src/components/Input";
import Spacing from "../../../core/src/components/Spacing";
import Button from "../../../core/src/components/Button";
import Link from "../../../core/src/components/Link";
import IconCaretRight from "../../../icons/src/interface/IconCaretRight";
import Divider from "../../../core/src/components/Divider";
import { validateEmail, primaryColorGenerator } from "../../../helpers";
import Alert from "../../../core/src/components/Alert";
import ResponsiveImage from "../../../core/src/components/ResponsiveImage";
import history from "../../../history";

const styleSheet = ({ color, unit }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh"
  },
  header: {
    height: "135px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    width: "446px",
    margin: "auto",
    paddingBottom: "135px",
    maxWidth: "90vw",
    verticalAlign: "middle"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  error_danger: {
    backgroundColor: color.core.danger[2],
    color: color.core.neutral[4]
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  }
});

const Reset = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [verifying, setVerifying] = React.useState(true);
  const [password, setPassword] = React.useState({
    newPassword: "",
    oldPassword: ""
  });
  const [serverError, setServerError] = React.useState(false);

  const [error, setError] = React.useState({
    newPassword: { status: false },
    oldPassword: { status: false },
    match: { status: false }
  });

  const [confirmResetUserPassword] = useMutation(CONFIRM_RESET_USER_PASSWORD);
  const [verifyResetPassword] = useMutation(VERIFY_RESET_PASSWORD);

  React.useEffect(() => {
    const token = queryString.parse(location.search).token;
    if (token) {
      verifyResetPassword({
        variables: {
          token: token
        }
      })
        .catch(() => {
          history.push({
            pathname: "/login",
            state: {
              error: true,
              msg: "This password recovery link is not valid anymore"
            }
          });
        })
        .then(() => {
          setVerifying(false);
        });
    } else {
      history.push({
        pathname: "/login",
        state: {
          error: true,
          msg:
            "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided."
        }
      });
    }
  }, []);

  const updateField = (value, event) => {
    setPassword({
      ...password,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};
    if (!password.newPassword || password.newPassword.length < 8) {
      errors = {
        ...errors,
        newPassword: { status: true }
      };
      submitError = true;
    }

    if (!password.oldPassword) {
      errors = {
        ...errors,
        oldPassword: { status: true }
      };
      submitError = true;
    }

    if (password.newPassword !== password.oldPassword) {
      errors = {
        ...errors,
        match: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }
    setLoading(true);

    confirmResetUserPassword({
      variables: {
        password: password.oldPassword,
        token: queryString.parse(location.search).token
      }
    })
      .then(({ data: { confirmResetUserPassword: { success } } }) => {
        if (success) {
          history.push({
            pathname: "/login",
            state: {
              success: true,
              msg: "Your password has been changed successfully."
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    !verifying && (
      <div>
        <div className={cx(styles.wrapper)}>
          <div className={cx(styles.content)}>
            <div className={cx(styles.form)}>
              <div className={cx(styles.header)}>
                <IconObviousLogo width="200" height="48" decorative dark />
              </div>
              <Spacing vertical={theme.unit / 2}>
                <div className={cx(styles.title)}>
                  <Title level={2}>Reset your password?</Title>
                  <Link
                    href="/login"
                    afterIcon={<IconCaretRight decorative size={24} />}
                  >
                    Log in
                  </Link>
                </div>
              </Spacing>
              {(error.match.status || serverError) && (
                <Spacing bottom={theme.unit / 2}>
                  <Alert
                    title="An error prohibited your password from being changed"
                    danger
                    full
                    hideStatusIcon
                  />
                </Spacing>
              )}
              <form
                id={id}
                method="post"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <Spacing bottom={theme.unit / 4}>
                  <Input
                    name="newPassword"
                    placeholder="Password - 8+ characters"
                    label="newPassword"
                    hideLabel
                    large
                    type="password"
                    value={password.newPassword}
                    onChange={updateField}
                    autoComplete="password"
                    noSpacing
                    invalid={error.newPassword.status}
                    errorMessage="Password needs to be 8+ characters"
                  />
                </Spacing>
                <Spacing bottom={theme.unit / 4}>
                  <Input
                    name="oldPassword"
                    placeholder="Confirm password"
                    label="oldPassword"
                    hideLabel
                    large
                    type="password"
                    value={password.oldPassword}
                    onChange={updateField}
                    autoComplete="password"
                    noSpacing
                    invalid={error.oldPassword.status}
                    errorMessage="Does not match password"
                  />
                </Spacing>
                <Spacing vertical={theme.unit / 2}>
                  <Button
                    large
                    block
                    onClick={handleSubmit}
                    loading={loading}
                    type="submit"
                  >
                    Change password
                  </Button>
                </Spacing>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

Reset.propTypes = {};

Reset.defaultProps = {};

export default Reset;
