import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation } from "@apollo/react-hooks";
import { INVITE_MEMBER, GET_TEAM } from "../queries";
import FormActions from "../../../core/src/components/FormActions";
import TextArea from "../../../core/src/components/TextArea";
import Input from "../../../core/src/components/Input";
import Select from "../../../core/src/components/Select";
import { validateEmail } from "../../../helpers";
import { EROFS } from "constants";

const InviteForm = ({ onClose, ...props }) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [member, setMember] = React.useState({
    email: "",
    message: "You have been invited to join us in the Obvıous. portal.",
    role: 1
  });
  const [error, setError] = React.useState({
    email: { status: false },
    message: { status: false },
    role: { status: false }
  });

  const [inviteMember] = useMutation(INVITE_MEMBER, {
    update(
      cache,
      {
        data: {
          createManufacturerMember: { manufacturerMember }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_TEAM
      });
      data.manufacturer.members.edges.push({
        node: { ...manufacturerMember },
        __typename: "ManufacturerMemberNodeEdge"
      });
      cache.writeQuery({
        query: GET_TEAM,
        data
      });
    }
  });

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    setMember({
      ...member,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!member.email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!validateEmail(member.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!member.role) {
      errors = {
        ...errors,
        role: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);

    inviteMember({
      variables: {
        pendingemail: member.email,
        invitationmessage: member.message,
        role: member.role
      },
      optimisticResponse: {
        __typename: "Mutation",
        createManufacturerMember: {
          product: {
            id: -1,
            pendingemail: member.email,
            invitationmessage: member.message,
            role: member.role,
            __typename: "ManufacturerMemberNode"
          },
          __typename: "createManufacturerMember"
        }
      }
    })
      .then(res => {
        onClose();
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Select
        name="role"
        value="User Role"
        placeholder="Please select a role"
        value={member.role}
        onChange={updateField}
        errorMessage="A role is needed"
        invalid={error.role.status}
      >
        <option value="1">Observer</option>
        <option value="2">Developer</option>
        <option value="3">Developer Admin</option>
        <option value="4">Customer Service</option>
        <option value="5">Product Manager</option>
      </Select>
      <Input
        name="email"
        label="Email"
        placeholder="puddle@jumper.xyz"
        value={member.email}
        onChange={updateField}
        errorMessage="An email is required to send an invite"
        invalid={error.email.status}
        type="email"
      />
      <TextArea
        name="message"
        label="Invite message"
        placeholder="Add an invitation message to send with your invite"
        value={member.message}
        onChange={updateField}
        optional
      />
      <FormActions
        continueText={`Send invite`}
        processingText={`Sending...`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

InviteForm.propTypes = {};

InviteForm.defaultProps = {};

export default InviteForm;
