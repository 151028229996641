import React from 'react';
import _pt from 'prop-types';
import withIcon from '../withIcon';
import useTheme from '../../../core/src/hooks/useTheme';

const IconDashboard = ({ firstColor, secondColor, ...props }) => {
  const theme = useTheme();

  return (
    <svg className="fill-current" {...props} viewBox="0 0 14 14">
      <path
        style={{ color: secondColor ? secondColor : '#ffffff' }}
        d="M5.396 0H1.02C.458 0 0 .458 0 1.02v2.626c0 .563.458 1.02 1.02 1.02h4.376c.563 0 1.02-.457 1.02-1.02V1.02C6.417.458 5.96 0 5.397 0z"
      />
      <path
        style={{ color: firstColor ? firstColor : theme.color.core.primary[3] }}
        d="M5.396 5.833H1.02C.458 5.833 0 6.291 0 6.854v6.125C0 13.542.458 14 1.02 14h4.376c.563 0 1.02-.458 1.02-1.02V6.853c0-.563-.457-1.02-1.02-1.02z"
      />
      <path
        style={{ color: secondColor ? secondColor : '#ffffff' }}
        d="M12.98 9.333H8.603c-.563 0-1.02.458-1.02 1.021v2.625c0 .563.457 1.021 1.02 1.021h4.375c.563 0 1.021-.458 1.021-1.02v-2.626c0-.563-.458-1.02-1.02-1.02zm0-9.333H8.603c-.563 0-1.02.458-1.02 1.02v6.126c0 .563.457 1.02 1.02 1.02h4.375c.563 0 1.021-.457 1.021-1.02V1.02C14 .458 13.542 0 12.98 0z"
      />
    </svg>
  );
};

IconDashboard.propTypes = {
  firstColor: _pt.string,
  secondColor: _pt.string,
};

IconDashboard.defaultProps = {
  firstColor: 'brand-teal',
  secondColor: 'brand-teal-light',
};

export default withIcon('IconDashboard')(IconDashboard);
