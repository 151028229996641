import React from "react";
import { useQuery } from "@apollo/react-hooks";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { GET_DEVICES } from "./queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import PageHeader from "../../../../components/PageHeader";
import Text from "../../../../core/src/components/Text";
import Title from "../../../../core/src/components/Title";
import DevicesList, { Device } from "./DevicesList";
import IconSearch from "../../../../icons/src/interface/IconSearch";
import Input from "../../../../core/src/components/Input";
import AppLoader from "../../../../core/src/components/AppLoader";
import Button from "../../../../core/src/components/Button";
import { toLocalNumber, getRolePermissions } from "../../../../helpers";
import DeviceForm from "./DeviceForm";
import Modal from "../../../../core/src/components/Modal";
import Spacing from "../../../../core/src/components/Spacing";
import Row from "../../../../core/src/components/Row";

const styleSheet = ({ color, unit }) => ({
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  search_icon: {
    marginRight: unit
  },
  more: {
    paddingTop: unit * 4,
    paddingBottom: unit * 4,
    display: "flex",
    justifyContent: "center"
  }
});
const Devices = ({ match }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [productID] = React.useState(match.params.product);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [visable, setVisable] = React.useState(false);

  const { loading: devicesLoading, data: devicesData, fetchMore } = useQuery(
    GET_DEVICES,
    {
      variables: { productid: productID, serialnumber: search },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const searchChange = value => {
    setSearch(value);
  };

  return (
    <>
      <Spacing bottom={2}>
        <Row
          center
          before={
            !devicesLoading && (
              <Text bold>
                {`${devicesData &&
                  devicesData.allDevices &&
                  toLocalNumber(
                    devicesData.allDevices.totalCount
                  )} devices`}{" "}
                {search && `Serial number starting with ${search}`}
              </Text>
            )
          }
          after={
            <div className={cx({ display: "flex", alignContent: "center" })}>
              <div className={cx(styles.search)}>
                <IconSearch
                  className={cx(styles.search_icon)}
                  accessibilityLabel="search"
                  width={32}
                  height={32}
                  color={theme.color.core.neutral[3]}
                />
                <Input
                  label="search"
                  hideLabel
                  autoComplete="off"
                  name="search"
                  value={search}
                  onChange={searchChange}
                  placeholder="find device"
                  type="search"
                />
              </div>
              {permissions.devices.includes("CREATE") && (
                <div className={cx({ marginLeft: 16 })}>
                  <Button onClick={() => setVisable(true)}>Add</Button>
                </div>
              )}
            </div>
          }
        ></Row>
      </Spacing>
      <AppLoader
        fetched={!devicesLoading}
        centered
        small
        loadingText={
          search
            ? `Fetching devices that start with ${search}`
            : "Fetching devices"
        }
        failureText="Failed to load devices"
      >
        <div
          className={cx({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          })}
        ></div>

        <DevicesList product={productID}>
          {devicesData &&
            devicesData.allDevices &&
            devicesData.allDevices.edges.map(({ node: device }) => {
              return (
                <Device key={device.id} device={device} product={productID}>
                  <Text bold>{device.serialnumber}</Text>
                </Device>
              );
            })}
        </DevicesList>

        {devicesData &&
          devicesData.allDevices &&
          devicesData.allDevices.pageInfo.hasNextPage && (
            <div className={cx(styles.more)}>
              <Button
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  fetchMore({
                    query: GET_DEVICES,
                    variables: {
                      productid: productID,
                      serialnumber: search,
                      after:
                        devicesData.allDevices &&
                        devicesData.allDevices.pageInfo.endCursor
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      const newEdges =
                        fetchMoreResult.devicesData.allDevices.edges;
                      const pageInfo =
                        fetchMoreResult.devicesData.allDevices.pageInfo;
                      setLoading(false);
                      return newEdges.length
                        ? {
                            // Put the new comments at the end of the list and update `pageInfo`
                            // so we have the new `endCursor` and `hasNextPage` values
                            allDevices: {
                              __typename:
                                previousResult.devicesData.allDevices
                                  .__typename,
                              edges: [
                                ...previousResult.devicesData.allDevices.edges,
                                ...newEdges
                              ],
                              pageInfo
                            }
                          }
                        : previousResult;
                    }
                  });
                }}
              >
                Load More
              </Button>
            </div>
          )}
      </AppLoader>
      {permissions.devices.includes("CREATE") && visable && (
        <Modal title={"Add Device"} onClose={() => setVisable(false)}>
          <DeviceForm product={productID} onClose={() => setVisable(false)} />
        </Modal>
      )}
    </>
  );
};

Devices.propTypes = {};

Devices.defaultProps = {};

export default withRouter(Devices);
