import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloLink } from "apollo-link";
// import { withClientState } from 'apollo-link-state';
import { onError } from "apollo-link-error";
import history from "../history";
import { aesthetic } from "../core/src/hooks/useStyles";

// import initalState from './state';
// import { updateManufacturer, updateModal, updateMessage } from './resolvers';
import { primaryColorGenerator } from "../helpers";

const AUTH_TOKEN = "ocelot-token";
const cache = new InMemoryCache();
const host = window.location.origin.includes("localhost")
  ? "http://127.0.0.1:8000"
  : window.location.origin;

const httpLink = createHttpLink({
  uri: host + "/graphiql"
});

const uploadLink = createUploadLink({
  uri: host + "/graphiql"
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors);
    graphQLErrors.map(({ message, locations, path }) => {
      console.log({
        message: message,
        locations: locations,
        path: path
      });
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (networkError.statusCode === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem("manufacturer");
      aesthetic.themes.light.color.core.primary = primaryColorGenerator(
        `#00B1A9`
      );
      history.push("/login");
    }
  }
});

const defaultOptions = {
  query: {
    fetchPolicy: "network-only"
  }
};

const link = ApolloLink.from([authLink, errorLink, uploadLink, httpLink]);

const client = new ApolloClient({
  link,
  cache,
  defaultOptions
});

export default client;
