import React from "react";
import { withRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Products from "./Products";
import Product from "./Products/Product";
import Entry from "./Products/Product/Entries/Entry";

const Catalog = ({ match, location }) => {
  const [visable, setVisable] = React.useState(false);

  return false ? (
    <LogoLoader />
  ) : (
    <Switch>
      <Redirect exact from={`/sales/catalog`} to={`/sales/catalog/products`} />
      <Route
        exact
        path={`/sales/catalog/products`}
        render={props => <Products {...props} />}
      />
    </Switch>
  );
};

export default withRouter(Catalog);
