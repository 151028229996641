import React from "react";
import BaseFormActions from "../../../core/src/components/FormActions";
import FormContext from "../FormContext";

/** `FormActions` automatically connected to the parent `Form`.  */
export default class FormActions extends React.PureComponent {
  static contextType = FormContext;

  render() {
    const props = { ...this.props };

    if (this.context) {
      const { submitting, valid } = this.context.getState();

      Object.assign(props, {
        disabled: !valid,
        processing: submitting
      });
    }

    return <BaseFormActions {...props} />;
  }
}
