import React from "react";
import useStyles from "../../core/src/hooks/useStyles";

const styleSheet = ({ breakpoints }) => ({
  content: {
    width: "100%",
    height: "100%",
    // minHeight: "100vh",
    maxWidth: breakpoints.medium,
    margin: "0 auto",
    paddingTop: "48px",
    paddingBottom: "96px"
  },
  content_small: {
    maxWidth: breakpoints.small
  },
  content_xsmall: {
    maxWidth: breakpoints.xsmall
  },
  content_xxsmall: {
    maxWidth: breakpoints.xxsmall
  }
});

const Content = ({ children, small, xsmall, xxsmall }) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <div
      className={cx(
        styles.content,
        small && styles.content_small,
        xsmall && styles.content_xsmall,
        xxsmall && styles.content_xxsmall
      )}
    >
      {children}
    </div>
  );
};

export default Content;
