import React from "react";
import _pt from "prop-types";
import QRCode from "qrcode.react";
import { CSVLink } from "react-csv";
import { useQuery } from "@apollo/react-hooks";
import { GET_LICENSES } from "../queries";
import useStyles from "../../../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../../../core/src/hooks/useTheme";
import Text from "../../../../../../../core/src/components/Text";
import Spacing from "../../../../../../../core/src/components/Spacing";
import Card, {
  Content as CardContent
} from "../../../../../../../core/src/components/Card";
import AdaptiveGrid from "../../../../../../../core/src/components/AdaptiveGrid";
import Button from "../../../../../../../core/src/components/Button";
import Select from "../../../../../../../core/src/components/Select";
import Input from "../../../../../../../core/src/components/Input";
import IconSearch from "../../../../../../../icons/src/interface/IconSearch";
import Row from "../../../../../../../core/src/components/Row";
import LogoLoader from "../../../../../../../core/src/components/LogoLoader";
import DateTime from "../../../../../../../core/src/components/DateTime";

const styleSheet = ({ color, unit }) => {
  return {
    keys_list_item: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#fff",
      borderRadius: 5,
      padding: unit * 2
    },
    search: {
      display: "flex",
      alignItems: "center"
    },
    search_icon: {
      marginRight: unit
    },
    options: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%"
    },
    options_items: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flexGrow: "1"
    }
  };
};

const Liceneses = ({}) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [search, setSearch] = React.useState("");
  const {
    loading,
    data: { allLiceneses }
  } = useQuery(GET_LICENSES, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  console.log(allLiceneses);

  return loading ? (
    <LogoLoader />
  ) : (
    <div>
      <Spacing top={8} bottom={4}>
        <div className={cx(styles.options)}>
          <div className={cx(styles.search)}>
            <IconSearch
              className={cx(styles.search_icon)}
              size={24}
              decorative
            />
            <Input
              placeholder="Search"
              name="search"
              value={search}
              onChange={value => setSearch(value)}
              hideLabel
            />
          </div>
          <div className={cx(styles.options_items)}>
            <Spacing right={1}>
              <Select placeholder="Filter By..." hideLabel>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </Select>
            </Spacing>
            {/* <div>
              <CSVLink
                data={[{ id: "1" }]}
                filename={"licenses.csv"}
                target="_blank"
                className="pr-3"
              >
                <Button>Export</Button>
              </CSVLink>
            </div> */}
          </div>
        </div>
      </Spacing>
      <Spacing>
        <AdaptiveGrid defaultItemsPerRow={1}>
          {allLiceneses.edges.map(({ node }) => (
            <Card id={node.id}>
              <CardContent>
                <Row center>
                  <div className={cx({ flexGrow: 1 })}>
                    <Text>{node.licensenumber}</Text>
                    <Text small>
                      Activated on device{" "}
                      <span
                        className={cx({ color: theme.color.core.primary[3] })}
                      >
                        {node.deviceid.serialnumber}
                      </span>
                      at{" "}
                      <DateTime
                        at={new Date(node.dateupdated).getTime()}
                        short
                      />
                    </Text>
                  </div>
                  <Spacing right={2}>
                    <DateTime at={new Date(node.datecreated).getTime()} short />
                  </Spacing>
                  <div>
                    <QRCode
                      value={node.signature}
                      size={32}
                      renderAs="svg"
                      fgColor={true ? theme.color.core.neutral[3] : "#fff"}
                    />
                  </div>
                </Row>
              </CardContent>
            </Card>
          ))}
        </AdaptiveGrid>
      </Spacing>
    </div>
  );
};

Liceneses.propTypes = {};

Liceneses.defaultProps = {};

export default Liceneses;
