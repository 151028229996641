import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  UPDATE_RESERVED_FEATURE,
  CREATE_PRODUCT_RESERVED_FEATURE,
  GET_RESERVED_FEATURES
} from "../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Row from "../../../../../core/src/components/Row";
import Switch from "../../../../../core/src/components/Switch";
import MenuToggle from "../../../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../icons/src/interface/IconMenuDots";
import { getRolePermissions } from "../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const ReservedFeature = ({ children, feature, product }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [updateReservedFeature] = useMutation(UPDATE_RESERVED_FEATURE);
  const [createProductReservedFeature] = useMutation(
    CREATE_PRODUCT_RESERVED_FEATURE
  );

  const toggleStatus = () => {
    if (permissions.features.includes("DELETE")) {
      const reservedID = feature.productreserved.edges.map(
        ({ node }) => node.id
      )[0];
      const status = feature.productreserved.edges.map(
        ({ node }) => node.deactivated
      )[0];
      status !== undefined
        ? updateReservedFeature({
            variables: {
              id: reservedID,
              productid: product,
              deactivated: !status
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateProductReservedFeature: {
                productReservedFeature: {
                  id: reservedID,
                  deactivated: !status,
                  __typename: "ProductReservedFeaturesNode"
                },
                __typename: "UpdateProductReservedFeature"
              }
            }
          })
        : createProductReservedFeature({
            variables: {
              productid: product,
              reservedfeatureid: feature.id
            },
            optimisticResponse: {
              __typename: "Mutation",
              createProductReservedFeature: {
                productReservedFeature: {
                  id: -1,
                  deactivated: true,
                  __typename: "ProductReservedFeaturesNode"
                },
                __typename: "CreateProductReservedFeature"
              }
            },
            update: (store, { data: { createProductReservedFeature } }) => {
              const data = store.readQuery({
                query: GET_RESERVED_FEATURES,
                variables: {
                  id: product
                }
              });
              data.allReservedFeatures.edges.map(({ node }) => {
                if (node.id === feature.id) {
                  node.productreserved.edges.push({
                    node: {
                      ...createProductReservedFeature.productReservedFeature
                    },
                    __typename: "ProductReservedFeaturesNodeEdge"
                  });
                }
                return node;
              });
              store.writeQuery({
                query: GET_RESERVED_FEATURES,
                variables: {
                  id: product
                },
                data
              });
            }
          });
    }
  };

  const handleChange = () => {};
  const handleHide = () => {};
  const handleShow = () => {};
  return (
    <div className={cx(styles.feature)}>
      <Row center>
        <div className={cx({ flexGrow: 1 })}>{children}</div>
        <div className={cx(styles.options)}>
          <div className={cx(styles.options_items)}>Purchasable</div>

          <div className={cx(styles.options_items)}>
            <Switch
              label="feature active status"
              hideLabel
              noSpacing
              checked={
                !feature.productreserved.edges.map(
                  ({ node }) => node.deactivated
                )[0]
              }
              onChange={toggleStatus}
            />
          </div>
          {permissions.features.includes("UPDATE") && (
            <MenuToggle
              accessibilityLabel="Actions"
              toggleIcon={
                <IconMenuDots decorative color={theme.color.core.neutral[4]} />
              }
              toggleLabel="Actions"
              zIndex={10}
              onShow={handleShow}
              onHide={handleHide}
            >
              <MenuItem
                onClick={() => {
                  toggleStatus();
                }}
              >
                {!feature.productreserved.edges.map(
                  ({ node }) => node.deactivated
                )[0]
                  ? "Deactivate"
                  : "Activate"}
              </MenuItem>
            </MenuToggle>
          )}
        </div>
      </Row>
    </div>
  );
};

export default ReservedFeature;
