import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import useStyles from "../../../core/src/hooks/useStyles";
import PageHeader from "../../../components/PageHeader";
import Tabs, { Item } from "../../../components/Tabs";
import IOSIntegration from "./Integration";
import IOSAPI from "./Api";

const Android = ({ match }) => {
  return (
    <div>
      <PageHeader
        id="contentMenu"
        title="iOS"
        tabs={
          <Tabs>
            <Item name="API" to={`/docs/ios/api`} />
            <Item name="Integration" to={`/docs/ios/integration`} />
          </Tabs>
        }
      />
      <Switch>
        <Redirect exact from="/docs/ios" to="/docs/ios/api" />
        <Route exact path="/docs/ios/api" component={IOSAPI} />
        <Route exact path="/docs/ios/integration" component={IOSIntegration} />
      </Switch>
    </div>
  );
};

export default Android;
