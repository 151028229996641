import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { GET_FEATURES } from "./queries";
import PageHeader from "../../../../components/PageHeader";
import Tabs, { Item } from "../../../../components/Tabs";
import FeaturesList, { Feature, ReservedFeature } from "./FeaturesList";
import Text from "../../../../core/src/components/Text";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Button from "../../../../core/src/components/Button";
import Modal from "../../../../core/src/components/Modal";
import FeatureForm from "./FeatureForm";
import { getRolePermissions } from "../../../../helpers";
import Row from "../../../../core/src/components/Row";
import Spacing from "../../../../core/src/components/Spacing";

const Features = ({ match, location }) => {
  const permissions = getRolePermissions("product");
  const [productID] = React.useState(match.params.product);
  const [loading, setLoading] = React.useState(true);
  const [visable, setVisable] = React.useState(false);
  const { loading: featuresLoading, data: featuresData } = useQuery(
    GET_FEATURES,
    {
      variables: { productid: productID },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  React.useEffect(() => {
    setLoading(featuresLoading);
  }, [featuresLoading]);

  return loading ? (
    <LogoLoader />
  ) : (
    <>
      <Spacing bottom={2}>
        <Row
          center
          after={
            permissions.features.includes("CREATE") &&
            location.pathname.includes("list") && (
              <Button onClick={() => setVisable(true)}>Add</Button>
            )
          }
        ></Row>
      </Spacing>

      <Switch>
        <Redirect
          exact
          from="/products/:product/features/"
          to="/products/:product/features/list"
        />
        <Route
          path="/products/:product/features/list"
          render={() => (
            <FeaturesList>
              {featuresData.allProductFeatures.edges.map(
                ({ node: feature }) => {
                  return (
                    <Feature
                      key={feature.id}
                      feature={feature}
                      product={productID}
                    >
                      <Text bold>{feature.name}</Text>
                      <Text small>{feature.description}</Text>
                    </Feature>
                  );
                }
              )}
            </FeaturesList>
          )}
        />
      </Switch>
      {visable && (
        <Modal title={"Add Feature"} onClose={() => setVisable(false)}>
          <FeatureForm product={productID} onClose={() => setVisable(false)} />
        </Modal>
      )}
    </>
  );
};

export default withRouter(Features);
