import React from "react";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_CATALOG_ENTRY, GET_FEATURES, GET_PRODUCT } from "../queries";
import useStyles from "../../../../../../../core/src/hooks/useStyles";
import LogoLoader from "../../../../../../../core/src/components/LogoLoader";
import PageHeader from "../../../../../../../components/PageHeader";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../../../../core/src/components/Breadcrumbs";
import Button from "../../../../../../../core/src/components/Button";
import Row from "../../../../../../../core/src/components/Row";
import Title from "../../../../../../../core/src/components/Title";
import Spacing from "../../../../../../../core/src/components/Spacing";
import Text from "../../../../../../../core/src/components/Text";
import Price from "../../../../../../../core/src/components/Price";
import Item from "./Item";
import Modal from "../../../../../../../core/src/components/Modal";
import Form from "./Form";
import { getRolePermissions } from "../../../../../../../helpers";
import Layout from "../../../../../../../layout/src/components/Layout";
import TopMenu from "../../../../../../../components/TopMenu";
import MainMenu from "../../../../../../../components/MainMenu";
import Content from "../../../../../../../components/Content";
import ProductBreadcrumb from "../../../../../../../components/ProductBreadcrumb";

const styleSheet = ({ color, unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Entries = ({ match }) => {
  const permissions = getRolePermissions("sales");
  const [styles, cx] = useStyles(styleSheet);
  const [productID] = React.useState(match.params.product);
  const [entryID] = React.useState(match.params.item);
  const [visable, setVisable] = React.useState(false);

  const { loading, data: entryData, refetch } = useQuery(GET_CATALOG_ENTRY, {
    variables: { id: entryID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    variables: { id: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const { loading: featuresLoading, data: featuresData } = useQuery(
    GET_FEATURES,
    {
      variables: { productid: productID },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const getFeatureName = featureid => {
    const features = featuresData.allProductFeatures.edges
      .filter(({ node }) => {
        return node.rowid === featureid && node.name;
      })
      .map(({ node }) => {
        return node.name;
      })
      .join();
    const reserved = featuresData.allReservedFeatures.edges
      .filter(({ node }) => {
        return node.rowid === featureid && node.name;
      })
      .map(({ node }) => {
        return node.name;
      })
      .join();
    return features ? features : reserved;
  };

  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <TopMenu />
      <Content>
        {loading || featuresLoading || productLoading ? (
          <LogoLoader />
        ) : (
          <div>
            <ProductBreadcrumb title={"Sales"}>
              <Breadcrumbs center accessibilityLabel="Breadcrumb">
                <Breadcrumb
                  highlighted
                  label="Catalog"
                  href={`/sales/catalog`}
                />
                <Breadcrumb
                  highlighted
                  label={productData.product.name}
                  href={`/sales/catalog/products/${productData.product.id}`}
                />
                <Breadcrumb hideIcon label={entryData.catalogEntry.name} />
              </Breadcrumbs>
            </ProductBreadcrumb>
            <Spacing bottom={2} top={4}>
              <div className={cx(styles.flex_center)}>
                <div className={cx({ flexGrow: 1 })}></div>
                {entryData.catalogEntry.entrytype.name === "PACKAGE" &&
                  (entryData.allReservedFeatures
                    ? entryData.allReservedFeatures.edges.length
                    : 0) +
                    (entryData.allProductFeatures
                      ? entryData.allProductFeatures.edges.length
                      : 0) >
                    entryData.catalogEntry.packageentry.edges.length &&
                  permissions.catalog.includes("CREATE") && (
                    <Button onClick={() => setVisable(true)}>
                      Add Feature
                    </Button>
                  )}
              </div>
            </Spacing>
            <div className={cx(styles.row_wrapper)}>
              <Row>
                <Title level={3}>{entryData.catalogEntry.name} Details</Title>

                <Spacing vertical={2}>
                  <Text bold>Description</Text>
                  <Text>{entryData.catalogEntry.description}</Text>
                </Spacing>
                {entryData.catalogEntry.entrytype.name === "ITEM" && (
                  <Spacing bottom={2}>
                    <Text bold>Feature Name</Text>
                    <Text>
                      {getFeatureName(entryData.catalogEntry.featureid)}
                    </Text>
                  </Spacing>
                )}

                <Spacing bottom={2}>
                  <Text bold>SKU</Text>
                  <Text>{entryData.catalogEntry.sku}</Text>
                </Spacing>
                <Spacing bottom={2}>
                  <Text bold>Price</Text>
                  <Text>
                    <Price amount={entryData.catalogEntry.itemprice} />
                  </Text>
                </Spacing>
              </Row>
            </div>
            {entryData.catalogEntry.entrytype.name === "PACKAGE" && (
              <Spacing vertical={4}>
                <Title level={3}>Features</Title>
                <Spacing top={2}>
                  {entryData.catalogEntry.packageentry.edges.map(({ node }) => {
                    return (
                      <Item
                        catalogpackage={node.id}
                        feature={node.featureid}
                        entry={entryID}
                        key={node.id}
                      >
                        <Text bold>{getFeatureName(node.featureid)}</Text>
                      </Item>
                    );
                  })}
                </Spacing>
              </Spacing>
            )}
            {permissions.catalog.includes("CREATE") &&
              visable &&
              entryData.catalogEntry.entrytype.name === "PACKAGE" && (
                <Modal title={"Add Feature"} onClose={() => setVisable(false)}>
                  <Form
                    onClose={() => setVisable(false)}
                    refetch={refetch}
                    entry={entryData.catalogEntry.id}
                    allFeatures={entryData.allProductFeatures}
                    reservedFeatures={entryData.allReservedFeatures}
                    entryFeatures={entryData.catalogEntry.packageentry.edges.map(
                      ({ node }) => {
                        return getFeatureName(node.featureid);
                      }
                    )}
                  />
                </Modal>
              )}
          </div>
        )}
      </Content>
    </Layout>
  );
};

Entries.propTypes = {};

Entries.defaultProps = {};

export default withRouter(Entries);
