import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Login from "./screens/account/login";
import Product from "./screens/products/product";
import Products from "./screens/products";
import Sales from "./screens/sales";
import Team from "./screens/team";
import Tools from "./screens/tools";
import Forgot from "./screens/account/forgot";
import Join from "./screens/account/join";
import Access from "./screens/account/access";
import Reset from "./screens/account/reset";
import history from "./history";
import Create from "./screens/account/create";
import client from "./Apollo";
import { changeThemeColors } from "./helpers";
import Docs from "./screens/docs";
import { RoutePrivate } from "./components/RoutePrivate/index";
import Device from "./screens/products/product/devices/Device";
import Group from "./screens/products/product/groups/Group";
import FirmwareDetail from "./screens/products/product/firmware/FirmwareDetail";
import SalesProduct from "./screens/sales/Catalog/Products/Product/index";
import Entry from "./screens/sales/Catalog/Products/Product/Entries/Entry";
import Account from "./screens/account/manage";

const publicLinks = [
  "/access",
  "/create",
  "/login",
  "/join",
  "/forgot",
  "/reset"
];

const App = ({ location }) => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (publicLinks.includes(location.pathname)) {
      const token = localStorage.getItem("ocelot-token");
      if (token) {
        if (location.pathname === "/login") {
          localStorage.removeItem("ocelot-token");
          localStorage.removeItem("manufacturer");
          localStorage.removeItem("user");
          client.resetStore();
          changeThemeColors("#00B1A9");
        } else {
          history.push({
            pathname: "/products"
          });
        }
      }
    }
  });

  return (
    <Switch>
      <Redirect from="/" to="/login" exact />
      <Route exact path="/access" render={props => <Access {...props} />} />
      <Route exact path="/create" render={props => <Create {...props} />} />
      <Route exact path="/login" render={props => <Login {...props} />} />
      <Route exact path="/join" render={props => <Join {...props} />} />
      <Route exact path="/forgot" render={props => <Forgot {...props} />} />
      <Route exact path="/reset" render={props => <Reset {...props} />} />
      {/* <Route
        exact
        path="/products/:product/firmware/library-generator"
        component={FirmwareLibraryGenerator}
      /> */}
      <RoutePrivate exact path="/products" component={Products} />
      <RoutePrivate
        path="/products/:product/devices/:device"
        component={Device}
      />
      <RoutePrivate path="/products/:product/groups/:group" component={Group} />
      <RoutePrivate
        path="/products/:product/firmware/:firmware"
        component={FirmwareDetail}
      />

      <RoutePrivate path="/products/:product" component={Product} />
      <RoutePrivate
        exact
        path={`/sales/catalog/products/:product`}
        component={SalesProduct}
      />
      <RoutePrivate
        exact
        path={`/sales/catalog/products/:product/items/:item`}
        component={Entry}
      />
      <RoutePrivate path="/sales" component={Sales} />

      <RoutePrivate path="/team" component={Team} />
      <RoutePrivate path="/tools" component={Tools} />
      <RoutePrivate path="/docs" component={Docs} />
      <RoutePrivate path="/account" component={Account} />
    </Switch>
  );
};

export default withRouter(App);
