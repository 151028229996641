import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { VERIFY_DEMO_CODE, CREATE_DEMO_USER } from "./queries";
import useStyles, { aesthetic } from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import IconObviousLogo from "../../../icons/src/interface/IconObviousLogo";
import Title from "../../../core/src/components/Title";
import Input from "../../../core/src/components/Input";
import Spacing from "../../../core/src/components/Spacing";
import Button from "../../../core/src/components/Button";
import Link from "../../../core/src/components/Link";
import IconCaretRight from "../../../icons/src/interface/IconCaretRight";
import Divider from "../../../core/src/components/Divider";
import { validateEmail, primaryColorGenerator } from "../../../helpers";
import Alert from "../../../core/src/components/Alert";
import ResponsiveImage from "../../../core/src/components/ResponsiveImage";
import history from "../../../history";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Text from "../../../core/src/components/Text";
import { create } from "domain";

const styleSheet = ({ color, unit }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh"
  },
  header: {
    height: "135px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    width: "446px",
    margin: "auto",
    paddingBottom: "135px",
    maxWidth: "90vw",
    verticalAlign: "middle"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  error_danger: {
    backgroundColor: color.core.danger[2],
    color: color.core.neutral[4]
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  }
});

const Access = () => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [verifying, setVerifying] = React.useState(false);
  const [isVerified, setVerified] = React.useState(false);
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState({
    demoCode: "",
    email: "",
    firstName: "",
    lastName: "",
    password: ""
  });
  const [serverError, setServerError] = React.useState({
    status: false,
    msg: ""
  });

  const [error, setError] = React.useState({
    demoCode: { status: false },
    email: { status: false },
    firstName: { status: false },
    lastName: { status: false },
    password: { status: false }
  });

  const { loading: demoLoading, data } = useQuery(VERIFY_DEMO_CODE, {
    variables: { democode: user.demoCode },
    skip: !verifying,
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  React.useEffect(() => {
    if (verifying) {
      if (data.demoCode) {
        if (!data.demoCode.userid) {
          setVerifying(false);
          setVerified(true);
          setLoading(false);
          setUser({
            ...user,
            democodeid: data.demoCode.id,
            manufacturer: data.demoCode.manufacturerid
          });
        } else {
          setServerError({
            status: true,
            msg:
              "The access code is already activated or not in our system. Please contact support@obvious.xyz"
          });
          setVerifying(false);
          setVerified(false);
          setLoading(false);
        }
      }
      if (!demoLoading && !data.demoCode) {
        setServerError({
          status: true,
          msg:
            "The access code is already activated or not in our system. Please contact support@obvious.xyz"
        });
        setVerifying(false);
        setVerified(false);
        setLoading(false);
      }
    }
  }, [data]);

  const [create] = useMutation(CREATE_DEMO_USER);

  const updateField = (value, event) => {
    setUser({
      ...user,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setServerError({
      ...serverError,
      status: false
    });
    let submitError = false;
    var errors = {};
    if (!user.demoCode) {
      errors = {
        ...errors,
        demoCode: { status: true }
      };
      submitError = true;
    }

    if (isVerified && !user.email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (isVerified && !validateEmail(user.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (isVerified && !user.firstName) {
      errors = {
        ...errors,
        firstName: { status: true }
      };
      submitError = true;
    }

    if (isVerified && !user.lastName) {
      errors = {
        ...errors,
        lastName: { status: true }
      };
      submitError = true;
    }

    if (
      (isVerified && !user.password) ||
      (isVerified && user.password.length < 8)
    ) {
      errors = {
        ...errors,
        password: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }
    setLoading(true);
    if (!isVerified) {
      setVerifying(true);
    } else {
      create({
        variables: {
          ...user
        }
      })
        .then(({ data: { createDemoUser: { success } } }) => {
          if (success) {
            history.push({
              pathname: "/login",
              state: { accountCreated: true }
            });
          } else {
            setServerError({
              status: true,
              msg: "Something went wrong, Please contact support@obvious.xyz"
            });
          }
          setLoading(false);
        })
        .catch(({ error }) => {
          console.log(error);
          setLoading(false);
          setServerError({
            status: true,
            msg: "Email already exist in our system"
          });
        });
    }
  };

  return (
    <div>
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.form)}>
            <div className={cx(styles.header)}>
              <IconObviousLogo width="200" height="48" decorative dark />
            </div>
            <Spacing vertical={theme.unit / 2}>
              <div className={cx(styles.title)}>
                <Title level={2}>Get started</Title>
                <Link
                  href="/login"
                  afterIcon={<IconCaretRight decorative size={24} />}
                >
                  Log in
                </Link>
              </div>
            </Spacing>
            {serverError.status && (
              <Spacing bottom={theme.unit / 2}>
                <Alert title={serverError.msg} danger full hideStatusIcon />
              </Spacing>
            )}
            <form
              id={id}
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              {!isVerified ? (
                <div>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="demoCode"
                      placeholder="Access Code"
                      label="access code"
                      hideLabel
                      large
                      value={user.demoCode}
                      onChange={updateField}
                      autoComplete="demoCode"
                      noSpacing
                      invalid={error.demoCode.status}
                      errorMessage="Please include an access code"
                    />
                  </Spacing>
                  <Spacing vertical={theme.unit / 2}>
                    <Button
                      large
                      block
                      onClick={handleSubmit}
                      loading={loading}
                      type="submit"
                    >
                      Continue
                    </Button>
                  </Spacing>
                </div>
              ) : (
                <div>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="email"
                      placeholder="Email"
                      label="Email"
                      hideLabel
                      large
                      type="email"
                      value={user.email}
                      onChange={updateField}
                      autoComplete="email"
                      noSpacing
                      invalid={error.email.status}
                      errorMessage="Email is required"
                    />
                  </Spacing>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                      hideLabel
                      large
                      value={user.firstName}
                      onChange={updateField}
                      autoComplete="firstName"
                      noSpacing
                      invalid={error.firstName.status}
                      errorMessage="First name is required"
                    />
                  </Spacing>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      hideLabel
                      large
                      value={user.lastName}
                      onChange={updateField}
                      autoComplete="lastName"
                      noSpacing
                      invalid={error.lastName.status}
                      errorMessage="Last name is required"
                    />
                  </Spacing>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="password"
                      placeholder="Password - 8+ characters"
                      label="Password"
                      hideLabel
                      large
                      value={user.password}
                      onChange={updateField}
                      autoComplete="password"
                      type="password"
                      noSpacing
                      invalid={error.password.status}
                      errorMessage="Password needs to be 8+ characters"
                    />
                  </Spacing>
                  <Spacing top={theme.unit / 2} bottom={2}>
                    <Button
                      large
                      block
                      onClick={handleSubmit}
                      loading={loading}
                      type="submit"
                    >
                      Sign up
                    </Button>
                  </Spacing>
                  <div
                    className={cx({
                      display: "flex",
                      justifyContent: "center"
                    })}
                  >
                    <Text micro>
                      By signing up, I agree to Obvıous's Terms of Service.
                    </Text>
                  </div>
                </div>
              )}
            </form>
            <Divider label="or" />
            <div className={cx({ display: "flex", justifyContent: "center" })}>
              <Link
                large
                block
                href="/create"
                borderless
                afterIcon={<IconCaretRight decorative size={36} />}
              >
                Try Obvıous. and set up your portal
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Access.propTypes = {};

Access.defaultProps = {};

export default Access;
