import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import useStyles from "../../core/src/hooks/useStyles";
import Layout from "../../layout/src/components/Layout";
import MainMenu from "../../components/MainMenu";
import Sidebar from "../../components/Sidebar";
import Spacing from "../../core/src/components/Spacing";
import Menu, { Item } from "../../components/Menu";
import IconDiode from "../../icons/src/interface/IconDiode";
import IconDashboard from "../../icons/src/interface/IconDashboard";
import IconDevice from "../../icons/src/interface/IconDevice";
import Title from "../../core/src/components/Title";
import Android from "./android";
import Content from "../../components/Content";
import Embedded from "./embedded";
import IOS from "./ios";
import Video from "./video";
import Examples from "./example";
import TopMenu from "../../components/TopMenu";

const styleSheet = () => ({
  flex: {
    display: "flex"
  }
});

const Docs = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
          <Sidebar color={"light"}>
            <Spacing left={2} right={4} top={4} bottom={4}>
              <Title primary level={3}>
                Resources
              </Title>
            </Spacing>
            <Spacing vertical={4}>
              <Menu>
                <Item
                  name="Android Docs"
                  to="/docs/android"
                  icon={<IconDashboard decorative />}
                />
                <Item
                  name="Embedded Docs"
                  to="/docs/embedded"
                  icon={<IconDiode size={20} decorative />}
                />
                <Item
                  name="iOS Docs"
                  to="/docs/ios"
                  icon={<IconDevice size={20} decorative />}
                />
                <Item
                  name="Video"
                  to="/docs/video"
                  icon={<IconDevice size={20} decorative />}
                />
                <Item
                  name="Examples"
                  to="/docs/examples"
                  icon={<IconDevice size={20} decorative />}
                />
                <Item
                  name="Integration Checklist"
                  to="/static/images/checklist.png"
                  openInNewWindow
                  noRoute
                />
              </Menu>
            </Spacing>
          </Sidebar>
        </div>
      }
    >
      <TopMenu />
      <Content>
        <Switch>
          <Redirect exact from="/docs" to="/docs/android" />
          <Route
            path="/docs/android"
            render={props => <Android {...props} />}
          />
          <Route
            path="/docs/embedded"
            render={props => <Embedded {...props} />}
          />
          <Route path="/docs/ios" render={props => <IOS {...props} />} />
          <Route path="/docs/video" render={props => <Video {...props} />} />
          <Route
            path="/docs/examples"
            render={props => <Examples {...props} />}
          />
        </Switch>
      </Content>
    </Layout>
  );
};

export default Docs;
