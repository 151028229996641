import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import converter from "hex2dec";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  GET_DFU_SOFTDEVICES,
  GET_DFU_CHIPS,
  GET_DFU_REPOSITORIES,
  CREATE_SDK_REPOSITORY
} from "../../firmware/queries";
import FormActions from "../../../../../core/src/components/FormActions";
import Input from "../../../../../core/src/components/Input";
import Select from "../../../../../core/src/components/Select";
import ToggleButtonController, {
  ButtonGroup
} from "../../../../../core/src/components/ToggleButtonController";
import Spacing from "../../../../../core/src/components/Spacing";
import Alert from "../../../../../core/src/components/Alert";

const FirmwareLibraryForm = ({
  product,
  onClose,
  refetch,
  rebuild,
  ...props
}) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState();
  const [soft, setSoft] = React.useState([]);
  const [library, setLibrary] = React.useState({
    softdeviceid: "",
    builddeviceid: "",
    ocelotflashstart: "0x7B000",
    ocelotresetsaferamstart: ""
  });

  React.useEffect(() => {
    if (rebuild) {
      setLibrary({
        softdeviceid: rebuild.dfubuildid.softdeviceid.rowid,
        builddeviceid: rebuild.dfubuildid.builddeviceid.rowid,
        ocelotflashstart: converter.decToHex(
          rebuild.dfubuildid.ocelotflashstart.toString()
        ),
        ocelotresetsaferamstart: converter.decToHex(
          rebuild.dfubuildid.ocelotresetsaferamstart.toString()
        )
      });
    }
  }, [rebuild]);

  const [error, setError] = React.useState({
    softdeviceid: { status: false, msg: "" },
    builddeviceid: { status: false, msg: "" },
    ocelotflashstart: { status: false, msg: "" },
    ocelotresetsaferamstart: { status: false, msg: "" }
  });

  const { loading: softDeviceLoading, data: softDevices } = useQuery(
    GET_DFU_SOFTDEVICES,
    {
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const { loading: chipsLoading, data: chips } = useQuery(GET_DFU_CHIPS, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const [serverError, setServerError] = React.useState({
    status: false,
    msg: ""
  });

  const [createSdkRepository] = useMutation(CREATE_SDK_REPOSITORY, {
    refetchQueries: [
      {
        query: GET_DFU_REPOSITORIES,
        variables: { productid: product }
      }
    ]
  });

  React.useEffect(() => {
    if (softDevices && softDevices.allDfuSoftdevices) {
      setSoft(
        softDevices.allDfuSoftdevices.edges
          .map(({ node: soft }) => {
            return soft.softdevicename;
          })
          .reduce(
            (unique, item) =>
              unique.includes(item) ? unique : [...unique, item],
            []
          )
      );
    }
  }, [softDevices]);

  React.useEffect(() => {
    if (rebuild) {
      setType(rebuild.dfubuildid.softdeviceid.softdevicename);
    } else {
      setType(soft[0]);
    }
  }, [soft]);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    console.log(value, event.target.name);
    setLibrary({
      ...library,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!library.builddeviceid) {
      errors = {
        ...errors,
        builddeviceid: { status: true }
      };
      submitError = true;
    }

    if (!library.softdeviceid) {
      errors = {
        ...errors,
        softdeviceid: { status: true }
      };
      submitError = true;
    }

    if (
      !library.ocelotflashstart ||
      library.ocelotflashstart.charAt(1) != "x" ||
      library.ocelotflashstart.charAt(1) != "x"
    ) {
      errors = {
        ...errors,
        ocelotflashstart: { status: true }
      };
      submitError = true;
    }

    if (
      !library.ocelotresetsaferamstart ||
      library.ocelotresetsaferamstart.charAt(1) != "x" ||
      library.ocelotresetsaferamstart.charAt(1) != "x"
    ) {
      errors = {
        ...errors,
        ocelotresetsaferamstart: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    createSdkRepository({
      variables: {
        productid: product,
        ...library
      }
    })
      .catch(response => {
        const errors = response.graphQLErrors[0].message;
        setError({
          ...error,
          [errors]: {
            status: true,
            msg: "Invalid Hex"
          }
        });
        setLoading(false);
      })
      .then(success => {
        console.log(success);
        if (success) {
          refetch();
          onClose();
        }
      });
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      {soft.length > 0 && !chipsLoading && (
        <>
          <ToggleButtonController
            value={type}
            name="button-group-controller"
            label="SoftDevice"
            onChange={value => setType(value)}
          >
            {ControlledButton => (
              <ButtonGroup>
                {soft
                  .sort((a, b) => {
                    return a.localeCompare(b);
                  })
                  .map((keyName, keyIndex) => {
                    return (
                      <ControlledButton value={keyName} key={keyName}>
                        {keyName}
                      </ControlledButton>
                    );
                  })}
              </ButtonGroup>
            )}
          </ToggleButtonController>
          <Select
            name="softdeviceid"
            label={`Select a ${type} version`}
            placeholder={`Select a ${type} version`}
            value={library.softdeviceid}
            onChange={updateField}
            invalid={error.softdeviceid.status}
            errorMessage="A soft device version is needed to generate a library"
          >
            {softDevices.allDfuSoftdevices.edges
              .filter(({ node: soft }) => {
                return soft.softdevicename == type;
              })
              .map(({ node: soft }) => {
                return (
                  <option value={soft.rowid} key={soft.id}>
                    {soft.version}
                  </option>
                );
              })}
          </Select>
          <Select
            name="builddeviceid"
            label="Select a chip"
            placeholder="Select a chip"
            value={library.builddeviceid}
            onChange={updateField}
            invalid={error.builddeviceid.status}
            errorMessage={
              error.builddeviceid.msg
                ? error.builddeviceid.msg
                : "A chip is needed to generate a library"
            }
          >
            {type === "S140" ? (
              <option value={5}>NRF52840_XXAA</option>
            ) : (
              chips.allDfuChips.edges.map(({ node: chip }) => (
                <option value={chip.rowid} key={chip.id}>
                  {chip.name}
                </option>
              ))
            )}
          </Select>
          <Input
            name="ocelotflashstart"
            label="Ocelot Flash Start"
            placeholder="0X123"
            value={library.ocelotflashstart}
            onChange={updateField}
            errorMessage={
              error.ocelotflashstart.msg
                ? error.ocelotflashstart.msg
                : "Invalid Hex"
            }
            invalid={error.ocelotflashstart.status}
          />
          <Input
            name="ocelotresetsaferamstart"
            label="Ocelot Reset Safe Ram Start"
            placeholder="0X123"
            value={library.ocelotresetsaferamstart}
            onChange={updateField}
            errorMessage={
              error.ocelotresetsaferamstart.msg
                ? error.ocelotresetsaferamstart.msg
                : "Invalid Hex"
            }
            invalid={error.ocelotresetsaferamstart.status}
          />
        </>
      )}
      {serverError.status && (
        <Spacing bottom={8 / 2}>
          <Alert title={serverError.msg} danger full hideStatusIcon />
        </Spacing>
      )}
      <FormActions
        continueText={`Generate SDK library`}
        processingText={`Generating...`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

FirmwareLibraryForm.propTypes = {};

FirmwareLibraryForm.defaultProps = {};

export default FirmwareLibraryForm;
