import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import useStyles from "../../core/src/hooks/useStyles";
import Layout from "../../layout/src/components/Layout";
import Content from "../../components/Content";
import MainMenu from "../../components/MainMenu";
import Sidebar from "../../components/Sidebar";
import Spacing from "../../core/src/components/Spacing";
import IconDiode from "../../icons/src/interface/IconDiode";
import IconDashboard from "../../icons/src/interface/IconDashboard";
import IconDevice from "../../icons/src/interface/IconDevice";
import Title from "../../core/src/components/Title";
import Catalog from "./Catalog";
import Overview from "./Overview";
import Transactions from "./Transactions";
import TransactionDetail from "./Transactions/TransactionDetail";
import { getRolePermissions } from "../../helpers";
import PageHeader from "../../components/PageHeader";
import Tabs, { Item } from "../../components/Tabs";
import TopMenu from "../../components/TopMenu";

const styleSheet = () => ({
  flex: {
    display: "flex"
  }
});

const Sales = ({ match }) => {
  const permissions = getRolePermissions("sales");
  const [styles, cx] = useStyles(styleSheet);
  return (
    <Layout noPadding noMaxwidth relative before={<MainMenu />}>
      <TopMenu />
      <Content>
        <PageHeader
          title="Sales"
          tabs={
            <Tabs>
              <Item name="Catalog" to={`/sales/catalog`} />
              <Item name="Transactions" to={`/sales/transactions`} />
            </Tabs>
          }
        />
        <Switch>
          <Redirect exact from={`/sales`} to={`/sales/catalog`} />
          <Route
            path={`/sales/catalog`}
            render={props => <Catalog {...props} />}
          />
          <Route
            exact
            path={`/sales/transactions`}
            render={props => <Transactions {...props} />}
          />
          <Route
            path={`/sales/transactions/:transaction`}
            render={props => <TransactionDetail {...props} />}
          />
        </Switch>
      </Content>
    </Layout>
  );
};

export default Sales;
