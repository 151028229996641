import React from "react";
import { getRolePermissions } from "../../../../../helpers";
import AdaptiveGrid from "../../../../../core/src/components/AdaptiveGrid";
import { useQuery } from "@apollo/react-hooks";
import { GET_DFU_REPOSITORIES } from "../queries";
import LogoLoader from "../../../../../core/src/components/LogoLoader";
import FirmwareDFUItem from "./FirmwareDFUItem";

const FirmwareDFU = ({ children, product }) => {
  const permissions = getRolePermissions("product");
  const [refetching, setRefetching] = React.useState(false);
  const { loading, data, refetch, networkStatus } = useQuery(
    GET_DFU_REPOSITORIES,
    {
      variables: { productid: product },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const refetchQuery = () => {
    setRefetching(true);
    refetch();
  };

  React.useEffect(() => {
    setRefetching(false);
  }, [data]);

  return loading || refetching ? (
    <LogoLoader />
  ) : (
    <AdaptiveGrid defaultItemsPerRow={3}>
      {data.allDfuRepositories &&
        data.allDfuRepositories.edges
          .filter(({ node }) => {
            return node.dfubuildid.dfubuildtypeid.rowid === 2;
          })
          .map(({ node }) => (
            <FirmwareDFUItem
              repository={node}
              product={product}
              key={node.id}
              refetch={refetchQuery}
            />
          ))}
    </AdaptiveGrid>
  );
};

export default FirmwareDFU;
