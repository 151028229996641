export const createSlug = (string) => {
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/,:;'
  const b = 'aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------'
  const p = new RegExp(a.split('').join('|'), 'g')
  
  return string.toString().toLowerCase()
  .replace(/\s+/g, '-') // Replace spaces with -
  .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
  .replace(/&/g, '-and-') // Replace & with ‘and’
  .replace(/[^\w\-]+/g, '') // Remove all non-word characters
  .replace(/\-\-+/g, '-') // Replace multiple - with single -
  .replace(/^-+/, '') // Trim - from start of text
  .replace(/-+$/, '') // Trim - from end of text
}

export const isOutOfViewport = (ele) => {
  const bounding = ele.getBoundingClientRect();
  const out = {};
  out.top = bounding.top < 0;
	out.left = bounding.left < 0;
	out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
	out.right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
	out.any = out.top || out.left || out.bottom || out.right;
	out.all = out.top && out.left && out.bottom && out.right;

  return out;
}

export const isAtTopOfViewport = (ele) => {
  const bounding = ele.getBoundingClientRect();
  const top = bounding.top > 0 && bounding.top < 50;

  return top;
}

export const isAtBottomOfViewport = (ele) => {
  const bounding = ele.getBoundingClientRect();
  const bottom = bounding.bottom < (window.innerHeight || document.documentElement.clientHeight);
  return bottom;
} 