import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { AUTHENTICATION, START_LOGIN } from "./queries";
import { GET_PRODUCTS } from "../../products/queries";
import useStyles, { aesthetic } from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import IconObviousLogo from "../../../icons/src/interface/IconObviousLogo";
import Title from "../../../core/src/components/Title";
import Input from "../../../core/src/components/Input";
import Spacing from "../../../core/src/components/Spacing";
import Button from "../../../core/src/components/Button";
import Link from "../../../core/src/components/Link";
import IconCaretRight from "../../../icons/src/interface/IconCaretRight";
import Divider from "../../../core/src/components/Divider";
import {
  validateEmail,
  changeThemeColors,
  lowercaseNoSpace
} from "../../../helpers";
import Alert from "../../../core/src/components/Alert";
import ResponsiveImage from "../../../core/src/components/ResponsiveImage";
import history from "../../../history";
import Text from "../../../core/src/components/Text";
import CheckBox from "../../../core/src/components/CheckBox/index";
import client from "../../../Apollo";

const styleSheet = ({ color, unit }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh"
  },
  header: {
    height: "135px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    width: "446px",
    margin: "auto",
    paddingBottom: "135px",
    maxWidth: "90vw",
    verticalAlign: "middle"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  error_danger: {
    backgroundColor: color.core.danger[2],
    color: color.core.neutral[4]
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  },
  form_start_login: {
    opacity: 0
  },
  logo: {
    position: "absolute",
    zIndex: 10,
    opacity: 1,
    pointerEvents: "none",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0
  },
  logo_outer_circle: {
    width: "256px",
    height: "256px",
    backgroundColor: color.core.primary[3],
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .2s ease-in-out"
  },
  logo_outer_circle_expand: {
    width: "100vw",
    height: "100vh",
    borderRadius: "0px"
  },
  logo_wrapper: {
    display: "flex",
    alignItems: "flex-end",
    willChange: "transform, opacity",
    opacity: 0,
    transform: "translateY(-100%)",
    transition: "all .4s ease-in-out"
  },
  logo_wrapper_show: {
    transform: "translateY(0)",
    opacity: 1
  },
  logo_inner_circle: {
    width: "128px",
    height: "128px",
    backgroundColor: color.base,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logo_inner_circle_expand: {
    width: 200,
    height: "auto",
    backgroundColor: "transparent"
  },
  logo_dot: {
    width: "56px",
    height: "56px",
    backgroundColor: "#000000",
    borderRadius: "100%",
    marginBottom: "16px"
  },
  logo_dot_hide: {
    width: 0,
    height: 0
  },
  logo_company: {
    opacity: 0,
    transition: "all .4s ease-in-out"
  },
  logo_company_show: {
    opacity: 1
  }
});

const Login = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [checkAuthentication] = useMutation(AUTHENTICATION);
  const [loginStart] = useMutation(START_LOGIN);
  const [remember, setRemember] = React.useState(false);
  const [login, setLogin] = React.useState({
    email: "",
    password: ""
  });

  const [error, setError] = React.useState({
    email: { status: false },
    password: { status: false }
  });

  const [serverError, setServerError] = React.useState({
    status: false,
    msg: ""
  });
  const [serverSuccess, setServerSuccess] = React.useState({
    status: false,
    msg: ""
  });
  const [startLogin, setStartLogin] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [company, setCompany] = React.useState(false);
  const [manufacturer, setManufacturer] = React.useState();

  const { data } = useQuery(GET_PRODUCTS, {
    skip: !startLogin,
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  React.useEffect(() => {
    if (location.state && location.state.accountCreated) {
      setServerSuccess({
        status: true,
        msg: "Account was successfully created"
      });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
    if (location.state && location.state.resetPassword) {
      setServerSuccess({
        status: true,
        msg:
          "If your email exists in our database, you'll receive a reset link shortly."
      });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
    if (location.state && location.state.success) {
      setServerSuccess({ status: true, msg: location.state.msg });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
    if (location.state && location.state.error) {
      setServerError({ status: true, msg: location.state.msg });
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
  });

  const updateField = (value, event) => {
    setLogin({
      ...login,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};
    if (!login.email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!validateEmail(login.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!login.password) {
      errors = {
        ...errors,
        password: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }
    setLoading(true);

    checkAuthentication({
      variables: {
        email: login.email,
        password: login.password
      }
    })
      .then(({ data: { tokenAuth } }) => {
        loginStart();
        setStartLogin(true);
        const manufacturer = {
          name: tokenAuth.manufacturer.name,
          color: tokenAuth.manufacturerColor.value,
          logo: tokenAuth.manufacturerLogo.value
        };
        const user = {
          firstName: tokenAuth.user.firstName,
          lastName: tokenAuth.user.lastName,
          email: tokenAuth.user.email,
          userType: tokenAuth.userType.name
        };
        user.userType = lowercaseNoSpace(user.userType);
        localStorage.setItem("ocelot-token", tokenAuth.token);
        localStorage.setItem("manufacturer", JSON.stringify(manufacturer));
        localStorage.setItem("user", JSON.stringify(user));
        setManufacturer(manufacturer);
        changeThemeColors(`#${manufacturer.color}`);
        setTimeout(() => {
          setExpand(true);
        }, 1000);

        setTimeout(() => {
          setCompany(true);
        }, 1250);

        setTimeout(() => {
          history.push("/products");
        }, 2000);
      })
      .catch(() => {
        setServerError({ status: true, msg: "Invalid Email or Password" });
        setServerSuccess({ status: false, msg: "" });
        setLoading(false);
      });
  };

  return (
    <div>
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.content)}>
          <div
            className={cx(styles.form, startLogin && styles.form_start_login)}
          >
            <div className={cx(styles.header)}>
              <IconObviousLogo width="200" height="48" decorative dark />
            </div>
            <Spacing vertical={theme.unit / 2}>
              <div className={cx(styles.title)}>
                <Title level={2}>Log in</Title>
                <Link
                  href="/access"
                  afterIcon={<IconCaretRight decorative size={24} />}
                >
                  Demo access
                </Link>
              </div>
            </Spacing>
            {(error.email.status ||
              error.password.status ||
              serverError.status) &&
              !serverSuccess.status && (
                <Spacing bottom={theme.unit / 2}>
                  <Alert title={serverError.msg} danger full hideStatusIcon />
                </Spacing>
              )}
            {serverSuccess.status && !serverError.status && (
              <Spacing bottom={theme.unit / 2}>
                <Alert title={serverSuccess.msg} notice full hideStatusIcon />
              </Spacing>
            )}
            <form
              id={id}
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <Spacing bottom={theme.unit / 4}>
                <Input
                  name="email"
                  placeholder="Email"
                  label="Email"
                  hideLabel
                  large
                  type="email"
                  value={login.email}
                  onChange={updateField}
                  autoComplete="email"
                  noSpacing
                  invalid={error.email.status}
                />
              </Spacing>
              <Spacing bottom={theme.unit / 4}>
                <Input
                  name="password"
                  placeholder="Password"
                  label="Password"
                  hideLabel
                  large
                  type="password"
                  value={login.password}
                  onChange={updateField}
                  autoComplete="password"
                  noSpacing
                  invalid={error.password.status}
                />
              </Spacing>
              <Spacing vertical={theme.unit / 2}>
                <div
                  className={cx({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  })}
                >
                  <div>
                    <CheckBox
                      name="rememberme"
                      label="Remember me"
                      onChange={() => setRemember(!remember)}
                      checked={remember}
                    />
                  </div>
                  <div>
                    <Link small block href="/forgot">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </Spacing>
              <Spacing vertical={theme.unit / 2}>
                <Button
                  large
                  block
                  onClick={handleSubmit}
                  loading={loading}
                  type="submit"
                >
                  Log in
                </Button>
              </Spacing>
            </form>
            <Divider label="or" />
            <div className={cx({ display: "flex", justifyContent: "center" })}>
              <Link
                large
                block
                href="/create"
                borderless
                afterIcon={<IconCaretRight decorative size={36} />}
              >
                Try Obvıous. and set up your portal
              </Link>
            </div>
          </div>
          <div className={cx(styles.logo)}>
            <div
              className={cx(
                styles.logo_wrapper,
                startLogin && styles.logo_wrapper_show
              )}
            >
              <div
                className={cx(
                  styles.logo_outer_circle,
                  expand && styles.logo_outer_circle_expand,
                  manufacturer && { backgroundColor: `#${manufacturer.color}` }
                )}
              >
                <div
                  className={cx(
                    styles.logo_inner_circle,
                    expand && styles.logo_inner_circle_expand
                  )}
                >
                  <div
                    className={cx(
                      styles.logo_company,
                      company && styles.logo_company_show
                    )}
                  >
                    {manufacturer &&
                    manufacturer.logo &&
                    manufacturer.logo !== "obvious.png" ? (
                      <ResponsiveImage
                        src={manufacturer.logo}
                        maxWidth={200}
                        decorative
                        noShadow
                      />
                    ) : (
                      <IconObviousLogo
                        inverted
                        dark
                        width={200}
                        height={40}
                        decorative
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={cx(styles.logo_dot, expand && styles.logo_dot_hide)}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
