import gql from "graphql-tag";

const GET_DFU_REPOSITORIES = gql`
  query allDfuRepositories($productid: ID!) {
    allDfuRepositories(productid: $productid) {
      edges {
        node {
          rowid
          id
          dfuimagename
          dfudisplayname
          dfubuildsuccessful
          datecreated
          dfupathid {
            pathname
          }
          dfubuildid {
            id
            dfusdkid {
              id
              sdkversionname
            }
            dfubuildtypeid {
              id
              name
              rowid
            }
            builddeviceid {
              id
              name
              rowid
            }
            softdeviceid {
              id
              softdevicename
              version
              rowid
            }
            ocelotflashstart
            ocelotresetsaferamstart
            dfustoragestart
            dfustoragesize
            dfuflashstart
            dfuinfostart
            dfumbrinfostart
            dfublename
            datecreated
          }
        }
      }
    }
  }
`;

const GET_DFU_SOFTDEVICES = gql`
  query allDfuSoftdevices {
    allDfuSoftdevices {
      edges {
        node {
          id
          rowid
          softdevicename
          version
        }
      }
    }
  }
`;

const GET_DFU_CHIPS = gql`
  query allDfuChips {
    allDfuChips {
      edges {
        node {
          rowid
          id
          name
        }
      }
    }
  }
`;

const CREATE_DFU_REPOSITORY = gql`
  mutation createDfuRepository(
    $productid: ID!
    $builddeviceid: Int!
    $softdeviceid: Int!
    $ocelotflashstart: String!
    $ocelotresetsaferamstart: String!
    $dfustoragestart: String!
    $dfustoragesize: String!
    $dfuflashstart: String!
    $dfuinfostart: String!
    $dfumbrinfostart: String!
    $dfublename: String!
  ) {
    createDfuRepository(
      data: {
        productid: $productid
        builddeviceid: $builddeviceid
        softdeviceid: $softdeviceid
        ocelotflashstart: $ocelotflashstart
        ocelotresetsaferamstart: $ocelotresetsaferamstart
        dfustoragestart: $dfustoragestart
        dfustoragesize: $dfustoragesize
        dfuflashstart: $dfuflashstart
        dfuinfostart: $dfuinfostart
        dfumbrinfostart: $dfumbrinfostart
        dfublename: $dfublename
      }
    ) {
      success
    }
  }
`;

const CREATE_SDK_REPOSITORY = gql`
  mutation createSdkRepository(
    $productid: ID!
    $builddeviceid: Int!
    $softdeviceid: Int!
    $ocelotflashstart: String!
    $ocelotresetsaferamstart: String!
  ) {
    createSdkRepository(
      data: {
        productid: $productid
        builddeviceid: $builddeviceid
        softdeviceid: $softdeviceid
        ocelotflashstart: $ocelotflashstart
        ocelotresetsaferamstart: $ocelotresetsaferamstart
      }
    ) {
      success
    }
  }
`;

const DESTROY_DFU_REPOSITORY = gql`
  mutation destroyProductDfuRepository($productid: ID!, $id: ID!) {
    destroyProductDfuRepository(data: { productid: $productid, id: $id }) {
      success
    }
  }
`;

const GET_FIRMWARES = gql`
  query allFirmware($productid: ID!) {
    allFirmware(productid: $productid) {
      edges {
        node {
          id
          version
          versioncode
          originalfilename
          minverfirmware
          maxverfirmware
          datecreated
          comment
          releasenotes
          postupdatemessage
          assignmentsCount
        }
      }
    }
  }
`;

const GET_FIRMWARE = gql`
  query firmware($id: ID!) {
    firmware(id: $id) {
      id
      version
      versioncode
      originalfilename
      minverfirmware
      maxverfirmware
      datecreated
      comment
      releasenotes
      postupdatemessage
      assignmentsCount
      datecreated
    }
  }
`;

const DESTROY_FIRMWARE = gql`
  mutation destroyFirmware($productid: ID!, $id: ID!) {
    destroyFirmware(data: { productid: $productid, id: $id }) {
      firmware {
        id
      }
    }
  }
`;

const CREATE_FIRMWARE = gql`
  mutation createFirmware(
    $productid: ID!
    $version: String!
    $versioncode: Int!
    $releasenotes: String
    $postupdatemessage: String
    $comment: String
    $originalfilename: String!
    $file: Upload!
    $minverfirmware: String!
    $maxverfirmware: String!
  ) {
    createFirmware(
      firmwareData: {
        productid: $productid
        version: $version
        versioncode: $versioncode
        originalfilename: $originalfilename
        releasenotes: $releasenotes
        postupdatemessage: $postupdatemessage
        comment: $comment
        file: $file
        minverfirmware: $minverfirmware
        maxverfirmware: $maxverfirmware
      }
    ) {
      firmware {
        id
        version
        versioncode
        originalfilename
        minverfirmware
        maxverfirmware
        comment
        releasenotes
        datecreated
        postupdatemessage
        assignmentsCount
      }
    }
  }
`;

const UPDATE_FIRMWARE = gql`
  mutation updateFirmware(
    $productid: ID!
    $id: ID!
    $version: String
    $releasenotes: String
    $postupdatemessage: String
    $comment: String
    $originalfilename: String
    $file: Upload
    $minverfirmware: String
    $maxverfirmware: String
  ) {
    updateFirmware(
      firmwareData: {
        id: $id
        productid: $productid
        version: $version
        originalfilename: $originalfilename
        releasenotes: $releasenotes
        postupdatemessage: $postupdatemessage
        comment: $comment
        file: $file
        minverfirmware: $minverfirmware
        maxverfirmware: $maxverfirmware
      }
    ) {
      firmware {
        id
        version
        originalfilename
        minverfirmware
        maxverfirmware
        comment
        releasenotes
        datecreated
        postupdatemessage
        assignmentsCount
      }
    }
  }
`;

export {
  GET_FIRMWARES,
  GET_FIRMWARE,
  UPDATE_FIRMWARE,
  CREATE_FIRMWARE,
  DESTROY_FIRMWARE,
  DESTROY_DFU_REPOSITORY,
  GET_DFU_REPOSITORIES,
  GET_DFU_SOFTDEVICES,
  GET_DFU_CHIPS,
  CREATE_DFU_REPOSITORY,
  CREATE_SDK_REPOSITORY
};
