import gql from "graphql-tag";

const GET_DEVICES = gql`
  query allDevices(
    $productid: ID!
    $serialnumber: String
    $after: String
    $before: String
  ) {
    allDevices(
      productid: $productid
      first: 100
      after: $after
      before: $before
      serialnumber_Istartswith: $serialnumber
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          serialnumber
          datecreated
        }
      }
    }
  }
`;

const GET_DEVICE = gql`
  query device($deviceid: ID!, $productid: ID!) {
    device(id: $deviceid) {
      id
      serialnumber
      active {
        edges {
          node {
            id
            featureid
            expirydate
          }
        }
      }
    }
    allProductFeatures(productid: $productid) {
      edges {
        node {
          id
          rowid
          name
          description
          deactivated
        }
      }
    }
    allReservedFeatures {
      edges {
        node {
          id
          rowid
          name
        }
      }
    }
  }
`;

const UPDATE_DEVICE_FEATURES = gql`
  mutation($id: ID!, $productid: ID!, $deviceid: ID!, $expirydate: String!) {
    updateDeviceActiviation(
      deviceActivationData: {
        id: $id
        productid: $productid
        deviceid: $deviceid
        expirydate: $expirydate
      }
    ) {
      deviceActivation {
        id
        expirydate
        featureid
      }
    }
  }
`;

const CREATE_DEVICE_FEATURES = gql`
  mutation createDeviceActivation(
    $deviceid: ID!
    $featureid: ID!
    $productid: ID!
  ) {
    createDeviceActivation(
      deviceActivationData: {
        productid: $productid
        deviceid: $deviceid
        featureid: $featureid
      }
    ) {
      deviceActivation {
        id
        expirydate
        featureid
      }
    }
  }
`;

const CREATE_DEVICE = gql`
  mutation createDevice(
    $productid: ID!
    $serialnumber: String!
    $key: String!
  ) {
    createDevice(
      deviceData: {
        productid: $productid
        serialnumber: $serialnumber
        key: $key
      }
    ) {
      device {
        id
        serialnumber
        datecreated
      }
    }
  }
`;

export {
  GET_DEVICES,
  GET_DEVICE,
  CREATE_DEVICE,
  UPDATE_DEVICE_FEATURES,
  CREATE_DEVICE_FEATURES
};
