import { DateTime, IANAZone } from "luxon";
import Core from "../../src";

const clientLocale = Core.locale();
const clientZone = new IANAZone(Core.timezone());
const utcZone = new IANAZone("UTC");

export { DateTime };

const createDateTime = (value, _temp) => {
  let { locale, sourceFormat, timezone } = _temp === void 0 ? {} : _temp;
  const options = {
    locale: locale || clientLocale
  };
  let date;

  if (timezone === false) {
    options.zone = utcZone;
  } else if (timezone === true) {
    options.zone = clientZone;
  } else {
    options.zone = timezone || clientZone;
  } // Support moment objects for backwards compat

  const moment = value;

  if (moment && typeof moment === "object" && "_isAMomentObject" in moment) {
    return DateTime.fromISO(moment.toISOString(), options);
  } // Parse in different formats

  if (value instanceof DateTime) {
    date = value.setLocale(options.locale).setZone(options.zone);
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value, options);
  } else if (typeof value === "string" && value) {
    date = sourceFormat
      ? DateTime.fromFormat(value, sourceFormat, options)
      : DateTime.fromISO(value, options);
  } else if (typeof value === "number") {
    date = DateTime.fromMillis(value, options);
  } else {
    date = DateTime.utc().setLocale(options.locale);

    if (options.zone !== "UTC") {
      date = date.setZone(options.zone);
    }
  }

  return date;
};

export default createDateTime;
