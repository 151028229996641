import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Button from "../../../core/src/components/Button";
import Modal from "../../../core/src/components/Modal";
import gql from "graphql-tag";
import useStyles from "../../../core/src/hooks/useStyles";
import Input from "../../../core/src/components/Input";
import {
  obviousServiceUuid,
  obviousControlPointUuid,
  objectTransferServiceUuid,
  genericAccessUuid
} from "../dfu/dfu-lib/dfu-via-chrome";
import ToolsDfu from "../dfu";
import Tool from "../Tool";
import ButtonGroup from "../../../core/src/components/ButtonGroup";
import Spacing from "../../../core/src/components/Spacing";
import Title from "../../../core/src/components/Title";
import AppLoader from "../../../core/src/components/AppLoader";
import Text from "../../../core/src/components/Text";

const styleSheet = ({ unit }) => ({
  statusWrapper: {
    padding: unit,
    borderRadius: unit,
    background: "white"
  },
  statusItemRowContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    padding: unit
  },
  statusItemTitle: { flex: "0 0 auto" },
  statusItemValue: { flex: "1 1 auto", textAlign: "right" }
});

const STATE_FILTER = 1;
const STATE_CONNECTING = 2;
const STATE_CONNECTED = 3;
const STATE_ERROR = 4;

function getUint32FromDataView(value, startByte) {
  if (value.byteLength >= startByte + 4) {
    return (
      (value.getUint8(startByte + 0) << 0) |
      (value.getUint8(startByte + 1) << 8) |
      (value.getUint8(startByte + 2) << 16) |
      (value.getUint8(startByte + 3) << 24)
    );
  } else {
    return undefined;
  }
}

function singleByteHex(b) {
  if (b < 16) {
    return "0" + b.toString(16);
  } else {
    return b.toString(16);
  }
}

function getHexFromDataView(value, startByte, length) {
  let ret = "";
  for (var x = startByte; x < length && x < value.byteLength; x++) {
    ret += singleByteHex(value.getUint8(x));
  }
  return ret;
}

const KeyValueRow = ({ title, value, unit }) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <div className={cx(styles.statusItemRowContainer)}>
      <span className={cx(styles.statusItemTitle)}>{title}</span>
      <span className={cx(styles.statusItemValue)}>{value}</span>
    </div>
  );
};

const DevicesList = ({ match, location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [currentState, setCurrentState] = React.useState(STATE_FILTER);
  const [namePrefix, setNamePrefix] = React.useState("");
  const [statusData, setStatusData] = React.useState({});
  const [storedObviousControlPoint, setObviousControlPoint] = React.useState(
    {}
  );
  const [mySelectedDevice, setSelectedDevice] = React.useState({});

  const onUpdateNamePrefix = value => {
    setNamePrefix(value);
  };

  const onCompleteConnection = selectedDevice => {
    setSelectedDevice(selectedDevice);

    let _deviceServer;
    return selectedDevice.gatt
      .connect()
      .then(deviceServer => {
        _deviceServer = deviceServer;
        return deviceServer.getPrimaryService(obviousServiceUuid);
      })
      .then(obviousService => {
        return obviousService.getCharacteristic(obviousControlPointUuid);
      })
      .then(obviousControlPoint => {
        setObviousControlPoint(obviousControlPoint);
        return obviousControlPoint.startNotifications();
      })
      .then(obviousControlPoint => {
        return new Promise((resolve, reject) => {
          const builtObject = {
            bleName: selectedDevice.name
          };

          obviousControlPoint.addEventListener(
            "characteristicvaluechanged",
            evt => onObviousControlPointChanged(evt)
          );
          let ixWrite = 0;
          const writesToDo = [
            {
              name: "sdkVersion",
              key: 1
            },
            {
              name: "serialNumber",
              key: 2
            },
            {
              name: "cmac",
              key: 3
            },
            {
              name: "time",
              key: 5
            },
            {
              name: "nonce",
              key: 6
            },
            {
              name: "provisioningCmac",
              key: 7
            },
            {
              name: "productId",
              key: 8
            },
            {
              name: "bondKey",
              key: 9
            },
            {
              name: "appVersion",
              key: 33
            },
            {
              name: "bootloaderVersion",
              key: 34
            },
            {
              name: "softDeviceVersion",
              key: 35
            }
          ];
          const doNextWrite = () => {
            const writeToDo = writesToDo[ixWrite];
            if (writeToDo) {
              const charOut = new DataView(new ArrayBuffer(1));
              charOut.setUint8(0, writeToDo.key); // 7e means reset
              return obviousControlPoint.writeValue(charOut);
            } else {
              cleanUp();
            }
          };
          const onObviousControlPointChanged = evt => {
            const writeToDo = writesToDo[ixWrite];
            if (writeToDo) {
              const value = evt.target.value;

              switch (writeToDo.name) {
                case "sdkVersion":
                  const sdk32 = getUint32FromDataView(value, 3);

                  if (isFinite(sdk32)) {
                    const minor = sdk32 % 100;
                    const major = Math.floor((sdk32 % 100000) / 100);
                    const version = Math.floor(sdk32 / 1000000);

                    builtObject["sdkVersion"] = `${version}.${major}.${minor}`;
                  }
                  break;
                case "serialNumber":
                  const serialNumber = getHexFromDataView(value, 3, 8);
                  builtObject["serialNumber"] = serialNumber;
                  break;
                case "cmac":
                  const cmac = getHexFromDataView(value, 3, 16);
                  builtObject["cmac"] = cmac;
                  break;
                case "time":
                  const time = getUint32FromDataView(value, 3);
                  builtObject["time"] = time;
                  break;
                case "nonce":
                  const nonce = getHexFromDataView(value, 3, 16);
                  builtObject["nonce"] = nonce;
                  break;
                case "provisioningCmac":
                  const provisioningCmac = getHexFromDataView(value, 3, 16);
                  builtObject["provisioningCmac"] = provisioningCmac;
                  break;
                case "productId":
                  const productId = getHexFromDataView(value, 3, 16);
                  builtObject["productId"] = productId;
                  break;
                case "getBondKey":
                  break;
                case "appVersion": {
                  const appVersion = getUint32FromDataView(value, 3);

                  if (isFinite(appVersion)) {
                    const minor = appVersion % 100;
                    const major = Math.floor((appVersion % 100000) / 100);
                    const version = Math.floor(appVersion / 1000000);
                    builtObject["appVersion"] = `${version}.${major}.${minor}`;
                  }
                  break;
                }
                case "bootloaderVersion": {
                  const bootloaderVersion = getUint32FromDataView(value, 3);

                  if (isFinite(bootloaderVersion)) {
                    const minor = bootloaderVersion % 100;
                    const major = Math.floor(
                      (bootloaderVersion % 100000) / 100
                    );
                    const version = Math.floor(bootloaderVersion / 1000000);

                    builtObject[
                      "bootloaderVersion"
                    ] = `${version}.${major}.${minor}`;
                  }
                  break;
                }
                case "softDeviceVersion": {
                  const softDeviceVersion = getUint32FromDataView(value, 3);

                  if (isFinite(softDeviceVersion)) {
                    const minor = softDeviceVersion % 100;
                    const major = Math.floor(
                      (softDeviceVersion % 100000) / 100
                    );
                    const version = Math.floor(softDeviceVersion / 1000000);

                    builtObject[
                      "softDeviceVersion"
                    ] = `${version}.${major}.${minor}`;
                  }
                  break;
                }
              }
              ixWrite++;
              doNextWrite();
            } else {
              cleanUp();
            }
          };

          const cleanUp = () => {
            obviousControlPoint.removeEventListener(
              "characteristicvaluechanged",
              onObviousControlPointChanged
            );
            resolve(builtObject);
          };
          doNextWrite(); // this will trigger a sequence of writes, eventually resolving with a call to cleanUp() which will resolve our overall returned promise
        });
      });
  };

  const onConnect = () => {
    setCurrentState(STATE_CONNECTING);

    const filterForTarget = {
      filters: [{ namePrefix: [namePrefix] }],
      optionalServices: [
        obviousServiceUuid,
        objectTransferServiceUuid,
        genericAccessUuid,
        "generic_access"
      ]
    };
    navigator.bluetooth.requestDevice(filterForTarget).then(
      device => {
        console.log("You selected ", device);
        return onCompleteConnection(device).then(builtInformation => {
          console.log("built ", builtInformation);
          setStatusData(builtInformation);
          setCurrentState(STATE_CONNECTED);
        });
      },
      deviceNotSelected => {
        // they didn't pick a device or it isn't supported
        console.error(
          deviceNotSelected,
          deviceNotSelected.code,
          deviceNotSelected.message
        );
        setCurrentState(STATE_FILTER);
      }
    );
  };

  const onPickAnotherDevice = () => {
    if (mySelectedDevice) {
      mySelectedDevice.gatt.disconnect();
    }

    setCurrentState(STATE_FILTER);
  };

  const onReconnect = () => {
    if (mySelectedDevice) {
      setCurrentState(STATE_CONNECTING);
      mySelectedDevice.gatt.disconnect();

      setTimeout(() => {
        return onCompleteConnection(mySelectedDevice).then(builtObject => {
          setStatusData(builtObject);
          setCurrentState(STATE_CONNECTED);
        });
      }, 1000);
    }
  };

  const filterContent =
    currentState === STATE_FILTER ? (
      <>
        <Input
          value={namePrefix}
          label="Device prefix"
          labelDescription="Enter a prefix filter for your device, then click connect."
          onChange={onUpdateNamePrefix}
        />
        <Button onClick={onConnect}>Connect</Button>
      </>
    ) : (
      undefined
    );

  const connectingContent =
    currentState === STATE_CONNECTING ? (
      <AppLoader
        centered
        small
        failureText="Failed connecting to device"
        loadingText="Connecting to device"
      />
    ) : (
      undefined
    );

  const connectedContent =
    currentState === STATE_CONNECTED ? (
      <>
        <Spacing bottom={2}>
          <Title truncated level={3}>
            {statusData.bleName} information
          </Title>
        </Spacing>
        <div className={cx(styles.statusWrapper)}>
          <KeyValueRow title="Bluetooth Name" value={statusData.bleName} />
          <KeyValueRow title="App Version" value={statusData.appVersion} />
          <KeyValueRow
            title="Bootloader Version"
            value={statusData.bootloaderVersion}
          />
          <KeyValueRow title="SDK Version" value={statusData.sdkVersion} />
          <KeyValueRow
            title="Softdevice Version"
            value={statusData.softDeviceVersion}
          />
          <KeyValueRow title="CMAC" value={statusData.cmac} />
          <KeyValueRow title="Nonce" value={statusData.nonce} />
          <KeyValueRow title="Product ID" value={statusData.productId} />
          <KeyValueRow
            title="Provisioning CMAC"
            value={statusData.provisioningCmac}
          />
          <KeyValueRow title="Serial #" value={statusData.serialNumber} />
          <KeyValueRow title="Time" value={statusData.time} />
        </div>

        <div>
          <Spacing vertical={4}>
            <ButtonGroup>
              <Button onClick={onReconnect}>Reconnect/Refresh</Button>
              <Button borderless onClick={onPickAnotherDevice}>
                Pick Another Device
              </Button>
            </ButtonGroup>
          </Spacing>
        </div>
      </>
    ) : (
      undefined
    );

  return (
    <Tool
      header={{
        title: "Browser DFU",
        subtitle: "Interrogate Obvious-Enabled Devices Close to Your Computer"
      }}
      sidebar={
        !connectedContent ? (
          filterContent
        ) : (
          <ToolsDfu inputDevice={mySelectedDevice}></ToolsDfu>
        )
      }
    >
      {currentState === STATE_FILTER && (
        <div>
          <Text bold large>
            Description
          </Text>
          <Spacing top={2}>
            <Text>
              This is especially useful for developers! Simply for the fact that
              no phone is required to do any of the updating. The information
              that is populated here is queried from the device itself over
              Bluetooth. To update to a new firmware version, either choose your
              encrypted .img file or simply drag and drop your file in the box
              and select update firmware. If you accidentally close the browser,
              open a new one, load the same firmware file into the box and the
              update will resume from where it left off. Once this is done,
              refresh the page and the new firmware version will show up!
            </Text>
          </Spacing>
        </div>
      )}

      <div>
        {connectingContent}
        {connectedContent}
      </div>
    </Tool>
  );
};

export default withRouter(DevicesList);
