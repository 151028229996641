import React from 'react';
import withIcon from '../withIcon';

const IconPhoto = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.9 13.98l2.1 2.53 3.1-3.99c.2-.26.6-.26.8.01l3.51 4.68a.5.5 0 0 1-.4.8H6.02c-.42 0-.65-.48-.39-.81L8.12 14c.19-.26.57-.27.78-.02z" />
    </svg>
  );
};

export default withIcon('IconPhoto')(IconPhoto);
