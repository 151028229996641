import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import useStyles from "../../../core/src/hooks/useStyles";
import { createSlug } from "./helpers";

const styleSheet = ({ unit }) => ({
  scroll_menu_item: {
    paddingTop: unit,
    paddingBottom: unit,
    display: "block",
    pointerEvents: "auto"
  }
});

const ScrollMenu = ({ headings }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <ul>
      {headings.map((item, index) => {
        const slug = createSlug(item);
        return (
          <li key={index}>
            <Link className={cx(styles.scroll_menu_item)} to={`#${slug}`}>
              <span>{item}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

ScrollMenu.propTypes = {
  heading: PropTypes.array
};

ScrollMenu.defaultProps = {
  heading: []
};

export default ScrollMenu;
