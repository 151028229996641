import React from "react";
import _pt from "prop-types";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_GROUP, GET_FIRMWARE_ASSIGNMENT } from "../queries";
import { GET_PRODUCT } from "../../../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import PageHeader from "../../../../../components/PageHeader";
import LogoLoader from "../../../../../core/src/components/LogoLoader";
import Tabs, { Item } from "../../../../../components/Tabs";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../../core/src/components/Breadcrumbs";
import GroupsFirmwareList, { Firmware } from "./GroupFirmwareList";
import Text from "../../../../../core/src/components/Text";
import GroupsDeviceList from "./GroupDevicesList";
import Layout from "../../../../../layout/src/components/Layout";
import Content from "../../../../../components/Content";
import MainMenu from "../../../../../components/MainMenu";
import Link from "../../../../../core/src/components/Link";
import ProfilePhoto from "../../../../../core/src/components/ProfilePhoto";
import { getInitials } from "../../../../../helpers";
import Spacing from "../../../../../core/src/components/Spacing";
import DirectionalIcon from "../../../../../core/src/components/DirectionalIcon";
import IconChevronLeft from "../../../../../icons/src/interface/IconChevronLeft";
import IconChevronRight from "../../../../../icons/src/interface/IconChevronRight";
import ProductBreadcrumb from "../../../../../components/ProductBreadcrumb";
import Title from "../../../../../core/src/components/Title";
import Chip from "../../../../../core/src/components/Chip";
import StatusLabel from "../../../../../core/src/components/StatusLabel";
import TopMenu from "../../../../../components/TopMenu";

const styleSheet = ({ color, unit }) => ({
  flex_center: {
    display: "flex",
    alignItems: "center"
  },
  tabs: {
    borderBottom: "solid 2px",
    borderColor: color.core.neutral[1]
  }
});

const GroupDetail = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [productID] = React.useState(match.params.product);
  const [groupID] = React.useState(match.params.group);
  const { loading, data } = useQuery(GET_GROUP, {
    variables: { id: groupID },
    skip: groupID === "base",
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });
  const { loading: firmwareLoading, data: assignmentData } = useQuery(
    GET_FIRMWARE_ASSIGNMENT,
    {
      variables: {
        groupid: groupID === "base" ? null : groupID,
        productid: productID
      },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    variables: { id: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });
  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <TopMenu />
      <Content>
        {loading || firmwareLoading || productLoading ? (
          <LogoLoader />
        ) : (
          <>
            <ProductBreadcrumb product={productData.product}>
              <Breadcrumbs center accessibilityLabel="Breadcrumb">
                <Breadcrumb
                  highlighted
                  label="Groups"
                  href={`/products/${productID}/groups`}
                />
                <Breadcrumb
                  hideIcon
                  label={
                    groupID === "base" ? "Base Group" : data && data.group.name
                  }
                />
              </Breadcrumbs>
            </ProductBreadcrumb>
            <Spacing bottom={2} top={4}>
              <div className={cx(styles.flex_center)}>
                <Title level={2}>
                  {groupID === "base" ? "Base Group" : data && data.group.name}
                </Title>
                <Spacing left={2}>
                  <StatusLabel compact>
                    Priority {data && data.group.priority}
                  </StatusLabel>
                </Spacing>
              </div>

              <Text truncated leading="none">
                {groupID === "base"
                  ? "All devices will default to the base group if there is no higher priority"
                  : data && data.group.description}
              </Text>
            </Spacing>
            <Spacing bottom={4}>
              <div className={cx(styles.tabs)}>
                <Tabs>
                  <Item
                    name="Firmware"
                    to={`/products/${productID}/groups/${groupID}/firmware`}
                  />
                  <Item
                    name="Devices"
                    to={`/products/${productID}/groups/${groupID}/devices`}
                  />
                </Tabs>
              </div>
            </Spacing>

            <Switch>
              <Redirect
                exact
                from="/products/:product/groups/:group"
                to="/products/:product/groups/:group/firmware"
              />
              <Route
                path="/products/:product/groups/:group/firmware"
                render={() => (
                  <GroupsFirmwareList group={groupID} product={productID}>
                    {assignmentData.allFirmwareAssignments &&
                      assignmentData.allFirmwareAssignments.edges.map(
                        ({ node: assignment }) => (
                          <Firmware
                            key={assignment.id}
                            assignment={assignment}
                            product={productID}
                            group={groupID}
                          >
                            <Text bold>{assignment.firmware.version}</Text>
                            <Text small>{assignment.comment}</Text>
                          </Firmware>
                        )
                      )}
                  </GroupsFirmwareList>
                )}
              />
              <Route
                path="/products/:product/groups/:group/devices"
                render={() => (
                  <GroupsDeviceList group={groupID} product={productID} />
                )}
              />
            </Switch>
          </>
        )}
        ;
      </Content>
    </Layout>
  );
};

GroupDetail.propTypes = {};

GroupDetail.defaultProps = {};

export default withRouter(GroupDetail);
