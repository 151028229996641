import React from 'react';
import _pt from 'prop-types';
import List from '../../core/src/components/List';
import Item from './Item';

export { Item };

const Menu = ({ children }) => {
  return <List>{children}</List>;
};

Menu.propTypes = {
  children: _pt.node.isRequired,
};

export default Menu;
