import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_FEATURE, UPDATE_FEATURE, GET_FEATURES } from "../queries";
import FormActions from "../../../../../core/src/components/FormActions";
import TextArea from "../../../../../core/src/components/TextArea";
import Input from "../../../../../core/src/components/Input";
import Select from "../../../../../core/src/components/Select";
import Switch from "../../../../../core/src/components/Switch";

const FeatureForm = ({ product, selectedFeature, onClose, ...props }) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [feature, setFeature] = React.useState({
    name: "",
    description: "",
    defaultfeature: 1,
    deactivated: true
  });
  const [error, setError] = React.useState({
    name: { status: false }
  });
  const [type, setType] = React.useState("create");

  const [createFeature] = useMutation(CREATE_FEATURE, {
    update(
      cache,
      {
        data: {
          createFeature: { feature }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_FEATURES,
        variables: { productid: product }
      });
      data.allProductFeatures.edges.push({
        node: { ...feature },
        __typename: "FeatureNodeEdge"
      });
      cache.writeQuery({
        query: GET_FEATURES,
        variables: { productid: product },
        data
      });
    }
  });

  const [updateFeature] = useMutation(UPDATE_FEATURE);

  React.useEffect(() => {
    if (selectedFeature) {
      setFeature({ ...selectedFeature });
      setType("edit");
    }
  }, []);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const toggleFeature = () => {
    setFeature({
      ...feature,
      deactivated: !feature.deactivated
    });
  };

  const updateField = (value, event) => {
    setFeature({
      ...feature,
      [event.target.name]: value
    });
  };

  const validate = e => {
    e.preventDefault();
    if (!feature.name) {
      setError({
        name: { status: true }
      });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    if (type === "create") {
      createFeature({
        variables: {
          ...feature,
          productid: product
        },
        optimisticResponse: {
          __typename: "Mutation",
          createFeature: {
            feature: {
              id: -1,
              ...feature,
              __typename: "FeatureNode"
            },
            __typename: "createFeature"
          }
        }
      }).catch(error => {
        setLoading(false);
      });
      onClose();
    }
    if (type === "edit") {
      updateFeature({
        variables: {
          ...feature,
          productid: product
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateFeature: {
            feature: {
              ...feature,
              __typename: "FeatureNode"
            },
            __typename: "UpdateFeature"
          }
        }
      }).catch(error => {
        setLoading(false);
      });
      onClose();
    }
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Input
        name="name"
        label="Feature Name"
        placeholder="LED blinking light"
        value={feature.name}
        onChange={updateField}
        errorMessage="Feature name is needed"
        invalid={error.name.status}
      />
      <TextArea
        name="description"
        label="Describe Your Feature"
        placeholder="The feature will add a blinking light"
        value={feature.description}
        onChange={updateField}
        optional
      />
      <Select
        name="defaultfeature"
        label="Select how you would like to deliver the feature"
        value={feature.defaultfeature}
        onChange={updateField}
      >
        <option value={1}>Provision</option>
        <option value={0}>Purchasable</option>
      </Select>
      <Switch
        label="Feature Status"
        checked={!feature.deactivated}
        onChange={toggleFeature}
      />
      <FormActions
        continueText={`${type === "create" ? "Create" : "Update"} feature`}
        processingText={`${type === "create" ? "Creating..." : "Updating..."}`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

FeatureForm.propTypes = {};

FeatureForm.defaultProps = {};

export default FeatureForm;
