import React from "react";
import gql from "graphql-tag";
import { GET_PRODUCT, GET_PRODUCTS } from "../queries";
import { useQuery } from "@apollo/react-hooks";
import { Switch, Route, Redirect } from "react-router-dom";
import useStyles from "../../../core/src/hooks/useStyles";
import Layout from "../../../layout/src/components/Layout";
import LogoLoader from "../../../core/src/components/LogoLoader/index";
import Features from "./features";
import Content from "../../../components/Content";
import Firmware from "./firmware";
import Devices from "./devices";
import Groups from "./groups";
import Keys from "./settings";
import GroupDetail from "./groups/Group";
import Device from "./devices/Device";
import FirmwareDetail from "./firmware/FirmwareDetail";
import MainMenu from "../../../components/MainMenu";
import Capabilities from "./capabilities";
import Capability from "./capabilities/capability";
import { getRolePermissions, getInitials } from "../../../helpers";
import TopMenu from "../../../components/TopMenu";
import PageHeader from "../../../components/PageHeader";
import Tabs, { Item } from "../../../components/Tabs";
import LibGenerator from "./LibGenerator";

const styleSheet = () => ({
  flex: {
    display: "flex"
  }
});

const Product = ({ match }) => {
  const permissions = getRolePermissions("product");
  const [productID, setProductID] = React.useState(match.params.product);
  const [styles, cx] = useStyles(styleSheet);

  const { loading, error, data, refetch } = useQuery(GET_PRODUCT, {
    variables: { id: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <TopMenu />
      <Content>
        {loading ? (
          <LogoLoader />
        ) : (
          <>
            <PageHeader
              title={data.product.name}
              initials={getInitials(data.product.name)}
              tabs={
                <Tabs>
                  {permissions.features.includes("READ") && (
                    <Item
                      name="Features"
                      to={`/products/${productID}/features`}
                    />
                  )}
                  {permissions.devices.includes("READ") && (
                    <Item
                      name="Devices"
                      to={`/products/${productID}/devices`}
                    />
                  )}
                  {permissions.groups.includes("READ") && (
                    <Item name="Groups" to={`/products/${productID}/groups`} />
                  )}
                  {permissions.firmware.includes("READ") && (
                    <Item
                      name="Firmware"
                      to={`/products/${productID}/firmware`}
                    />
                  )}
                  {permissions.firmware.includes("READ") && (
                    <Item
                      name="Generator"
                      to={`/products/${productID}/generator`}
                    />
                  )}
                  {permissions.keys.includes("READ") && (
                    <Item
                      name="Settings"
                      to={`/products/${productID}/settings`}
                    />
                  )}
                  {/* {permissions.capabilities.includes("READ") && (
                  <Item
                    name="Capabilities"
                    to={`/products/${productID}/capabilities`}
                  />
                )} */}
                </Tabs>
              }
            />
            <Switch>
              <Redirect
                exact
                from={`/products/:product`}
                to={`/products/:product/features`}
              />
              <Route
                path={`/products/:product/features`}
                render={props => <Features {...props} />}
              />
              <Route
                exact
                path={`/products/:product/groups`}
                render={props => <Groups />}
              />
              <Route
                exact
                path={`/products/:product/firmware`}
                render={props => <Firmware {...props} />}
              />
              <Route
                exact
                path={`/products/:product/generator`}
                render={props => <LibGenerator {...props} />}
              />
              <Route
                exact
                path={`/products/:product/firmware/list`}
                render={props => <Firmware {...props} />}
              />
              <Route
                exact
                path={`/products/:product/firmware/library`}
                render={props => <Firmware {...props} />}
              />
              <Route
                exact
                path={`/products/:product/firmware/dfu`}
                render={props => <Firmware {...props} />}
              />
              <Route
                exact
                path={`/products/:product/devices`}
                component={Devices}
              />

              <Route
                exact
                path={`/products/:product/settings`}
                render={props => (
                  <Keys
                    keys={data.product.productkey.edges[0].node.encryptionkey}
                  />
                )}
              />
              <Route
                exact
                path={`/products/:product/capabilities`}
                component={Capabilities}
              />
              <Route
                path={`/products/:product/capabilities/:capability`}
                component={Capability}
              />
            </Switch>
          </>
        )}
      </Content>
    </Layout>
  );
};

export default Product;
