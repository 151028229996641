import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "./Apollo";
import history from "./history";
import Core from "./core/src";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

Core.initialize();

ReactDOM.render(
  <Router history={history} basename="/">
    <ApolloProvider client={ApolloClient}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
