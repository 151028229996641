import React from "react";
import _pt from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GET_MANUFACTURER_INTEGRATION_TYPE,
  GET_MANUFACTURER_INTEGRATION_CONFIG,
  CREATE_MANUFACTURER_INTEGRATION_CONFIG,
  UPDATE_MANUFACTURER_INTEGRATION_CONFIG,
  DESTROY_MANUFACTURER_INTEGRATION_CONFIG
} from "./queries";
import useStyles from "../../../core/src/hooks/useStyles";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Select from "../../../core/src/components/Select";
import Input from "../../../core/src/components/Input";
import Button from "../../../core/src/components/Button";
import ButtonGroup from "../../../core/src/components/ButtonGroup/index";
import DangerButton from "../../../core/src/components/DangerButton/index";

const styleSheet = ({ color, unit }) => ({});

const NRFConnectSettings = ({}) => {
  const [styles, cx] = useStyles(styleSheet);
  const [oldValue, setOldValue] = React.useState();
  const [value, setValue] = React.useState();
  const [configId, setConfigID] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const {
    loading: configLoading,
    error: configErrorr,
    data: { allManufacturerConfigs }
  } = useQuery(GET_MANUFACTURER_INTEGRATION_CONFIG, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const [createConfig] = useMutation(CREATE_MANUFACTURER_INTEGRATION_CONFIG, {
    update(
      cache,
      {
        data: {
          createManufacturerIntegrationConfig: { manufacturerIntegrationConfig }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_MANUFACTURER_INTEGRATION_CONFIG
      });
      data.allManufacturerConfigs.edges.push({
        node: { ...manufacturerIntegrationConfig },
        __typename: "ManufacturerIntegrationConfigEdge"
      });
      cache.writeQuery({
        query: GET_MANUFACTURER_INTEGRATION_CONFIG,
        data
      });
    }
  });

  const [updateConfig] = useMutation(UPDATE_MANUFACTURER_INTEGRATION_CONFIG);
  const [destroyConfig] = useMutation(DESTROY_MANUFACTURER_INTEGRATION_CONFIG);

  React.useEffect(() => {
    if (allManufacturerConfigs) {
      const value = allManufacturerConfigs.edges.filter(({ node: config }) => {
        console.log(config);
        if (config.integrationtypeid === 1) {
          return config;
        }
      });
      if (value.length > 0) {
        setValue(value[0].node.apikey);
        setOldValue(value[0].node.apikey);
        setConfigID(value[0].node.id);
      } else {
        setValue();
      }
      console.log(value);
    }
  }, [allManufacturerConfigs]);

  const updateField = (value, event) => {
    setValue(value);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!configId) {
      createConfig({
        variables: {
          integrationtypeid: 1,
          value: value
        }
      })
        .then(
          ({
            data: {
              createManufacturerIntegrationConfig: { config }
            }
          }) => {
            setConfigID(config.id);
            setOldValue(value);
            setLoading(false);
          }
        )
        .catch(error => {
          console.log(error);
        });
    }
    if (configId) {
      updateConfig({
        variables: {
          id: configId,
          value: value
        }
      })
        .then(res => {
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleDelete = () => {
    setDeleteLoading(true);
    if (configId) {
      destroyConfig({
        variables: { id: configId }
      })
        .then(res => {
          setDeleteLoading(false);
          setValue();
          setOldValue();
          setConfigID();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return configLoading ? (
    <LogoLoader />
  ) : (
    <div>
      <form>
        <Input
          label="API Key"
          labelDescription="You can find your nRF connect api key on their website"
          name="value"
          value={value}
          onChange={updateField}
        />
        <ButtonGroup>
          <Button
            onClick={handleSubmit}
            loading={loading}
            disabled={value === oldValue}
          >
            Save
          </Button>
          <DangerButton
            loading={deleteLoading}
            onClick={handleDelete}
            disabled={!configId}
            inverted
          >
            Delete
          </DangerButton>
        </ButtonGroup>
      </form>
    </div>
  );
};

NRFConnectSettings.propTypes = {};

NRFConnectSettings.defaultProps = {};

export default NRFConnectSettings;
