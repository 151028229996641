import React from 'react';
import _pt from 'prop-types';
import withIcon from '../withIcon';
import useTheme from '../../../core/src/hooks/useTheme';

const IconDevice = ({ firstColor, secondColor, ...props }) => {
  const theme = useTheme();

  return (
    <svg {...props} viewBox="0 0 13 20">
      <g
        transform="translate(-36.000000, -282.000000) translate(36.000000, 282.000000)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          fill={secondColor ? secondColor : '#ffffff'}
          x="1.51385802"
          y="2.44795961"
          width="9.73194444"
          height="1"
        />
        <rect
          fill={secondColor ? secondColor : '#ffffff'}
          x="2.16265432"
          y="1.2239798"
          width="8.65061728"
          height="1"
        />
        <rect
          fill={secondColor ? secondColor : '#ffffff'}
          x="2.59518519"
          y="0"
          width="7.56929012"
          height="1"
        />
        <rect
          fill={secondColor ? secondColor : '#ffffff'}
          transform="translate(6.379830, 17.135717) scale(-1, -1) translate(-6.379830, -17.135717) "
          x="1.51385802"
          y="16.727724"
          width="9.73194444"
          height="1"
        />
        <rect
          fill={secondColor ? secondColor : '#ffffff'}
          transform="translate(6.487963, 18.359697) scale(-1, -1) translate(-6.487963, -18.359697) "
          x="2.16265432"
          y="17.9517038"
          width="8.65061728"
          height="1"
        />
        <rect
          fill={secondColor ? secondColor : '#ffffff'}
          transform="translate(6.379830, 19.583677) scale(-1, -1) translate(-6.379830, -19.583677) "
          x="2.59518519"
          y="19.1756836"
          width="7.56929012"
          height="1"
        />
        <rect
          stroke={firstColor ? firstColor : theme.color.core.primary[3]}
          strokeWidth="0.81680334"
          x="0.40840167"
          y="4.28433772"
          width="12.1591226"
          height="11.4229947"
          rx="2.04200835"
        />
      </g>
    </svg>
  );
};

IconDevice.propTypes = {
  firstColor: _pt.string,
  secondColor: _pt.string,
};

IconDevice.defaultProps = {
  firstColor: 'brand-teal',
  secondColor: 'brand-teal-light',
};

export default withIcon('IconDevice')(IconDevice);
