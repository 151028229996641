import React from "react";
import _pt from "prop-types";
import useStyles from "../../../../core/src/hooks/useStyles";
import PageHeader from "../../../../components/PageHeader";
import Row from "../../../../core/src/components/Row";
import Title from "../../../../core/src/components/Title";
import Text from "../../../../core/src/components/Text";
import Copy from "../../../../core/src/components/Copy";
import Spacing from "../../../../core/src/components/Spacing";

const styleSheet = ({ color, unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 2
  }
});

const Keys = ({ keys }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className={cx(styles.row_wrapper)}>
      <Row
        center
        after={
          <div className={cx(styles.options)}>
            <div className={cx(styles.options_items)}>
              <Text bold large>
                {keys}
              </Text>
            </div>
            <Copy text={keys} />
          </div>
        }
      >
        <div>
          <Spacing bottom={1}>
            <Text bold>Product Key</Text>
          </Spacing>
          <Text>
            This is your unique application identifier. It's used to identify
            your product when using Obvıous.
          </Text>
        </div>
      </Row>
    </div>
  );
};

Keys.propTypes = {};

Keys.defaultProps = {};

export default Keys;
