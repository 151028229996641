import Color from "color";
import buildTheme from "./buildTheme";

const manufacturer = JSON.parse(localStorage.getItem("manufacturer"));
const location = window.location.pathname;

const getColor =
  manufacturer && !location.includes("/login") && location !== "/"
    ? `${manufacturer.color.includes("#") ? "" : "#"}${manufacturer.color}`
    : "#00B1A9";

let primary;

const mainColor = Color(getColor).hsl();

primary = [
  mainColor.lighten(0.25).hex(),
  mainColor.lighten(0.125).hex(),
  mainColor.lighten(0.062).hex(),
  mainColor.hex(),
  mainColor.darken(0.125).hex(),
  mainColor.darken(0.25).hex()
];

const brand = {
  luxury: [
    "#BFC1FF",
    "#8A88F0",
    "#665CDA",
    "#4D3CBE",
    "#3D279B",
    "#2F1A71",
    "#1E1040"
  ],
  plus: [
    "#FFBFE4",
    "#D467A1",
    "#AE336D",
    "#8C1848",
    "#6E0E31",
    "#550D23",
    "#40101C"
  ]
};

const aside = {
  background: "#ffffff",
  backgroundHover: "#F5F7FA",
  border: "#d6d6d6",
  color: "#767676"
};

const card = {
  background: "#ffffff",
  backgroundHover: "#fafafa"
};

const color = {
  // Gray
  neutral: [
    "#F5F7FA",
    "#E4E7EB",
    "#CBD2D9",
    "#9AA5B1",
    "#7B8794",
    "#616E7C",
    "#52606D",
    "#3E4C59",
    "#323F4B",
    "#1F2933"
  ],
  // Blue
  secondary: [
    "#E3ECF6",
    "#B9D0E8",
    "#8AB0D9",
    "#1561B2",
    "#0F4FA2",
    "#0C4599",
    "#06338A"
  ],
  // Any
  primary,
  purple: [
    "#E0E8F9",
    "#BED0F7",
    "#98AEEB",
    "#7B93DB",
    "#647ACB",
    "#4C63B6",
    "#4055A8",
    "#35469C",
    "#2D3A8C",
    "#19216C",
    "#273B9B"
  ],
  pink: [
    "#FFE3EC",
    "#FFB8D2",
    "#FF8CBA",
    "#F364A2",
    "#F446A8",
    "#E8368F",
    "#DA127D",
    "#BC0A6F",
    "#A30664",
    "#870557",
    "#620042"
  ],
  teal: [
    "#E1FCF8",
    "#C1FEF6",
    "#92FDF2",
    "#62F4EB",
    "#3AE7E1",
    "#1CD4D4",
    "#0FB5BA",
    "#099AA4",
    "#07818F",
    "#05606E"
  ],
  // Green
  green: [
    "#E3F9E5",
    "#C1F2C7",
    "#91E697",
    "#51CA58",
    "#31B237",
    "#18981D",
    "#0F8613",
    "#0E7817",
    "#07600E",
    "#014807"
  ],
  // Yellow/Orange
  yellow: [
    "#FFFBEA",
    "#FFF3C4",
    "#FCE588",
    "#FADB5F",
    "#F7C948",
    "#F0B429",
    "#DE911D",
    "#CB6E17",
    "#B44D12",
    "#8D2B0B"
  ],
  // Red
  red: [
    "#FFE3E3",
    "#FFBDBD",
    "#FF9B9B",
    "#F86A6A",
    "#EF4E4E",
    "#E12D39",
    "#CF1124",
    "#AB091E",
    "#8A041A",
    "#610316"
  ],

  // Green
  success: [
    "#E9F5E8",
    "#C9E5C5",
    "#A5D49F",
    "#4AA83E",
    "#3A9730",
    "#328D28",
    "#227D1B"
  ],
  // Yellow/Orange
  warning: [
    "#FFF5DB",
    "#FFE196",
    "#FFDA80",
    "#FFB400",
    "#F7A800",
    "#FF9C00",
    "#FF8C00"
  ],
  // Red/Pink
  danger: [
    "#FCEBEB",
    "#EF9191",
    "#E44444",
    "#DF2323",
    "#D21515",
    "#C50A0A",
    "#B71C1C"
  ]
};
export default fontFamily =>
  buildTheme({
    base: "#F5F7FA",
    brand,
    color,
    fontFamily,
    aside,
    card
  });
