import React from "react";
import _pt from "prop-types";
import { matchPath, withRouter } from "react-router-dom";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import RouterNavLink from "../../core/src/components/RouterNavLink";

const styleSheet = ({ color, font, unit }) => ({
  tab_list_item: {
    marginRight: unit * 3
  },
  tab_list_item_link: {
    ...font.textReset,
    ...font.textSmall,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    position: "relative",
    textDecoration: "none",
    whiteSpace: "nowrap",
    textAlign: "center",
    borderBottom: "2px solid",
    borderColor: "transparent",
    color: color.core.neutral[5],
    height: unit * 5
  },
  tab_list_item_link_active: {
    borderColor: color.core.primary[3]
  }
});

const TabItem = ({ name, to, location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const isActive = matchPath(location.pathname, { path: to });

  const Color = {
    color: isActive ? theme.color.core.primary[3] : theme.color.core.neutral[5]
  };

  return (
    <li className={cx(styles.tab_list_item)}>
      <div
        className={cx(
          styles.tab_list_item_link,
          isActive && styles.tab_list_item_link_active
        )}
      >
        <RouterNavLink to={to}>
          <div className={cx(Color)}>{name}</div>
        </RouterNavLink>
      </div>
    </li>
  );
};

TabItem.propTypes = {
  name: _pt.string.isRequired,
  to: _pt.string.isRequired,
  location: _pt.object.isRequired
};

export default withRouter(TabItem);
