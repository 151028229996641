import React from 'react';
import withIcon from '../withIcon';

const IconCheck = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path d="M8.795 15.875l-3.47-3.47a.996.996 0 1 0-1.41 1.41l4.18 4.18c.39.39 1.02.39 1.41 0l10.58-10.58a.996.996 0 1 0-1.41-1.41z" />
    </svg>
  );
};

export default withIcon('IconCheck')(IconCheck);
