import React from "react";
import _pt from "prop-types";
import withIcon from "../withIcon";
import useTheme from "../../../core/src/hooks/useTheme";

const IconObviousShortLogo = ({ dark, inverted, ...props }) => {
  const theme = useTheme();

  return (
    <svg {...props} viewBox="0 0 26 19">
      <path
        style={{ color: dark ? "#000000" : "#ffffff" }}
        d="M23 19c-1.67 0-3-1.268-3-3.03 0-1.795 1.33-2.97 3-2.97s3 1.175 3 2.97c0 1.762-1.33 3.03-3 3.03z"
        transform="translate(-39 -39) translate(39 39)"
      />
      <path
        style={{ color: inverted ? "#ffffff" : theme.color.core.primary[3] }}
        d="M9.846 18.807c-5.608 0-9.708-3.763-9.708-9.065S4.238.676 9.846.676c5.608 0 9.674 3.764 9.674 9.066 0 5.302-4.066 9.065-9.674 9.065zm0-4.189c2.558 0 4.492-1.833 4.492-4.876 0-3.044-1.934-4.877-4.492-4.877S5.32 6.698 5.32 9.742c0 3.043 1.968 4.876 4.526 4.876z"
        transform="translate(-39 -39) translate(39 39)"
      />
    </svg>
  );
};

IconObviousShortLogo.propTypes = {
  dark: _pt.bool,
  inverted: _pt.bool
};

IconObviousShortLogo.defaultProps = {
  dark: false,
  inverted: false
};

export default withIcon("IconObviousShortLogo")(IconObviousShortLogo);
