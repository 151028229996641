import gql from "graphql-tag";

const CREATE_MANUFACTURE_ACCOUNT = gql`
  mutation createManufacturer(
    $company: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $color: String
    $logo: String
  ) {
    createManufactuer(
      data: {
        company: $company
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
        color: $color
        logo: $logo
        manufacturertype: "portal"
      }
    ) {
      success
    }
  }
`;

export { CREATE_MANUFACTURE_ACCOUNT };
