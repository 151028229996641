import gql from "graphql-tag";

const GET_PRODUCT = gql`
  query($id: ID!) {
    product(id: $id) {
      id
      name
      description
      featureregionsize
      featureregionaddress
      productkey {
        edges {
          node {
            id
            encryptionkey
          }
        }
      }
    }
  }
`;

const GET_CATALOG_ENTRIES = gql`
  query allProductEntries($productid: ID!) {
    allProductEntries(productid: $productid) {
      edges {
        node {
          id
          name
          sku
          description
          imageurl
          itemprice
          thumbnailurl
          datecreated
          featureid
          entrytype {
            name
          }
          packageentry {
            edges {
              node {
                id
                featureid
              }
            }
          }
        }
      }
    }
  }
`;

const GET_CATALOG_ENTRY = gql`
  query catalogEntry($id: ID!) {
    catalogEntry(id: $id) {
      id
      name
      sku
      description
      imageurl
      itemprice
      thumbnailurl
      datecreated
      featureid
      entrytype {
        name
      }
      packageentry {
        edges {
          node {
            id
            featureid
          }
        }
      }
    }
  }
`;

const DESTROY_ENTRY = gql`
  mutation destroyEntry($id: ID!) {
    destroyEntry(data: { id: $id }) {
      entry {
        id
      }
    }
  }
`;

const GET_FEATURES = gql`
  query allProductFeatures($productid: ID!) {
    allProductFeatures(productid: $productid) {
      edges {
        node {
          id
          name
          description
          deactivated
          defaultfeature
          rowid
        }
      }
    }
    allReservedFeatures {
      edges {
        node {
          rowid
          id
          name
          productreserved(productid: $productid) {
            edges {
              node {
                id
                deactivated
              }
            }
          }
        }
      }
    }
  }
`;

const CREATE_ENTRY_FEATURE = gql`
  mutation createPackageEntry($catalogpackageid: ID!, $featureid: ID!) {
    createPackageEntry(
      data: { catalogpackageid: $catalogpackageid, featureid: $featureid }
    ) {
      catalogpackageentry {
        featureid
        id
      }
    }
  }
`;

const DESTROY_PACKAGE_ENTRY = gql`
  mutation destroyPackageEntry($id: ID!) {
    destroyPackageEntry(data: { id: $id }) {
      entry {
        featureid
        id
      }
    }
  }
`;

const CREATE_ENTRY = gql`
  mutation createEntry(
    $sku: String!
    $name: String!
    $description: String
    $imageurl: String
    $thumbnailurl: String
    $featureid: ID
    $itemprice: Float!
    $features: String
  ) {
    createEntry(
      data: {
        sku: $sku
        name: $name
        description: $description
        imageurl: $imageurl
        thumbnailurl: $thumbnailurl
        featureid: $featureid
        itemprice: $itemprice
        features: $features
      }
    ) {
      entry {
        id
        name
        sku
        description
        imageurl
        itemprice
        thumbnailurl
        datecreated
        featureid
        entrytype {
          name
        }
      }
    }
  }
`;

const UPDATE_ENTRY = gql`
  mutation updateEntry(
    $id: ID
    $name: String!
    $description: String
    $imageurl: String!
    $thumbnailurl: String
    $itemprice: Float!
    $featureid: ID
    $features: String
  ) {
    updateEntry(
      data: {
        id: $id
        featureid: $featureid
        name: $name
        description: $description
        imageurl: $imageurl
        thumbnailurl: $thumbnailurl
        itemprice: $itemprice
        features: $features
      }
    ) {
      entry {
        id
        name
        sku
        description
        imageurl
        itemprice
        thumbnailurl
        datecreated
        featureid
      }
    }
  }
`;

export {
  GET_CATALOG_ENTRIES,
  GET_CATALOG_ENTRY,
  DESTROY_ENTRY,
  GET_FEATURES,
  GET_PRODUCT,
  CREATE_ENTRY_FEATURE,
  CREATE_ENTRY,
  DESTROY_PACKAGE_ENTRY,
  UPDATE_ENTRY
};
