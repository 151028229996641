import gql from "graphql-tag";

const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($email: String!) {
    resetUserPassword(email: $email) {
      success
      errors
    }
  }
`;

export { RESET_USER_PASSWORD };
