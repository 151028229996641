import React from "react";
import _pt from "prop-types";
import useStyles from "../../core/src/hooks/useStyles";
import PageHeader from "../../components/PageHeader";
import Spacing from "../../core/src/components/Spacing";
import Grid, { Col } from "../../core/src/components/Grid";

const styleSheet = ({ color, unit }) => ({});

const Tool = ({ header, children, sidebar, end, logo, tabs }) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <div>
      <Spacing bottom={8}>
        <Grid>
          <Col span={sidebar ? 8 : 12}>{children}</Col>
          {sidebar && (
            <Col span={3} offset={1}>
              {sidebar}
            </Col>
          )}
        </Grid>
      </Spacing>
    </div>
  );
};

Tool.propTypes = {};

Tool.defaultProps = {};

export default Tool;
