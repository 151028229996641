import React from "react";
import { withRouter } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import LogoLoader from "../../../core/src/components/LogoLoader";

const Overview = ({ match, location }) => {
  return false ? (
    <LogoLoader />
  ) : (
    <div>
      <PageHeader
        title="Overview"
        subtitle="Set up product features to be avaliable in your store"
      />
      <div></div>
    </div>
  );
};

export default withRouter(Overview);
