import React from 'react';
import withIcon from '../withIcon';

const IconAddAlt = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1z" />
    </svg>
  );
};

export default withIcon('IconAddAlt')(IconAddAlt);
