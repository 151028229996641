import React from "react";
import _pt from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { GET_PRODUCTS } from "./queries";
import useStyles from "../../core/src/hooks/useStyles";
import Layout from "../../layout/src/components/Layout";
import Content from "../../components/Content";
import PageHeader from "../../components/PageHeader/index";
import Modal from "../../core/src/components/Modal";
import Form from "./Form";
import Button from "../../core/src/components/Button";
import Product from "./Item";
import MainMenu from "../../components/MainMenu";
import LogoLoader from "../../core/src/components/LogoLoader";
import { getRolePermissions } from "../../helpers";
import TopMenu from "../../components/TopMenu";

const styleSheet = ({ unit, breakpoints }) => ({
  flex_wrapper: {
    paddingLeft: unit * 2,
    paddingRight: unit * 2
  },
  flex_container: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: unit * -2,
    marginLeft: unit * -2
  },
  flex_item: {
    width: "33%",
    paddingLeft: unit * 2,
    paddingRight: unit * 2,
    marginBottom: unit * 4
  }
});

const Products = ({}) => {
  const permissions = getRolePermissions("products");
  const [styles, cx] = useStyles(styleSheet);
  const [visable, setVisable] = React.useState(false);
  const { loading, refetch, data: productsData } = useQuery(GET_PRODUCTS, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Layout
        relative
        noPadding
        noMaxwidth
        before={
          <div className={cx(styles.flex)}>
            <MainMenu />
          </div>
        }
      >
        {loading ? (
          <LogoLoader />
        ) : (
          <>
            <TopMenu />
            <Content>
              <PageHeader
                title="Products"
                end={
                  permissions.includes("CREATE") && (
                    <Button onClick={() => setVisable(true)}>Add</Button>
                  )
                }
              />
              <div className={cx(styles.flex_wrapper)}>
                <div className={cx(styles.flex_container)}>
                  {productsData &&
                    productsData.allProducts.edges.map(({ node: product }) => (
                      <div key={product.id} className={cx(styles.flex_item)}>
                        <Product product={product} />
                      </div>
                    ))}
                </div>
              </div>
            </Content>
          </>
        )}
      </Layout>
      {visable && (
        <Modal title={"Add Product"} onClose={() => setVisable(false)}>
          <Form onClose={() => setVisable(false)} onRefetch={refetch} />
        </Modal>
      )}
    </>
  );
};

Products.propTypes = {};

Products.defaultProps = {};

export default Products;
