import React from "react";
import useStyles from "../../../../core/src/hooks/useStyles";
import Transaction from "./Transaction";
import Text from "../../../../core/src/components/Text/index";
import Row from "../../../../core/src/components/Row";
import Spacing from "../../../../core/src/components/Spacing";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  date: {
    width: 184
  },
  purchase: {
    width: 128
  },
  price: {
    width: 128
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 128
  },
  status_circle: {
    width: unit,
    height: unit,
    borderRadius: "100%",
    marginRight: unit
  },
  options: {
    width: 24
  }
});

const TransactionList = ({ children }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className="">
      <div className={cx(styles.header)}>
        <Row>
          <div className={cx(styles.row)}>
            <div className={cx({ flexGrow: 1 })}>
              Serial number + Transaction number
            </div>
            <div className={cx(styles.options)} />
          </div>
        </Row>
      </div>
      {children}
    </div>
  );
};

export { Transaction };
export default TransactionList;
