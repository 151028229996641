import parseLocale from './parseLocale';

export default function getLocaleFromClient() {
  let local;
  (navigator.languages || [navigator.language]).some(lang => {
    local = parseLocale(lang);

    return !!local;
  });

  return local;
}
