import React from "react";
import _pt from "prop-types";
import Text from "../../../../../../core/src/components/Text";
import Spacing from "../../../../../../core/src/components/Spacing";
import Link from "../../../../../../core/src/components/Link";
import Grid, { Col } from "../../../../../../core/src/components/Grid";
import List, { Item } from "../../../../../../core/src/components/List";

const CapabilityDisabled = ({}) => {
  return (
    <Grid>
      <Col span={8}>
        <Text bold large>
          Description
        </Text>
        <Spacing top={2}>
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            consectetur in lorem eget ullamcorper. Donec lacinia elit ex.
            Vivamus suscipit urna ac interdum semper. Donec in condimentum elit,
            congue congue mi. Vestibulum bibendum odio vitae justo pellentesque,
            sed pharetra ante bibendum. Vestibulum volutpat sodales nisi, quis
            convallis lorem porttitor et. Praesent dapibus, dui ac ultrices
            egestas, mauris risus ultricies mi, vitae fringilla felis nisl
            faucibus nulla. Quisque accumsan, leo non semper luctus, odio sapien
            pharetra elit, eget dapibus est sapien vel ligula.
          </Text>
        </Spacing>
        <Spacing top={4}>
          <Text bold large>
            Getting Started
          </Text>
          <Spacing top={2}>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              consectetur in lorem eget ullamcorper. Donec lacinia elit ex.
              Vivamus suscipit urna ac interdum semper. Donec in condimentum
              elit, congue congue mi. Vestibulum bibendum odio vitae justo
              pellentesque, sed pharetra ante bibendum. Vestibulum volutpat
              sodales nisi, quis convallis lorem porttitor et. Praesent dapibus,
              dui ac ultrices egestas, mauris risus ultricies mi, vitae
              fringilla felis nisl faucibus nulla. Quisque accumsan, leo non
              semper luctus, odio sapien pharetra elit, eget dapibus est sapien
              vel ligula.
            </Text>
          </Spacing>
        </Spacing>
        <Spacing top={4}>
          <Text bold large>
            API
          </Text>
          <Spacing top={2}>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              consectetur in lorem eget ullamcorper. Donec lacinia elit ex.
              Vivamus suscipit urna ac interdum semper. Donec in condimentum
              elit, congue congue mi. Vestibulum bibendum odio vitae justo
              pellentesque, sed pharetra ante bibendum. Vestibulum volutpat
              sodales nisi, quis convallis lorem porttitor et. Praesent dapibus,
              dui ac ultrices egestas, mauris risus ultricies mi, vitae
              fringilla felis nisl faucibus nulla. Quisque accumsan, leo non
              semper luctus, odio sapien pharetra elit, eget dapibus est sapien
              vel ligula.
            </Text>
          </Spacing>
        </Spacing>
      </Col>
      <Col span={3} offset={1}>
        <Spacing>
          <Text bold>Learn More</Text>
          <Spacing vertical={4}>
            <List>
              <Item>
                <Spacing bottom={2}>
                  <Link>Product Docs</Link>
                  <Spacing top={1}>
                    <Text>
                      Mauris consectetur in lorem eget ullamcorper. Donec
                      lacinia elit ex.
                    </Text>
                  </Spacing>
                </Spacing>
              </Item>
              <Item>
                <Spacing bottom={2}>
                  <Link>Tutorials</Link>
                  <Spacing top={1}>
                    <Text>
                      Mauris consectetur in lorem eget ullamcorper. Donec
                      lacinia elit ex.
                    </Text>
                  </Spacing>
                </Spacing>
              </Item>
              <Item>
                <Spacing bottom={2}>
                  <Link>API Docs</Link>
                  <Spacing top={1}>
                    <Text>
                      Mauris consectetur in lorem eget ullamcorper. Donec
                      lacinia elit ex.
                    </Text>
                  </Spacing>
                </Spacing>
              </Item>
            </List>
          </Spacing>
        </Spacing>
      </Col>
    </Grid>
  );
};

CapabilityDisabled.propTypes = {};

CapabilityDisabled.defaultProps = {};

export default CapabilityDisabled;
