//FIX THIS - remove defineProperty

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

class ScrollCheck {
  constructor(node) {
    _defineProperty(this, 'computedStyle', void 0);

    _defineProperty(this, 'node', void 0);

    this.node = node;
    this.computedStyle = window.getComputedStyle(node);
  }

  hasOverflow(dimension) {
    const overflow = this.computedStyle['overflow' + dimension.toUpperCase()];

    return overflow === 'scroll' || overflow === 'auto';
  }

  isScrollableX() {
    return this.hasOverflow('x') && this.node.scrollWidth > this.node.clientWidth;
  }

  isScrollableY() {
    return this.hasOverflow('y') && this.node.scrollHeight > this.node.clientHeight;
  }

  isScrollable() {
    return this.isScrollableX() || this.isScrollableY();
  }
}

export default function isScrollable(node) {
  return new ScrollCheck(node).isScrollable();
}
export function scrollingParents(node) {
  const output = [];
  let el = node;

  while (el && el !== document.documentElement) {
    if (isScrollable(el)) {
      output.push(el);
    }

    el = el.parentNode;
  }

  if (node.ownerDocument && node.ownerDocument.defaultView) {
    output.push(node.ownerDocument.defaultView);
  }

  return output;
}
