export const roles = {
  observer: {
    products: ["READ"],
    product: {
      features: ["READ"],
      devices: ["READ"],
      firmware: ["READ"],
      groups: ["READ"],
      keys: ["READ"],
      capabilities: ["READ"]
    },
    sales: {
      overview: ["READ"],
      catalog: ["READ"],
      transactions: ["READ"]
    },
    team: {
      members: ["READ"],
      invites: ["READ"]
    },
    tools: ["READ"],
    docs: ["READ"]
  },
  developer: {
    products: ["CREATE", "READ", "UPDATE", "DELETE"],
    product: {
      features: ["CREATE", "READ", "UPDATE", "DELETE"],
      devices: ["CREATE", "READ", "UPDATE", "DELETE"],
      firmware: ["CREATE", "READ", "UPDATE", "DELETE"],
      groups: ["CREATE", "READ", "UPDATE", "DELETE"],
      keys: ["READ"],
      capabilities: ["READ"]
    },
    sales: {
      overview: ["READ"],
      catalog: ["CREATE", "READ", "UPDATE", "DELETE"],
      transactions: ["READ"]
    },
    team: {
      members: ["READ"],
      invites: ["READ"]
    },
    tools: ["READ"],
    docs: ["READ"]
  },
  developeradmin: {
    products: ["CREATE", "READ", "UPDATE", "DELETE"],
    product: {
      features: ["CREATE", "READ", "UPDATE", "DELETE"],
      devices: ["CREATE", "READ", "UPDATE", "DELETE"],
      firmware: ["CREATE", "READ", "UPDATE", "DELETE"],
      groups: ["CREATE", "READ", "UPDATE", "DELETE"],
      keys: ["READ"],
      capabilities: ["READ"]
    },
    sales: {
      overview: ["READ"],
      catalog: ["CREATE", "READ", "UPDATE", "DELETE"],
      transactions: ["READ"]
    },
    team: {
      members: ["CREATE", "READ", "UPDATE", "DELETE"],
      invites: ["CREATE", "READ", "UPDATE", "DELETE"]
    },
    tools: ["READ"],
    docs: ["READ"]
  },
  customerservice: {
    catalog: ["CREATE", "READ", "UPDATE", "DELETE"]
  },
  productmanager: {
    products: ["CREATE", "READ", "UPDATE", "DELETE"],
    product: {
      features: ["CREATE", "READ", "UPDATE", "DELETE"],
      devices: ["CREATE", "READ", "UPDATE", "DELETE"],
      firmware: ["CREATE", "READ", "UPDATE", "DELETE"],
      groups: ["CREATE", "READ", "UPDATE", "DELETE"],
      keys: ["READ"],
      capabilities: ["READ"]
    },
    sales: {
      overview: ["READ"],
      catalog: ["CREATE", "READ", "UPDATE", "DELETE"],
      transactions: ["READ"]
    },
    team: {
      members: ["CREATE", "READ", "UPDATE", "DELETE"],
      invites: ["CREATE", "READ", "UPDATE", "DELETE"]
    },
    tools: ["READ"],
    docs: ["READ"]
  },
  admin: {
    products: ["CREATE", "READ", "UPDATE", "DELETE"],
    product: {
      features: ["CREATE", "READ", "UPDATE", "DELETE"],
      devices: ["CREATE", "READ", "UPDATE", "DELETE"],
      firmware: ["CREATE", "READ", "UPDATE", "DELETE"],
      groups: ["CREATE", "READ", "UPDATE", "DELETE"],
      keys: ["READ"],
      capabilities: ["READ"]
    },
    sales: {
      overview: ["READ"],
      catalog: ["CREATE", "READ", "UPDATE", "DELETE"],
      transactions: ["READ"]
    },
    team: {
      members: ["CREATE", "READ", "UPDATE", "DELETE"],
      invites: ["CREATE", "READ", "UPDATE", "DELETE"]
    },
    tools: ["READ"],
    docs: ["READ"]
  }
};

export const softDevices = {
  s112: ["5.1.0", "6.0.0", "6.10", "6.1.1"],
  s132: ["4.0.x", "5.0.0", "5.1.0", "6.0.0", "6.10", "6.1.1"],
  s332: ["4.0.x", "5.0.0", "6.1.1"]
};

export const chips = [
  "nRF5R832_XXAA",
  "nRF52832_XXAB",
  "nRF52810_XXAA",
  "nRF52840__XXAA"
];
