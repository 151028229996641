import React from "react";
import _pt from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import CapabilityHeader from "../Header";
import Tabs, { Item } from "../../../../../../components/Tabs";
import Liceneses from "./Licenses";
import Tools from "./Tools";

const CapabilityActive = ({ match }) => {
  const [productID, setProductID] = React.useState(match.params.product);
  const [capabilityID, setCapabilityID] = React.useState(
    match.params.capability
  );

  return (
    <div>
      <CapabilityHeader
        tabs={
          <Tabs>
            <Item
              name="Licenses"
              to={`/products/${productID}/capabilities/${capabilityID}/licenses`}
            />
            <Item
              name="Tools"
              to={`/products/${productID}/capabilities/${capabilityID}/tools`}
            />
          </Tabs>
        }
      />
      <Switch>
        <Redirect
          exact
          from={`/products/:product/capabilities/:capability`}
          to={`/products/:product/capabilities/:capability/licenses`}
        />
        <Route
          exact
          path={`/products/:product/capabilities/:capability/licenses`}
          render={props => <Liceneses {...props} />}
        />
        <Route
          exact
          path={`/products/:product/capabilities/:capability/tools`}
          render={props => <Tools {...props} />}
        />
      </Switch>
    </div>
  );
};

CapabilityActive.propTypes = {};

CapabilityActive.defaultProps = {};

export default withRouter(CapabilityActive);
