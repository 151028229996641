import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { GET_TEAM } from "../queries";
import useStyles from "../../../core/src/hooks/useStyles";
import PageHeader from "../../../components/PageHeader";
import List, { Member } from "./List";
import Text from "../../../core/src/components/Text";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Button from "../../../core/src/components/Button";
import Modal from "../../../core/src/components/Modal";
import InviteForm from "../Invites/InviteForm";
import StatusLabel from "../../../core/src/components/StatusLabel";
import StatusText from "../../../core/src/components/StatusText";
import { getRolePermissions } from "../../../helpers";
import Spacing from "../../../core/src/components/Spacing";
import DateTime from "../../../core/src/components/DateTime";

const styleSheet = ({ color, unit }) => ({});

const Members = ({ match, location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const permissions = getRolePermissions("team");
  const [visable, setVisable] = React.useState(false);
  const { loading, data } = useQuery(GET_TEAM, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return loading ? (
    <LogoLoader />
  ) : (
    <div>
      <Spacing bottom={8}>
        <List>
          {data.manufacturer.members.edges.map(({ node: member }) => {
            if (member.pendingemail) {
              return (
                <Member key={member.id} member={member}>
                  <div
                    className={cx({ display: "flex", alignItems: "center" })}
                  >
                    <div className={cx({ flexGrow: 1 })}>
                      <Text bold>{member.pendingemail}</Text>
                      <Text small>
                        {"Invite sent on "}
                        <DateTime
                          at={new Date(member.datecreated).getTime()}
                          medium
                        />
                      </Text>
                    </div>
                  </div>
                </Member>
              );
            }
            return (
              <Member key={member.id} member={member}>
                <div className={cx({ display: "flex", alignItems: "center" })}>
                  <div className={cx({ flexGrow: 1 })}>
                    <Text bold>
                      {member.account.firstName} {member.account.lastName}
                    </Text>
                    <Text small>{member.account.email}</Text>
                  </div>
                </div>
              </Member>
            );
          })}
        </List>
      </Spacing>
      {permissions.members.includes("CREATE") && visable && (
        <Modal title={"Invite Member"} onClose={() => setVisable(false)}>
          <InviteForm onClose={() => setVisable(false)} />
        </Modal>
      )}
    </div>
  );
};

export default withRouter(Members);
