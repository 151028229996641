import React from "react";
import _pt from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import useStyles from "../../../../core/src/hooks/useStyles";
import { GET_PRODUCTS } from "./queries";
import PageHeader from "../../../../components/PageHeader";
import Product from "./Item";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../core/src/components/Breadcrumbs";

const styleSheet = ({ unit, breakpoints }) => ({
  flex_wrapper: {
    paddingLeft: unit * 2,
    paddingRight: unit * 2
  },
  flex_container: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: unit * -2,
    marginLeft: unit * -2
  },
  flex_item: {
    width: "33%",
    paddingLeft: unit * 2,
    paddingRight: unit * 2,
    marginBottom: unit * 4
  }
});

const Products = ({}) => {
  const [styles, cx] = useStyles(styleSheet);
  const { loading, data } = useQuery(GET_PRODUCTS, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return loading ? (
    <LogoLoader />
  ) : (
    <div>
      <div className={cx(styles.flex_wrapper)}>
        <div className={cx(styles.flex_container)}>
          {data &&
            data.allProducts.edges.map(({ node: product }) => (
              <div className={cx(styles.flex_item)}>
                <Product product={product} key={product.id} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

Products.propTypes = {};

Products.defaultProps = {};

export default Products;
