import gql from "graphql-tag";

const GET_LICENSES = gql`
  query allLiceneses {
    allLiceneses {
      edges {
        node {
          id
          datecreated
          dateupdated
          signature
          licensenumber
          deviceid {
            serialnumber
          }
        }
      }
    }
  }
`;

export { GET_LICENSES };
