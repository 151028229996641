import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_PRODUCT, UPDATE_PRODUCT, GET_PRODUCTS } from "./queries";
import FormActions from "../../core/src/components/FormActions";
import TextArea from "../../core/src/components/TextArea";
import Input from "../../core/src/components/Input";

const ProductForm = ({ selectedProduct, onClose, onRefetch, ...props }) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState({
    name: "",
    description: "",
    featureregionsize: 4096
  });
  const [error, setError] = React.useState({
    name: { status: false },
    featureregionsize: { status: false }
  });
  const [type, setType] = React.useState("create");

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    update(
      cache,
      {
        data: {
          createProduct: { product }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_PRODUCTS
      });
      data.allProducts.edges.push({
        node: { ...product },
        __typename: "ProductNodeEdge"
      });
      cache.writeQuery({
        query: GET_PRODUCTS,
        data
      });
    }
  });

  const [updateProduct] = useMutation(UPDATE_PRODUCT);

  React.useEffect(() => {
    if (selectedProduct) {
      setProduct({
        ...selectedProduct
      });
      setType("edit");
    }
  }, []);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    setProduct({
      ...product,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!product.name) {
      errors = {
        ...errors,
        name: { status: true }
      };
      submitError = true;
    }

    if (!product.featureregionsize) {
      errors = {
        ...errors,
        featureregionsize: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    if (type === "create") {
      createProduct({
        variables: {
          ...product
        }
      })
        .then(res => {
          onRefetch();
          onClose();
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
    if (type === "edit") {
      updateProduct({
        variables: {
          ...product
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateProduct: {
            product: {
              id: product.id,
              ...product,
              __typename: "ProductNode"
            },
            __typename: "updateProduct"
          }
        }
      }).catch(error => {
        console.log(error);
        setLoading(false);
      });
      onClose();
    }
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Input
        name="name"
        label="Product name"
        placeholder="Ocelot"
        value={product.name}
        onChange={updateField}
        errorMessage="Product needs a name"
        invalid={error.name.status}
      />
      <TextArea
        name="description"
        label="Description"
        placeholder="Add a product description"
        value={product.description}
        onChange={updateField}
        optional
      />
      <FormActions
        continueText={`${type === "create" ? "Create" : "Update"} product`}
        processingText={`${type === "create" ? "Creating..." : "Updating..."}`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

ProductForm.propTypes = {};

ProductForm.defaultProps = {};

export default ProductForm;
