import focusElement from './focusElement';
import getFirstFocusableChild from './getFirstFocusableChild';

const focusFirstFocusableChild = node => {
  if (!node.contains(document.activeElement)) {
    const firstFocusableElement = getFirstFocusableChild(node);

    focusElement(firstFocusableElement);
  }
};

export default focusFirstFocusableChild;
