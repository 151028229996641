import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import Tabs, { Item } from "../../../components/Tabs";
import Integration from "./Integration";
import Api from "./Api";

const Embedded = ({ match }) => {
  return (
    <div>
      <PageHeader
        id="contentMenu"
        title="Embedded"
        tabs={
          <Tabs>
            <Item name="API" to={`/docs/embedded/api`} />
            <Item name="Integration" to={`/docs/embedded/integration`} />
          </Tabs>
        }
      />
      <Switch>
        <Redirect exact from="/docs/embedded" to="/docs/embedded/api" />
        <Route exact path="/docs/embedded/api" component={Api} />
        <Route
          exact
          path="/docs/embedded/integration"
          component={Integration}
        />
      </Switch>
    </div>
  );
};

export default Embedded;
