import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_ENTRY_FEATURE, GET_CATALOG_ENTRY } from "../queries";
import FormActions from "../../../../../../../core/src/components/FormActions";
import Select from "../../../../../../../core/src/components/Select";

const Form = ({
  reservedFeatures,
  allFeatures,
  entryFeatures,
  entry,
  onClose,
  refetch,
  ...props
}) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [featureList, setFeatureList] = React.useState([]);
  const [feature, setFeature] = React.useState({
    featureid: ""
  });

  const [error, setError] = React.useState({
    featureid: { status: false }
  });

  const [createPackageEntry] = useMutation(CREATE_ENTRY_FEATURE, {
    update(
      cache,
      {
        data: { createPackageEntry }
      }
    ) {
      const data = cache.readQuery({
        query: GET_CATALOG_ENTRY,
        variables: { id: entry }
      });

      data.catalogEntry.packageentry.edges.push({
        node: { ...createPackageEntry.catalogpackageentry },
        __typename: "ProductNodeEdge"
      });

      cache.writeQuery({
        query: GET_CATALOG_ENTRY,
        variables: { id: entry },
        data
      });
    }
  });

  React.useEffect(() => {
    const combined = [
      ...allFeatures.edges.map(edge => {
        return edge.node;
      }),
      ...reservedFeatures.edges.map(edge => {
        return edge.node;
      })
    ]
      .map(node => {
        return { value: node.id, text: node.name };
      })
      .filter(node => {
        if (!entryFeatures.includes(node.text)) {
          return node;
        }
      });
    setFeatureList(combined);
  }, []);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    setFeature({
      ...feature,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!feature.featureid) {
      errors = {
        ...errors,
        featureid: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    createPackageEntry({
      variables: {
        featureid: feature.featureid,
        catalogpackageid: entry
      },
      optimisticResponse: {
        __typename: "Mutation",
        createPackageEntry: {
          catalogpackageeentry: {
            id: -1,
            ...feature,
            __typename: "CatalogPackageEntryNode"
          },
          __typename: "createPackageEntry"
        }
      }
    })
      .then(res => {
        console.log(res);
        onClose();
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Select
        name="featureid"
        label="Select a feature"
        value={feature.featureid}
        onChange={updateField}
        placeholder="Select a feature"
        errorMessage="A feature is needed"
        invalid={error.featureid.status}
      >
        {featureList.map(feature => {
          return <option value={feature.value}>{feature.text}</option>;
        })}
      </Select>
      <FormActions
        continueText={`Add feature`}
        processingText={`Adding...`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
