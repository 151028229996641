import React from "react";
import _pt from "prop-types";
import { matchPath, withRouter } from "react-router-dom";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import RouterNavLink from "../../core/src/components/RouterNavLink";
import Spacing from "../../core/src/components/Spacing";
import { Item } from "../../core/src/components/List";
import Text from "../../core/src/components/Text";

const styleSheet = ({ color }) => ({
  item: {
    backgroundColor: "transpartent",
    width: "100%",
    ":hover": {
      backgroundColor: color.core.neutral[8]
    }
  },
  item_active: {},
  flex: {
    display: "flex",
    flexDirection: "column"
  },
  justify_center: {
    justifyContent: "center"
  },
  items_center: {
    alignItems: "center"
  }
});

const MenuItem = ({ name, to, icon, location, width, onClick, children }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const isActive = matchPath(location.pathname, { path: to });
  const Color = "#ffffff";

  const handleClick = event => {
    if (!onClick) {
      event.preventDefault();
    } else {
      onClick(event);
    }
  };

  return (
    <Item>
      <div
        onClick={handleClick}
        className={cx(
          styles.item,
          isActive && styles.item_active,
          width && {
            width: width
          },
          isActive && { backgroundColor: theme.color.core.neutral[8] }
        )}
      >
        <RouterNavLink to={to}>
          <div
            className={cx({
              width: "100%"
            })}
          >
            <Spacing vertical={2}>
              <div className={cx(styles.flex, styles.items_center)}>
                <Spacing bottom={1}>{icon}</Spacing>
                <Text atom bold inverted uppercased>
                  <div className={cx({ color: Color })}>{name}</div>
                </Text>
              </div>
            </Spacing>
          </div>
        </RouterNavLink>
        {children}
      </div>
    </Item>
  );
};

MenuItem.propTypes = {
  name: _pt.string.isRequired,
  to: _pt.string.isRequired,
  location: _pt.object.isRequired
};

MenuItem.defaultProps = {
  icon: null
};

export default withRouter(MenuItem);
