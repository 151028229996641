import gql from "graphql-tag";

const GET_MANUFACTURER_CONFIGS = gql`
  query allManufacturerConfigs {
    allManufacturerConfigs {
      edges {
        node {
          id
          apikey
        }
      }
    }
  }
`;

const UPDATE_MANUFACTURER_CONFIGS = gql`
  mutation updateManufacturerIntegrationConfig($id: ID!, $value: String!) {
    updateManufacturerIntegrationConfig(data: { id: $id, value: $value }) {
      config {
        id
        apikey
      }
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $current_password: String!
    $new_password: String!
    $confirm_password: String!
  ) {
    updatePassword(
      data: {
        currentPassword: $current_password
        newPassword: $new_password
        confirmPassword: $confirm_password
      }
    ) {
      success
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateAccount($firstName: String!, $email: String!) {
    updateAccount(data: { firstName: $firstName, email: $email }) {
      success
    }
  }
`;

const UPDATE_LOCAL_PROFILE = gql`
  mutation updateUser($firstName: String!, $email: String!) {
    updateUser(firstName: $firstName, email: $email) @client {
      user {
        firstName
        email
      }
    }
  }
`;

const GET_LOGIN_USER = gql`
  query me {
    me {
      id
      firstName
      email
    }
  }
`;

const GET_USER = gql`
  query user {
    user @client {
      firstName
      email
    }
  }
`;

export {
  GET_MANUFACTURER_CONFIGS,
  UPDATE_MANUFACTURER_CONFIGS,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_LOCAL_PROFILE,
  GET_LOGIN_USER,
  GET_USER
};
