import React from "react";
import _pt from "prop-types";
import { slugify } from "../../../helpers";

const Heading = ({ level, children, ...props }) => {
  const Tag = `h${level}`;
  const anchor = children.map(({ props: { value, type } }) => {
    if (type !== "a") {
      if (value !== undefined) {
        return slugify(value);
      }
    }
    return null;
  });
  return (
    <Tag>
      {anchor && <a id={anchor}></a>}
      {children.map(({ props: { children, value } }) => {
        return value;
      })}
    </Tag>
  );
};

Heading.defaultProps = {
  language: null
};

Heading.propTypes = {
  value: _pt.string,
  language: _pt.string
};

export default Heading;
