import React from "react";
import useStyles from "../../core/src/hooks/useStyles";
import Item from "./Item";

const styleSheet = ({ unit, font, color }) => ({
  tab_list: {
    display: "flex",
    marginTop: unit * 2
  },
  tabs_center: {
    display: "flex",
    justifyContent: "center"
  }
});

export const Tabs = ({ children, center }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <nav className={cx(center && styles.tabs_center)}>
      <ul className={cx(styles.tab_list)}>{children}</ul>
    </nav>
  );
};

export { Item };
export default Tabs;
