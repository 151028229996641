import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import queryString from "query-string";
import { useMutation } from "@apollo/react-hooks";
import { VERIFY_REGEISTER_USER_EMAIL, CREATE_ACCOUNT } from "./queries";
import useStyles from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import IconObviousLogo from "../../../icons/src/interface/IconObviousLogo";
import Title from "../../../core/src/components/Title";
import Input from "../../../core/src/components/Input";
import Spacing from "../../../core/src/components/Spacing";
import Button from "../../../core/src/components/Button";
import Link from "../../../core/src/components/Link";
import IconCaretRight from "../../../icons/src/interface/IconCaretRight";
import { validateEmail, getAllUrlParams } from "../../../helpers";
import Alert from "../../../core/src/components/Alert";
import history from "../../../history";
import LogoLoader from "../../../core/src/components/LogoLoader";
import CheckBox from "../../../core/src/components/CheckBox/index";
import Text from "../../../core/src/components/Text";

const styleSheet = ({ color, unit }) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100vh"
  },
  header: {
    height: "135px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    width: "446px",
    margin: "auto",
    paddingBottom: "135px",
    maxWidth: "90vw",
    verticalAlign: "middle"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  error_danger: {
    backgroundColor: color.core.danger[2],
    color: color.core.neutral[4]
  },
  form: {
    opacity: 1,
    transition: "all .2s ease-in-out"
  }
});

const Join = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [queryEmail] = React.useState(getAllUrlParams(location.search).email);
  const [verifying, setVerifying] = React.useState(true);
  const [isVerified, setVerified] = React.useState(false);
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [agreement, setAgreement] = React.useState(false);
  const [user, setUser] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    password: ""
  });
  const [serverError, setServerError] = React.useState(false);

  const [error, setError] = React.useState({
    email: { status: false },
    firstName: { status: false },
    lastName: { status: false },
    password: { status: false },
    agreement: { status: false }
  });

  const [verify] = useMutation(VERIFY_REGEISTER_USER_EMAIL);
  const [create] = useMutation(CREATE_ACCOUNT);

  React.useLayoutEffect(() => {
    if (queryEmail) {
      verify({
        variables: {
          email: queryEmail
        }
      }).then(({ data: { verifyRegisterUserEmail: { success } } }) => {
        if (success) {
          setVerified(true);
        } else {
          setServerError(true);
        }
        setUser({
          ...user,
          email: queryEmail
        });
        setVerifying(false);
      });
    } else {
      setVerifying(false);
    }
  }, []);

  const updateField = (value, event) => {
    setUser({
      ...user,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};

    if (isVerified && !agreement) {
      errors = {
        ...errors,
        agreement: { status: true }
      };
      submitError = true;
    }

    if (!user.email) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (!validateEmail(user.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (isVerified && !user.firstName) {
      errors = {
        ...errors,
        firstName: { status: true }
      };
      submitError = true;
    }

    if (isVerified && !user.lastName) {
      errors = {
        ...errors,
        lastName: { status: true }
      };
      submitError = true;
    }

    if (
      (isVerified && !user.password) ||
      (isVerified && user.password.length < 8)
    ) {
      errors = {
        ...errors,
        password: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }
    setLoading(true);
    if (!isVerified) {
      console.log("verify");
      verify({
        variables: {
          email: user.email
        }
      }).then(({ data: { verifyRegisterUserEmail: { success } } }) => {
        if (success) {
          setVerified(true);
          setServerError(false);
        } else {
          setServerError(true);
        }
        setLoading(false);
        setVerifying(false);
      });
    } else {
      create({
        variables: {
          ...user
        }
      }).then(({ data: { registerUser: { success } } }) => {
        if (success) {
          history.push({ pathname: "/login", state: { accountCreated: true } });
        } else {
          setServerError(true);
        }
        setLoading(false);
      });
    }
  };

  return verifying ? (
    <LogoLoader />
  ) : (
    <div>
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.content)}>
          <div className={cx(styles.form)}>
            <div className={cx(styles.header)}>
              <IconObviousLogo width="200" height="48" decorative dark />
            </div>
            <Spacing vertical={theme.unit / 2}>
              <div className={cx(styles.title)}>
                <Title level={2}>Get started</Title>
                <Link
                  href="/login"
                  afterIcon={<IconCaretRight decorative size={24} />}
                >
                  Log in
                </Link>
              </div>
            </Spacing>
            {(error.email.status || serverError) && (
              <Spacing bottom={theme.unit / 2}>
                <Alert
                  title="Please use the email your invitation was sent to"
                  danger
                  full
                  hideStatusIcon
                />
              </Spacing>
            )}
            <form
              id={id}
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              {!isVerified ? (
                <div>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="email"
                      placeholder="Email"
                      label="Email"
                      hideLabel
                      large
                      type="email"
                      value={user.email}
                      onChange={updateField}
                      autoComplete="email"
                      noSpacing
                      invalid={error.email.status}
                    />
                  </Spacing>
                  <Spacing vertical={theme.unit / 2}>
                    <Button
                      large
                      block
                      onClick={handleSubmit}
                      loading={loading}
                      type="submit"
                    >
                      Continue
                    </Button>
                  </Spacing>
                </div>
              ) : (
                <div>
                  <Spacing bottom={theme.unit / 4}>
                    <Text bold large>
                      {user.email}
                    </Text>
                  </Spacing>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                      hideLabel
                      large
                      value={user.firstName}
                      onChange={updateField}
                      autoComplete="firstName"
                      noSpacing
                      invalid={error.firstName.status}
                      errorMessage="First name is required"
                    />
                  </Spacing>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      hideLabel
                      large
                      value={user.lastName}
                      onChange={updateField}
                      autoComplete="lastName"
                      noSpacing
                      invalid={error.lastName.status}
                      errorMessage="Last name is required"
                    />
                  </Spacing>
                  <Spacing bottom={theme.unit / 4}>
                    <Input
                      name="password"
                      placeholder="Password - 8+ characters"
                      label="Password"
                      hideLabel
                      large
                      value={user.password}
                      onChange={updateField}
                      autoComplete="password"
                      type="password"
                      noSpacing
                      invalid={error.password.status}
                      errorMessage="Password needs to be 8+ characters"
                    />
                  </Spacing>
                  <Spacing vertical={theme.unit / 2}>
                    <div
                      className={cx({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      })}
                    >
                      <div>
                        <CheckBox
                          name="agreement"
                          label="Agreement"
                          hideLabel
                          onChange={() => setAgreement(!agreement)}
                          checked={agreement}
                          invalid={error.agreement.status}
                        />
                      </div>
                      <Spacing left={1}>
                        <Text small>
                          By creating an account, you agree to the{" "}
                          <Link openInNewWindow href="/static/pdf/terms.pdf">
                            Terms and Conditions
                          </Link>{" "}
                          and{" "}
                          <Link openInNewWindow href="/static/pdf/privacy.pdf">
                            Privacy policy
                          </Link>
                          .
                        </Text>
                      </Spacing>
                    </div>
                  </Spacing>
                  <Spacing top={theme.unit / 2} bottom={2}>
                    <Button
                      large
                      block
                      onClick={handleSubmit}
                      loading={loading}
                      type="submit"
                    >
                      Sign up
                    </Button>
                  </Spacing>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Join.propTypes = {};

Join.defaultProps = {};

export default Join;
