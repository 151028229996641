import React from 'react';

const useForceUpdate = () => {
  const [, setTick] = React.useState(0);
  const update = () => {
    setTick(tick => tick + 1);
  };

  return update;
};

export default useForceUpdate;
