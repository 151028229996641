import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { GET_TEAM } from "../queries";
import PageHeader from "../../../components/PageHeader";
import List, { Member } from "./List";
import Text from "../../../core/src/components/Text";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Button from "../../../core/src/components/Button";
import Modal from "../../../core/src/components/Modal";
import InviteForm from "./InviteForm";
import { getRolePermissions } from "../../../helpers";
import Spacing from "../../../core/src/components/Spacing";

const Members = ({ match, location }) => {
  const permissions = getRolePermissions("team");
  const [visable, setVisable] = React.useState(false);
  const { loading, data } = useQuery(GET_TEAM, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return loading ? (
    <LogoLoader />
  ) : (
    <div>
      <PageHeader
        title="Invites"
        subtitle="A list of your invites"
        end={
          permissions.members.includes("CREATE") && (
            <Button onClick={() => setVisable(true)}>Invite</Button>
          )
        }
      />
      <Spacing bottom={8}>
        <List>
          {data.manufacturer.members.edges.map(({ node: member }) => {
            if (!member.pendingemail) {
              return;
            }
            return (
              <Member key={member.id} member={member}>
                <Text bold>{member.pendingemail}</Text>
              </Member>
            );
          })}
        </List>
      </Spacing>

      {permissions.members.includes("CREATE") && visable && (
        <Modal title={"Invite Member"} onClose={() => setVisable(false)}>
          <InviteForm onClose={() => setVisable(false)} />
        </Modal>
      )}
    </div>
  );
};

export default withRouter(Members);
