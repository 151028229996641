import React from "react";
import _pt from "prop-types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = ({ language, value }) => {
  return (
    <SyntaxHighlighter language={language} style={atomDark}>
      {value}
    </SyntaxHighlighter>
  );
};

CodeBlock.defaultProps = {
  language: null
};

CodeBlock.propTypes = {
  value: _pt.string.isRequired,
  language: _pt.string
};

export default CodeBlock;
