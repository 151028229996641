import React from "react";
import uuid from "uuid/v4";
import BaseTextArea from "../basic/BaseTextArea";
import FormField, { partitionFieldProps } from "../FormField";
import T from "../Translate";

const TextArea = ({ ...props }) => {
  const [id] = React.useState(uuid());
  const { fieldProps, inputProps } = partitionFieldProps(props);
  const { locale, proofread, ...textareaProps } = inputProps;
  const description =
    fieldProps.labelDescription ||
    (inputProps.maxLength && (
      <T
        k="kodkod.form.charsUsed"
        phrase="%{current}/%{max} characters used"
        current={inputProps.value.length.toLocaleString()}
        max={inputProps.maxLength.toLocaleString()}
        context="Showing the current and max characters within a form textarea"
      />
    ));

  return (
    <FormField {...fieldProps} id={id} labelDescription={description}>
      <BaseTextArea {...textareaProps} id={id} />
    </FormField>
  );
};

export default TextArea;
