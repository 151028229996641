import React from "react";
import _pt from "prop-types";
import { HashLink as Link } from "react-router-hash-link";

const Links = ({ href, children, ...props }) => {
  return (
    <Link to={href}>
      {children.map(({ props: { value } }) => {
        return value;
      })}
    </Link>
  );
};

export default Links;
