import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Row from "../../../../../core/src/components/Row";
import ProfilePhoto from "../../../../../core/src/components/ProfilePhoto";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  },
  options_space: {
    width: 24
  }
});

const Group = ({ children, group, product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <Link to={`/products/${product}/groups/base`}>
      <div className={cx(styles.feature)}>
        <Row
          center
          after={
            <div className={cx(styles.options)}>
              <div className={cx(styles.options_items)}>
                <ProfilePhoto background="light">-1</ProfilePhoto>
              </div>
              <div className={cx(styles.options_space)} />
            </div>
          }
        >
          {children}
        </Row>
      </div>
    </Link>
  );
};

export default Group;
