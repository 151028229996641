import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_PASSWORD } from "../queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import Input from "../../../../core/src/components/Input";
import Spacing from "../../../../core/src/components/Spacing";
import FormActions from "../../../../core/src/components/FormActions";
import Alert from "../../../../core/src/components/Alert";

const styleSheet = ({ unit, color }) => ({
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    visibility: "visible",
    opacity: 1,
    willChange: "opacity, visibility",
    transition: "visibility 0.2s ease 0s, opacity 0.2s ease 0s",
    background: "white"
  },

  divider: {
    width: "100%",
    margin: `0px auto ${unit * 6}`,
    borderTop: `1px solid ${color.core.neutral[1]}`
  },
  buttons: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const Security = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [alert, setAlert] = React.useState({
    status: false,
    msg: "",
    danger: false,
    success: false
  });
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState({
    current_password: "",
    new_password: "",
    confirm_password: ""
  });
  const [error, setError] = React.useState({
    current_password: { status: false },
    new_password: { status: false },
    confirm_password: { status: false }
  });
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const updateField = (value, event) => {
    setPassword({
      ...password,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};
    if (!password.new_password || password.new_password.length < 8) {
      errors = {
        ...errors,
        new_password: { status: true }
      };
      submitError = true;
    }

    if (
      !password.confirm_password ||
      password.confirm_password !== password.new_password
    ) {
      errors = {
        ...errors,
        confirm_password: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    setLoading(true);

    updatePassword({
      variables: {
        ...password
      }
    })
      .then(res => {
        console.log(res);
        setAlert({
          status: true,
          type: "success",
          msg: "Your security settings have been saved"
        });
        setLoading(false);
      })
      .catch(response => {
        setAlert({
          status: true,
          type: "danger",
          msg: response.graphQLErrors[0].message
        });
        setLoading(false);
      });
  };

  return (
    <form>
      {alert.status && (
        <Spacing bottom={2}>
          <Alert
            danger={alert.type === "danger"}
            success={alert.type === "success"}
            title={alert.msg}
            full
            hideStatusIcon
          />
        </Spacing>
      )}
      <Spacing bottom={2}>
        <Input
          name="current_password"
          label="Current password"
          type="password"
          value={password.current_password}
          onChange={updateField}
          autoComplete="off"
        />
      </Spacing>
      <Spacing bottom={2}>
        <Input
          name="new_password"
          label="New password"
          type="password"
          labelDescription="8+ characters"
          value={password.new_password}
          onChange={updateField}
          autoComplete="off"
          invalid={error.new_password.status}
          errorMessage="Password needs to be 8+ characters"
        />
      </Spacing>
      <Spacing bottom={2}>
        <Input
          name="confirm_password"
          label="Confirm new password"
          type="password"
          labelDescription="8+ characters"
          value={password.confirm_password}
          onChange={updateField}
          autoComplete="off"
          invalid={error.confirm_password.status}
          errorMessage="Looks like your new password fields don't match"
        />
      </Spacing>
      <div className={cx(styles.buttons)}>
        <FormActions
          continueText="Update"
          processingText="Updating.."
          processing={loading}
          onContinue={handleSubmit}
          disabled={
            password.current_password.length === 0 ||
            password.new_password.length === 0 ||
            password.confirm_password.length === 0
          }
          hideCancel
        />
      </div>
    </form>
  );
};

export default Security;
