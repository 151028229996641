import React from "react";
import _pt from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_FIRMWARES } from "./queries";
import Text from "../../../../core/src/components/Text";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Button from "../../../../core/src/components/Button";
import { getRolePermissions } from "../../../../helpers";
import Spacing from "../../../../core/src/components/Spacing";
import Row from "../../../../core/src/components/Row";
import Tabs, { Item } from "../../../../components/Tabs";
import FirmwareLibrary from "./FirmwareLibrary";
import FirmwareDFU from "./FirmwareDFU/index";
import FirmwareLibraryForm from "./FirmwareLibrary/FirmwareLibraryForm";
import FirmwareDFUForm from "./FirmwareDFU/FirmwareDFUForm";
import Modal from "../../../../core/src/components/Modal";
import MutedButton from "../../../../core/src/components/MutedButton";

const LibGenerator = ({ match }) => {
  const permissions = getRolePermissions("product");
  const [productID] = React.useState(match.params.product);
  const [visable, setVisable] = React.useState(false);
  const [libraryVisable, setLibraryVisable] = React.useState(false);
  const [dfuVisable, setDfuVisable] = React.useState(false);
  const [libraryFilter, setLibraryFilter] = React.useState(true);
  const [dfuFilter, setDfuFilter] = React.useState(false);

  const { loading, data, refetch } = useQuery(GET_FIRMWARES, {
    variables: { productid: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const handleFilter = () => {
    setDfuFilter(!dfuFilter);
    setLibraryFilter(!libraryFilter);
  };

  const handleAdd = () => {
    if (dfuFilter) {
      setDfuVisable(true);
    }
    if (libraryFilter) {
      setLibraryVisable(true);
    }
  };

  return loading ? (
    <LogoLoader />
  ) : (
    <>
      <Spacing bottom={2}>
        <Row
          center
          before={
            <>
              <Spacing inline right={1}>
                <MutedButton inverted={!libraryFilter} onClick={handleFilter}>
                  Library
                </MutedButton>
              </Spacing>
              <MutedButton inverted={!dfuFilter} onClick={handleFilter}>
                DFU
              </MutedButton>
            </>
          }
          after={
            permissions.firmware.includes("CREATE") && (
              <Button onClick={handleAdd}>Add</Button>
            )
          }
        ></Row>
      </Spacing>
      {libraryFilter && (
        <>
          <FirmwareLibrary product={productID} />
          {libraryVisable && (
            <Modal
              title={"Generate SDK Library"}
              onClose={() => setLibraryVisable(false)}
            >
              <FirmwareLibraryForm
                product={productID}
                onClose={() => setLibraryVisable(false)}
                refetch={refetch}
              />
            </Modal>
          )}
        </>
      )}
      {dfuFilter && (
        <>
          <FirmwareDFU product={productID} />
          {dfuVisable && (
            <Modal
              title={"Generate DFU Library"}
              onClose={() => setDfuVisable(false)}
            >
              <FirmwareDFUForm
                product={productID}
                onClose={() => setDfuVisable(false)}
                refetch={refetch}
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

LibGenerator.propTypes = {};

LibGenerator.defaultProps = {};

export default withRouter(LibGenerator);
