import React from "react";
import _pt from "prop-types";
import useStyles from "../../../core/src/hooks/useStyles";

const styleSheet = ({ color, unit }) => ({});

const NRFConnectDevice = ({}) => {
  const [styles, cx] = useStyles(styleSheet);
};

NRFConnectDevice.propTypes = {};

NRFConnectDevice.defaultProps = {};

export default NRFConnectDevice;
