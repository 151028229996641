import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { GET_CATALOG_ENTRY, DESTROY_PACKAGE_ENTRY } from "../queries";
import useStyles from "../../../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../../../core/src/hooks/useTheme";
import Row from "../../../../../../../core/src/components/Row";
import MenuToggle from "../../../../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../../../icons/src/interface/IconMenuDots";
import { getRolePermissions } from "../../../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  row: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Feature = ({ children, feature, product, entry, catalogpackage }) => {
  const permissions = getRolePermissions("sales");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  const [destroy] = useMutation(DESTROY_PACKAGE_ENTRY, {
    update(
      cache,
      {
        data: { destroyPackageEntry }
      }
    ) {
      const data = cache.readQuery({
        query: GET_CATALOG_ENTRY,
        variables: { id: entry }
      });

      data.catalogEntry.packageentry.edges = data.catalogEntry.packageentry.edges.filter(
        edge => edge.node.id !== catalogpackage
      );

      cache.writeQuery({
        query: GET_CATALOG_ENTRY,
        variables: { id: entry },
        data
      });
    }
  });

  const destroyFeature = () => {
    permissions.catalog.includes("DELETE") &&
      destroy({
        variables: {
          id: catalogpackage
        },
        optimisticResponse: {
          __typename: "Mutation",
          destroyPackageEntry: {
            catalogpackageeentry: {
              id: catalogpackage,
              __typename: "CatalogPackageEntryNode"
            },
            __typename: "destroyPackageEntry"
          }
        }
      });
  };

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.row)}>
      <Row
        after={
          <div className={cx(styles.options)}>
            {permissions.catalog.includes("DELETE") && (
              <MenuToggle
                accessibilityLabel="Actions"
                closeOnClick
                toggleIcon={
                  <IconMenuDots
                    decorative
                    color={theme.color.core.neutral[4]}
                  />
                }
                toggleLabel="Actions"
                zIndex={10}
                onShow={handleShow}
                onHide={handleHide}
              >
                <MenuItem
                  onClick={() => {
                    destroyFeature();
                  }}
                >
                  Remove {feature.name}
                </MenuItem>
              </MenuToggle>
            )}
          </div>
        }
      >
        {children}
      </Row>
    </div>
  );
};

export default Feature;
