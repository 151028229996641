import gql from "graphql-tag";

const GET_PRODUCTS = gql`
  query Products {
    allProducts {
      edges {
        node {
          id
          name
          description
          featureregionsize
          devicesCount
          featuresCount
          groupsCount
        }
      }
    }
  }
`;

const GET_PRODUCT = gql`
  query($id: ID!) {
    product(id: $id) {
      id
      name
      description
      featureregionsize
      featureregionaddress
      productkey {
        edges {
          node {
            id
            encryptionkey
          }
        }
      }
    }
  }
`;

export { GET_PRODUCTS, GET_PRODUCT };
