import gql from "graphql-tag";

const GET_TEAM = gql`
  query {
    manufacturer {
      name
      members {
        edges {
          node {
            id
            active
            account: accountid {
              id
              firstName
              lastName
              email
            }
            usertype {
              name
              rowid
            }
            pendingemail
            invitationmessage
          }
        }
      }
    }
  }
`;

const INVITE_MEMBER = gql`
  mutation createManufacturerMember(
    $pendingemail: String!
    $invitationmessage: String!
    $role: Int!
  ) {
    createManufacturerMember(
      manufacturerMemberData: {
        pendingemail: $pendingemail
        invitationmessage: $invitationmessage
        role: $role
      }
    ) {
      manufacturerMember {
        id
        active
        accountid {
          id
          firstName
          lastName
          email
        }
        pendingemail
        invitationmessage
        usertype {
          name
          rowid
        }
      }
    }
  }
`;

const UPDATE_MEMBER = gql`
  mutation updateManufacturerMember($id: ID!, $role: Int!) {
    updateManufacturerMember(manufacturerMemberData: { id: $id, role: $role }) {
      manufacturerMember {
        id
        active
        accountid {
          id
          firstName
          lastName
          email
        }
        pendingemail
        invitationmessage
        usertype {
          name
          rowid
        }
      }
    }
  }
`;

const DESTORY_MANUFACTURER_MEMBER = gql`
  mutation destroyManufacturerMember($id: ID!) {
    destroyManufacturerMember(manufacturerMemberData: { id: $id }) {
      manufacturerMember {
        id
      }
    }
  }
`;

export { GET_TEAM, DESTORY_MANUFACTURER_MEMBER, INVITE_MEMBER, UPDATE_MEMBER };
