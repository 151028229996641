import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_GROUP_DEVICES, GET_DEVICES_NOT_IN_ANY_GROUP } from "../../queries";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../../core/src/hooks/useTheme";
import Text from "../../../../../../core/src/components/Text/index";
import Row from "../../../../../../core/src/components/Row";
import Device from "./Device";
import Button from "../../../../../../core/src/components/Button";
import IconSearch from "../../../../../../icons/src/interface/IconSearch";
import Input from "../../../../../../core/src/components/Input";
import AppLoader from "../../../../../../core/src/components/AppLoader";
import Modal from "../../../../../../core/src/components/Modal";
import Form from "./Form";

import { getRolePermissions } from "../../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  options: {
    width: 24
  },
  options_bar: {
    display: "flex",
    paddingBottom: unit * 4,
    width: "100%",
    justifyContent: "between"
  },
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  search_icon: {
    marginRight: unit
  }
});

const GroupsDeviceList = ({ children, group, product }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [visable, setVisable] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [devices, setDevices] = React.useState([]);

  const { loading, data } = useQuery(GET_GROUP_DEVICES, {
    variables: { groupid: group },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const { loading: noGroupDevicesLoading, data: noGroupDevicesData } = useQuery(
    GET_DEVICES_NOT_IN_ANY_GROUP,
    {
      variables: { productid: product },
      skip: group !== "base",
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const searchChange = (value, event) => {
    setSearch(value);
  };

  React.useEffect(() => {
    if (!loading && group !== "base") {
      setDevices(data.allGroupDevices.edges);
    }
  }, [data]);

  React.useEffect(() => {
    if (!noGroupDevicesLoading && group === "base") {
      setDevices(
        noGroupDevicesData &&
          noGroupDevicesData.allDevicesNotInAnyGroup &&
          noGroupDevicesData.allDevicesNotInAnyGroup.edges
      );
    }
  }, [noGroupDevicesData]);

  React.useEffect(() => {
    if (group !== "base") {
      if (search && data.allGroupDevices.edges.length > 0) {
        const list = data.allGroupDevices.edges.filter(({ node }) => {
          return node.memberid.toLowerCase().includes(search.toLowerCase());
        });

        setDevices(list);
      }
      if (
        search === "" &&
        data &&
        data.allGroupDevices &&
        data.allGroupDevices.edges.length > 0
      ) {
        setDevices(data.allGroupDevices.edges);
      }
    } else {
      if (
        search &&
        noGroupDevicesData.allDevicesNotInAnyGroup.edges.length > 0
      ) {
        const list = noGroupDevicesData.allDevicesNotInAnyGroup.edges.edges.filter(
          ({ node }) => {
            return node.serialnumber
              .toLowerCase()
              .includes(search.toLowerCase());
          }
        );

        setDevices(list);
      }
      if (
        search === "" &&
        noGroupDevicesData &&
        noGroupDevicesData.allDevicesNotInAnyGroup &&
        noGroupDevicesData.allDevicesNotInAnyGroup.edges.length > 0
      ) {
        setDevices(noGroupDevicesData.allDevicesNotInAnyGroup.edges);
      }
    }
  }, [search]);

  return (
    <AppLoader
      fetched={!loading}
      centered
      small
      loadingText="Loading devices"
      failureText="Failed to load devices"
    >
      <div>
        <div className={cx(styles.options_bar)}>
          <div className={cx(styles.search)}>
            <IconSearch
              className={cx(styles.search_icon)}
              accessibilityLabel="search"
              width={32}
              height={32}
              color={theme.color.core.neutral[3]}
            />
            <Input
              label="search"
              hideLabel
              autoComplete="off"
              name="search"
              value={search}
              onChange={searchChange}
              placeholder="find device"
              type="search"
            />
          </div>
          {permissions.groups.includes("CREATE") && group !== "base" && (
            <Button onClick={() => setVisable(true)}>Add</Button>
          )}
        </div>
        {devices.length > 0 ? (
          <div>
            <div className={cx(styles.header)}>
              <Row
                after={
                  <div className={cx(styles.header_children)}>
                    <div className={cx(styles.options)} />
                  </div>
                }
              >
                <Text small>Serialnumber</Text>
              </Row>
            </div>
            {devices.map(({ node: device }) => (
              <Device
                key={device.id}
                device={device}
                product={product}
                group={group}
              >
                <Text bold>{device.memberid || device.serialnumber}</Text>
              </Device>
            ))}
          </div>
        ) : (
          <div>
            {search
              ? "No device match your search"
              : "Devices need to be added to group"}
          </div>
        )}
      </div>
      {permissions.groups.includes("CREATE") && visable && (
        <Modal
          title={`Add a device to group`}
          onClose={() => setVisable(false)}
        >
          <Form
            product={product}
            group={group}
            onClose={() => setVisable(false)}
          />
        </Modal>
      )}
    </AppLoader>
  );
};

export { Device };
export default GroupsDeviceList;
