import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_LOGIN_USER } from "./queries";
import { Switch, Route, Redirect } from "react-router-dom";
import queryString from "query-string";
import useStyles from "../../../core/src/hooks/useStyles";
import Header from "../../../components/Header";
import Title from "../../../core/src/components/Title";
import Spacing from "../../../core/src/components/Spacing";

import Content from "../../../components/Content";
import IconButton from "../../../core/src/components/IconButton";
import IconClose from "../../../icons/src/interface/IconClose";
import history from "../../../history";
import Tabs, { Item } from "../../../components/Tabs";
import Profile from "./profile";
import Security from "./security";
import LogoLoader from "../../../core/src/components/LogoLoader";

const styleSheet = ({ unit, color }) => ({
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    visibility: "visible",
    opacity: 1,
    willChange: "opacity, visibility",
    transition: "visibility 0.2s ease 0s, opacity 0.2s ease 0s",
    background: "white"
  },

  divider: {
    width: "100%",
    margin: `0px auto ${unit * 6}`,
    borderTop: `1px solid ${color.core.neutral[1]}`
  }
});

let role = false;
if (localStorage.getItem("user")) {
  role = JSON.parse(localStorage.getItem("user")).userType;
  console.log(role);
}
const Account = ({ location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [next, setNext] = React.useState(null);

  React.useEffect(() => {
    const nextLocation = queryString.parse(location.search).next;
    if (nextLocation) {
      setNext(nextLocation);
    } else {
      setNext("/products");
    }
  }, []);

  const {
    loading: userLoading,
    data: userData,
    refetch: userRefetch
  } = useQuery(GET_LOGIN_USER, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return (
    <div className={cx(styles.modal)}>
      <Header
        logoDark
        actions={
          <IconButton>
            <IconClose
              accessibilityLabel="Close acount screen"
              size={32}
              onClick={() =>
                history.replace({
                  pathname: `${next}`
                })
              }
            />
          </IconButton>
        }
      />
      {userLoading ? (
        <LogoLoader />
      ) : (
        <div className={cx(styles.model_presentation)}>
          <Spacing vertical={4}>
            <Title centerAlign level={2}>
              Manage account
            </Title>
          </Spacing>
          <Tabs center>
            <Item name="Profile" to={`/account/profile`} />
            <Item name="Security" to={`/account/security`} />
          </Tabs>
          <div className={cx(styles.divider)}></div>
          <Content xxsmall>
            <Switch>
              <Route
                path={`/account/profile`}
                render={props => (
                  <Profile
                    {...props}
                    user={userData.me}
                    refetch={userRefetch}
                  />
                )}
              />
              <Route
                path={`/account/security`}
                render={props => <Security {...props} />}
              />
              <Redirect to="/account/profile" exact />
            </Switch>
          </Content>
        </div>
      )}
    </div>
  );
};

export default Account;
