function exceptAssert(f, reason) {
  if (!f) {
    throw new Error(reason);
  }
}

function isString(s) {
  return typeof s === "string";
}
function isFunction(f) {
  return typeof f === "function";
}

// service uuids
export const obviousServiceUuid = "0000fddc-0000-1000-8000-00805f9b34fb".toLowerCase();
export const genericAccessUuid = "00001800-0000-1000-8000-00805f9b34fb".toLowerCase();
export const objectTransferServiceUuid = "00001825-0000-1000-8000-00805f9b34fb".toLowerCase();

// obvious characteristic uuids:
export const obviousControlPointUuid = "4ee50b51-5564-48ad-b41b-e4d5836b7628".toLowerCase();
export const obviousDataCharUuid = "4EE50B52-5564-48AD-B41B-E4D5836B7628".toLowerCase();

// bots characteristic uuids:
export const botsTypeCharUuid = "00002ABF-0000-1000-8000-00805f9b34fb".toLowerCase();
export const botsSizeCharUuid = "00002AC0-0000-1000-8000-00805f9b34fb".toLowerCase();
export const botsOacpCharUuid = "00002AC5-0000-1000-8000-00805f9b34fb".toLowerCase();
export const botsOlcpCharUuid = "00002AC6-0000-1000-8000-00805f9b34fb".toLowerCase();

// constants for BOTS
const dfuFileHexValue = "0x28766b83d5e41bb4ad48645520f0e54e";

const DECODE_STATUS_SUCCESS = 1;
const DECODE_STATUS_CONTINUEAT = 2;
const DECODE_STATUS_ERROROTHER = 3;
const DECODE_STATUS_ABORT = 4;

function _base64ToUint8Array(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}
function array2hex(arr) {
  var ret = "0x";
  for (var x = 0; x < arr.length; x++) {
    ret += arr[x].toString(16);
  }
  return ret;
}
function ab2hex(ab) {
  var ret = "0x";
  for (var x = 0; x < ab.byteLength; x++) {
    const val = ab.getUint8(x);
    if (val < 16) {
      ret += "0" + val.toString(16);
    } else {
      ret += ab.getUint8(x).toString(16);
    }
  }
  return ret;
}

function Deferred() {
  this.promise = new Promise((resolve, reject) => {
    this.resolve = resolve;
    this.reject = reject;
  });
}

function validateControlPointResponse(value) {
  if (value.byteLength < 3) {
    throw new Error("Control point response didn't have enough bytes");
  }

  if (value.getUint8(0) !== 0x80 || value.getUint8(1) !== 0x16) {
    throw new Error("response is invalid");
  }

  if (value.getUint8(2) === 0x01) {
    return Promise.resolve();
  } else {
    throw new Error("invalid response: ", value);
  }
}

function parseDataCharOutput(value) {
  if (value.byteLength < 0) {
    throw new Error("data count is too small");
  }

  const statusSuccess = 0x01;
  const statusContinue = 0x02;
  const statusAbort = 0x03;

  const data0 = value.getUint8(0);

  let out = {
    status: DECODE_STATUS_ERROROTHER,
    burst: 0,
    offset: 0,
    length: 0
  };
  switch (data0) {
    case statusSuccess: // statusSuccess
      // I think this means we're done!
      console.log("data-char sez: status-success");
      out.status = DECODE_STATUS_SUCCESS;
      break;
    case statusContinue: // statusContinue
      if (value.byteLength === 11) {
        out.status = DECODE_STATUS_CONTINUEAT;
        out.burst = value.getUint8(1) | (value.getUint8(2) << 8);
        out.offset =
          value.getUint8(3) |
          (value.getUint8(4) << 8) |
          (value.getUint8(5) << 16) |
          (value.getUint8(6) << 24);
        out.length =
          value.getUint8(7) |
          (value.getUint8(8) << 8) |
          (value.getUint8(9) << 16) |
          (value.getUint8(10) << 24);
      } else {
        out.status = DECODE_STATUS_ABORT;
      }
      break;
    case statusAbort: // statusAbort
      console.log("data-char sez: status-abort");
      out.status = DECODE_STATUS_ABORT;
      break;
    default:
      console.log("data-char sez: confusing and scary");
      out.status = DECODE_STATUS_ERROROTHER;
      break;
  }

  return out;
}

function msPromise(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// Main Class
///////////////////////////////////////////////////////////////////////
export default function ChromeDfu(base64Firmware, fnStatus, fnProgress) {
  exceptAssert(
    isString(base64Firmware),
    "You need to supply a base64 version of your firmware file"
  );
  exceptAssert(
    isFunction(fnProgress),
    "You need to supply a progress function, where progres (from 0..100) will be sent"
  );
  exceptAssert(
    isFunction(fnStatus),
    "You need to supply a status function, where text updates will go"
  );

  this.charPromises = {}; // mapping from characteristic uuids to deferreds
  this.waitForDisconnect = null;
  this.file = _base64ToUint8Array(base64Firmware);

  fnProgress(0);

  const getBufferOutOfFile = (offset, length) => {
    const ret = new Uint8Array(length);
    for (var x = 0; x < length; x++) {
      ret[x] = this.file[x + offset];
    }

    return ret;
  };
  const getFileLength = () => {
    return this.file.byteLength;
  };

  const onObviousControlPointChanged = evt => {
    // basically nothing happens here, because we don't care about the obvious control point until we're in DFU mode
  };
  const onDisconnect = evt => {
    if (this.waitForDisconnect) {
      this.waitForDisconnect.resolve(evt.target);
    }
    console.log("disconnect event ", evt);
  };

  const prepareDeferredForChar = char => {
    this.charPromises[char.uuid] = new Deferred();
  };

  const writeWithResponseToChar = (char, buffer) => {
    prepareDeferredForChar(char);
    return char.writeValue(buffer).then(() => {
      return this.charPromises[char.uuid].promise;
    });
  };
  const onObviousDfuControlPointChanged = evt => {
    console.log("obvious dfu control point changed ");

    if (this.charPromises[evt.target.uuid]) {
      this.charPromises[evt.target.uuid].resolve(evt.target.value);
    }
  };
  const onObviousDataCharChanged = evt => {
    const { status, burst, offset, length } = parseDataCharOutput(
      evt.target.value
    );

    exceptAssert(this.dataChar);
    exceptAssert(this.oacpChar);

    let writeProm = Promise.resolve();
    switch (status) {
      case DECODE_STATUS_SUCCESS:
        // file sent successfully
        const charOut = new DataView(new ArrayBuffer(1));
        charOut.setUint8(0, 0x04);
        writeProm = writeProm.then(() => {
          console.log("writing 0x04 to oacp");
          return this.oacpChar.writeValue(charOut.buffer).then(() => {
            this.dataDoneResolve();
          });
        });
        break;
      case DECODE_STATUS_CONTINUEAT:
        // size represents how many bytes we're going to do on this cycle
        const size = Math.min(burst, length);

        // blockSize represents how many loops that is, given that we can send 20 bytes/transfer via BLE
        const blockSize = Math.floor(size / 20);

        // remainder is how many bytes we'll have left over.  Like if size=190, we'll have 10 bytes after 9 normal 20-byte transfers
        const remainder = size % 20;
        let tmp = offset;

        fnStatus(
          `continue at offset ${offset}, length = ${length} burst = ${burst}`
        );
        fnProgress((100 * offset) / getFileLength());

        for (var x = 0; x < blockSize; x++) {
          const out = getBufferOutOfFile(tmp, 20);

          writeProm = writeProm.then(() => {
            console.log(
              (new Date().getTime() % 10000) + "writing another 20 bytes"
            );
            return Promise.all([
              this.dataChar.writeValue(out),
              msPromise(1000 / 25)
            ]);
          });

          tmp += 20;
        }

        if (remainder != 0) {
          const out = getBufferOutOfFile(tmp, remainder);
          writeProm = writeProm.then(
            () => {
              return this.dataChar.writeValue(out);
            },
            failure => {
              console.log(
                "Failed a remainder write! ",
                failure,
                failure.code,
                failure.message
              );
            }
          );
        }
        break;
      case DECODE_STATUS_ABORT:
        console.log("obvious said to abort");
        const dev = evt.target.service.device.gatt;
        return retry(dev);
        break;
      case DECODE_STATUS_ERROROTHER:
        break;
    }

    return writeProm
      .then(result => {})
      .catch(failure => {
        console.error(failure, failure.code, failure.message);
        debugger;
        throw failure;
      });
  };

  const retry = dev => {
    return new Promise((resolve, reject) => {
      dev.disconnect();
      setTimeout(() => {
        return dev
          .connect()
          .then(deviceServer => {
            return resumeFromDfuConnect(deviceServer.device.gatt).then(
              resolve,
              reject
            );
          })
          .catch(failure => {
            debugger;
            console.error(failure, failure.code, failure.message);
            reject(failure);
          });
      }, 1000);
    });
  };

  const onOacpCharChanged = evt => {
    console.log("oacp char changed ", evt.target.value);

    const buf = evt.target.value;
    if (buf.byteLength !== 3) {
      throw new Error("Bad OACP response");
    } else {
      if (buf.getUint8(0) !== 0x60 || buf.getUint8(1) !== 0x04) {
        throw new Error("Invalid response");
      }

      if (buf.getUint8(2) !== 0x1) {
        throw new Error("Non-success in oacp");
      }
    }
  };
  const onOlcpCharChanged = evt => {
    console.log("olcp char changed ", evt.target.value);
  };

  const cycleBotsUntil = (typeChar, olcpChar, targetHex) => {
    return new Promise((resolve, reject) => {
      let lastHex;
      const handleBotsType = value => {
        const thisHex = ab2hex(value);
        fnStatus("the bots char now has the value: ", thisHex);
        const fIsSame = thisHex === lastHex;

        if (targetHex === thisHex) {
          resolve();
        } else {
          lastHex = thisHex;
          if (fIsSame) {
            // repeated
            firstBotsType();
          } else {
            nextBotsType();
          }
        }
      };

      const firstBotsType = () => {
        const charOut = new DataView(new ArrayBuffer(1));
        charOut.setUint8(0, 0x01); // first object
        fnStatus("first-ing OLCP");
        return olcpChar.writeValue(charOut.buffer).then(written => {
          fnStatus("firsted OLCP, reading type char again");
          return typeChar.readValue().then(handleBotsType);
        });
      };
      const nextBotsType = () => {
        const charOut = new DataView(new ArrayBuffer(1));
        charOut.setUint8(0, 0x04); // next object
        fnStatus("Next-ing OLCP");
        return olcpChar
          .writeValue(charOut.buffer)
          .then(written => {
            fnStatus("nexted OLCP, reading type char again");
            return typeChar.readValue().then(handleBotsType);
          })
          .catch(failure => {
            throw failure;
          });
      };

      return nextBotsType().then(resolve, reject);
    });
  };

  const resumeFromDfuConnect = device => {
    exceptAssert(device && device.getPrimaryServices);
    fnStatus("time to rediscover services...");
    console.log("reconnected: ", device);

    const oldDataDoneResolve = this.dataDoneResolve;

    return device
      .getPrimaryServices()
      .then(serviceList => {
        fnStatus("found " + serviceList.length + " services");

        const objectTransferService = serviceList.find(service => {
          return service.uuid === objectTransferServiceUuid;
        });
        const obviousService = serviceList.find(service => {
          return service.uuid === obviousServiceUuid;
        });
        fnStatus(
          "found object transfer transfer service with uuid ",
          objectTransferService.uuid
        );

        return Promise.all([
          obviousService.getCharacteristics(),
          objectTransferService.getCharacteristics()
        ]);
      })
      .then(discoveredChars => {
        fnStatus(
          "obvious has " + discoveredChars[0].length + " characteristics"
        );
        fnStatus("BOTS has " + discoveredChars[1].length + " characteristics");

        const charMap = {};
        discoveredChars.forEach(discoveredChar => {
          discoveredChar.forEach(char => {
            charMap[char.uuid.toLowerCase()] = char;
            fnStatus("Found characteristic " + char.uuid);
          });
        });

        const neededChars = [
          obviousControlPointUuid,
          obviousDataCharUuid,
          botsTypeCharUuid,
          botsSizeCharUuid,
          botsOacpCharUuid,
          botsOlcpCharUuid
        ];

        const enableNotificationPromises = [];
        neededChars.forEach(charId => {
          const char = charMap[charId.toLowerCase()];
          if (!char) {
            throw new Error(
              "Did not find characteristic " +
                charId +
                " while setting up notifications"
            );
          }
          switch (charId) {
            case obviousControlPointUuid:
              enableNotificationPromises.push(
                char.startNotifications().then(charWithStartedNotifications => {
                  return charWithStartedNotifications.addEventListener(
                    "characteristicvaluechanged",
                    evt => onObviousDfuControlPointChanged(evt)
                  );
                })
              );
              break;
            case obviousDataCharUuid:
              enableNotificationPromises.push(
                char.startNotifications().then(charWithStartedNotifications => {
                  return charWithStartedNotifications.addEventListener(
                    "characteristicvaluechanged",
                    evt => onObviousDataCharChanged(evt)
                  );
                })
              );
              break;
            case botsTypeCharUuid:
              break;
            case botsSizeCharUuid:
              break;
            case botsOacpCharUuid:
              enableNotificationPromises.push(
                char.startNotifications().then(charWithStartedNotifications => {
                  return charWithStartedNotifications.addEventListener(
                    "characteristicvaluechanged",
                    evt => onOacpCharChanged(evt)
                  );
                })
              );
              break;
            case botsOlcpCharUuid:
              enableNotificationPromises.push(
                char.startNotifications().then(charWithStartedNotifications => {
                  return charWithStartedNotifications.addEventListener(
                    "characteristicvaluechanged",
                    evt => onOlcpCharChanged(evt)
                  );
                })
              );
              break;
          }
        });
        fnStatus(
          "We appear to have found all the characteristics we need to proceed.  Enabling notifications now."
        ); // see didDiscoverCharacteristicsFor in greg's obvioustest_cli repo
        fnStatus(
          "Need to enable notification for " +
            enableNotificationPromises.length +
            " characteristics"
        );

        const typeChar = charMap[botsTypeCharUuid];
        const olcpChar = charMap[botsOlcpCharUuid];
        const oacpChar = (this.oacpChar = charMap[botsOacpCharUuid]);
        const obviousControlPoint = charMap[obviousControlPointUuid];
        const obviousDataChar = (this.dataChar = charMap[obviousDataCharUuid]);

        return Promise.all(enableNotificationPromises)
          .then(enabled => {
            fnStatus("notifications enabled");

            return cycleBotsUntil(typeChar, olcpChar, dfuFileHexValue);
          })
          .then(cycleDone => {
            fnStatus(
              "We have cycled the BOTS, and now it matches the dfu hex file"
            );

            const sizeChar = charMap[botsSizeCharUuid];

            return sizeChar.readValue();
          })
          .then(sizeValue => {
            let length =
              sizeValue.getUint8(4) |
              (sizeValue.getUint8(5) << 8) |
              (sizeValue.getUint8(6) << 16) |
              (sizeValue.getUint8(7) << 24);
            const offset =
              sizeValue.getUint8(0) |
              (sizeValue.getUint8(1) << 8) |
              (sizeValue.getUint8(2) << 16) |
              (sizeValue.getUint8(3) << 24);

            fnStatus("BOTS says offset " + offset + " length " + length);

            if (length != getFileLength()) {
              fnStatus("Changing length to " + getFileLength());
              length = getFileLength();
            }
            length = length - offset;

            // ok, now we have to set up the transfer.  Tell obvious about it
            const charOut = new DataView(new ArrayBuffer(10));
            charOut.setUint8(0, 0x16);
            charOut.setUint8(1, (offset >> 0) & 0xff);
            charOut.setUint8(2, (offset >> 8) & 0xff);
            charOut.setUint8(3, (offset >> 16) & 0xff);
            charOut.setUint8(4, (offset >> 24) & 0xff);
            charOut.setUint8(5, (length >> 0) & 0xff);
            charOut.setUint8(6, (length >> 8) & 0xff);
            charOut.setUint8(7, (length >> 16) & 0xff);
            charOut.setUint8(8, (length >> 24) & 0xff);
            charOut.setUint8(9, 0x00);

            console.log("sending ", ab2hex(charOut));

            // by writing to the control point, the data char is about to change\
            return writeWithResponseToChar(obviousControlPoint, charOut.buffer);
          })
          .then(written => {
            return validateControlPointResponse(written);
          })
          .then(dataCharChange => {
            // all further state-machine stuff happens in the handling of the data-char-changed event
            return new Promise(resolve => {
              // when one resolves this one, to say "the data transferred!", we need to tell the previous data-done resolve, and it will pass it along as well.
              this.dataDoneResolve = () => {
                resolve();
                if (oldDataDoneResolve) {
                  oldDataDoneResolve();
                }
              };
            });
          })
          .catch(failure => {
            debugger;
            return retry(device);
          });
      });
  };

  this.startFresh = targetServiceName => {
    const filterForTarget = {
      filters: [{ services: [targetServiceName] }],
      optionalServices: [obviousServiceUuid, objectTransferServiceUuid, 0x1800]
    };
    return window.navigator.bluetooth.requestDevice(filterForTarget).then(
      device => {
        return this.startFreshOnPreconnectedDevice(device);
      },
      deviceNotSelected => {
        console.log("device not selected ", deviceNotSelected);
        throw deviceNotSelected;
      }
    );
  };

  this.startFreshOnPreconnectedDevice = preconnectedDevice => {
    return preconnectedDevice.gatt
      .connect()
      .then(deviceServer => {
        fnStatus("got a device server for device id ", deviceServer.device.id);
        deviceServer.device.addEventListener("gattserverdisconnected", evt =>
          onDisconnect(evt)
        );

        fnStatus("querying for obvious service");
        return deviceServer.getPrimaryService(obviousServiceUuid);
      })
      .then(obviousService => {
        fnStatus("got the obvious service");
        fnStatus("querying for obvious control point");
        return obviousService.getCharacteristic(obviousControlPointUuid);
      })
      .then(characteristic => {
        fnStatus("got obvious control point");

        characteristic.startNotifications();
        characteristic.addEventListener("characteristicvaluechanged", evt =>
          onObviousControlPointChanged(evt)
        );
        fnStatus("now listening to obvious control point");

        // let's get ready for this sucker disconnecting when we DFU it up.
        this.waitForDisconnect = new Deferred();

        const charOut = new DataView(new ArrayBuffer(1));
        charOut.setUint8(0, 0x7e); // 7e means reset
        return characteristic.writeValue(charOut.buffer);
      })
      .then(characteristic => {
        fnStatus("7e written.  I expected we'll be disconnecting soon...");

        return this.waitForDisconnect.promise;
      })
      .then(disconnectedDevice => {
        fnStatus(
          "we've disconnected.  Now we gotta hunt for the DFU version of that device"
        );

        return disconnectedDevice.gatt.connect();
      })
      .then(reconnected => {
        return resumeFromDfuConnect(reconnected);
      })
      .then(resumeFromDfu => {
        console.log("resume from dfu thinks it is done");
      })
      .catch(failure => {
        fnStatus(
          "failed: ",
          failure,
          failure.code,
          failure.message,
          failure.name
        );
        throw failure;
      });
  };

  this.resumeFromDfuMode = targetBleDeviceName => {
    console.log("we're hunting for ", targetBleDeviceName);

    const filterForTarget = !!targetBleDeviceName
      ? {
          filters: [{ name: [targetBleDeviceName] }],
          optionalServices: [
            obviousServiceUuid,
            objectTransferServiceUuid,
            0x1800
          ]
        }
      : {
          acceptAllDevices: true,
          optionalServices: [
            obviousServiceUuid,
            objectTransferServiceUuid,
            0x1800
          ]
        };
    return window.navigator.bluetooth
      .requestDevice(filterForTarget)
      .then(device => {
        return device.gatt.connect();
      })
      .then(deviceServer => {
        return resumeFromDfuConnect(deviceServer.device.gatt);
      })
      .then(() => {
        console.log("resume from dfu thinks it is done");
      })
      .catch(failure => {
        console.error(failure, failure.error, failure.message);
        throw failure;
      });
  };
}
