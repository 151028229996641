import React from "react";
import _pt from "prop-types";
import useStyles from "../../../../core/src/hooks/useStyles";
import { useQuery } from "@apollo/react-hooks";
import { GET_PRODUCTS, GET_MANUFACTURER_INTEGRATION_MAP } from "../queries";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Select from "../../../../core/src/components/Select";
import DevicesList, { Device } from "./DevicesList";
import Spacing from "../../../../core/src/components/Spacing";
import Text from "../../../../core/src/components/Text";

const styleSheet = ({ color, unit }) => ({});

const NRFConnectDevices = ({}) => {
  const [styles, cx] = useStyles(styleSheet);
  const [product, setProduct] = React.useState();
  const {
    loading: productsLoading,
    data: { allProducts }
  } = useQuery(GET_PRODUCTS, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });
  const {
    loading: devicesLoading,
    data: { allManufacturerDevices }
  } = useQuery(GET_MANUFACTURER_INTEGRATION_MAP, {
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });
  return productsLoading || devicesLoading ? (
    <LogoLoader />
  ) : (
    <div>
      <form>
        <Select
          label="Select a product?"
          labelDescription="Select a product to see a list of devices"
          name="product"
          placeholder="Select a product"
          value={product}
          onChange={(value, event) => setProduct(value)}
        >
          {allProducts &&
            allProducts.edges.map(({ node: product }) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
        </Select>
      </form>
      <Spacing vertical={8}>
        <DevicesList>
          {allManufacturerDevices &&
            allManufacturerDevices.edges.map(({ node: device }) => (
              <Device key={device.id} device={device}>
                <div
                  className={cx({ dispaly: "flex", alignContent: "center" })}
                >
                  <Spacing>
                    <Text bold small>
                      sn
                    </Text>
                    {device.deviceid.serialnumber}
                  </Spacing>
                  <Spacing> {device.integrationdeviceid}</Spacing>
                </div>
              </Device>
            ))}
        </DevicesList>
      </Spacing>
    </div>
  );
};

NRFConnectDevices.propTypes = {};

NRFConnectDevices.defaultProps = {};

export default NRFConnectDevices;
