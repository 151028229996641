import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  UPDATE_GROUP_FIRMWARE_ASSIGNMENT,
  DESTROY_GROUP_FIRMWARE_ASSIGNMENT,
  GET_FIRMWARE_ASSIGNMENT
} from "../../queries";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../../core/src/hooks/useTheme";
import Row from "../../../../../../core/src/components/Row";
import MenuToggle from "../../../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../../icons/src/interface/IconMenuDots";
import Switch from "../../../../../../core/src/components/Switch/index";
import Modal from "../../../../../../core/src/components/Modal";
import StatusLabel from "../../../../../../core/src/components/StatusLabel";
import IconAddAlt from "../../../../../../icons/src/interface/IconAddAlt";
import Tooltip from "../../../../../../core/src/components/Tooltip";
import IconAndroid from "../../../../../../icons/src/general/IconAndroid";
import { convertVersionToString, convertIntegerToVersion } from "../../../../../../helpers";
import Form from "./Form";

import { getRolePermissions } from "../../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  options_items: {
    marginRight: unit * 4,
    marginLeft: unit * 4
  },
  options_items_short: {
    marginRight: unit
  }
});

const Firmware = ({ children, assignment, product, group }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [visable, setVisable] = React.useState(false);
  const [updateAssignment] = useMutation(UPDATE_GROUP_FIRMWARE_ASSIGNMENT);
  const [destroyAssignment] = useMutation(DESTROY_GROUP_FIRMWARE_ASSIGNMENT, {
    update(
      cache,
      {
        data: { destroyFirmwareAssignemnt }
      }
    ) {
      const data = cache.readQuery({
        query: GET_FIRMWARE_ASSIGNMENT,
        variables: {
          groupid: group !== "base" ? group : null,
          productid: product
        }
      });
      data.allFirmwareAssignments.edges = data.allFirmwareAssignments.edges.filter(
        edge => edge.node.id !== assignment.id
      );

      cache.writeQuery({
        query: GET_FIRMWARE_ASSIGNMENT,
        variables: {
          groupid: group !== "base" ? group : null,
          productid: product
        },
        data
      });
    }
  });
  const removeAssignment = () => {
    permissions.groups.includes("DELETE") &&
      destroyAssignment({
        variables: {
          id: assignment.id,
          productid: product,
          groupid: group !== "base" ? group : null
        },
        optimisticResponse: {
          __typename: " Mutation",
          destroyFirmwareAssigment: {
            assignment: {
              id: assignment.id,
              __typename: "GroupFirmwareAssignmentNode"
            },
            __typename: "destroyFirmwareAssigment"
          }
        }
      });
  };

  const toggleStatus = () => {
    permissions.groups.includes("UPDATE") &&
      updateAssignment({
        variables: {
          ...assignment,
          firmwareid: assignment.firmware.id,
          active: !assignment.active,
          productid: product
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateGroupFirmwareAssignment: {
            assignment: {
              ...assignment,
              active: !assignment.active,
              __typename: "GroupFirmwareAssignmentNode"
            },
            __typename: "updateGroupFirmwareAssignment"
          }
        }
      });
  };

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <Row
        after={
          <div className={cx(styles.options)}>
            <div className={cx(styles.options_items_short)}>
              <Tooltip content="Min Android Version">
                <StatusLabel beforeIcon={<IconAndroid size={12} decorative />}>
                  {convertIntegerToVersion(assignment.minverandroid)}
                </StatusLabel>
              </Tooltip>
            </div>
            <div className={cx(styles.options_items_short)}>
              <Tooltip content="Min iOS Version">
                <StatusLabel beforeIcon={<IconAddAlt size={12} decorative />}>
                  {convertIntegerToVersion(assignment.minverios)}
                </StatusLabel>
              </Tooltip>
            </div>
            <div className={cx(styles.options_items)}>
              <Switch
                label="feature active status"
                hideLabel
                noSpacing
                checked={assignment.active}
                onChange={toggleStatus}
              />
            </div>
            {(permissions.groups.includes("UPDATE") ||
              permissions.groups.includes("DELETE")) && (
              <MenuToggle
                closeOnClick
                accessibilityLabel="Actions"
                toggleIcon={
                  <IconMenuDots
                    decorative
                    color={theme.color.core.neutral[4]}
                  />
                }
                toggleLabel="Actions"
                zIndex={10}
                onShow={handleShow}
                onHide={handleHide}
              >
                {permissions.groups.includes("UPDATE") && (
                  <MenuItem onClick={toggleStatus}>
                    {assignment.active ? "Deactivate" : "Activate"}
                  </MenuItem>
                )}
                {permissions.groups.includes("UPDATE") && (
                  <MenuItem onClick={() => setVisable(true)}>Edit</MenuItem>
                )}
                {permissions.groups.includes("DELETE") && (
                  <MenuItem onClick={removeAssignment}>
                    Remove firmware assignment
                  </MenuItem>
                )}
              </MenuToggle>
            )}
          </div>
        }
      >
        {children}
      </Row>
      {permissions.groups.includes("UPDATE") && visable && (
        <Modal
          title={`Edit firmware assignment`}
          onClose={() => setVisable(false)}
        >
          <Form
            product={product}
            group={group}
            selectedAssignment={assignment}
            onClose={() => setVisable(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Firmware;
