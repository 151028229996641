import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import useStyles, { aesthetic } from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import IconObviousLogo from "../../icons/src/interface/IconObviousLogo";

const styleSheet = ({ color, unit }) => ({
  header: {
    margin: unit * 3,
    position: "relative",
    zIndex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  header_logo: {}
});

const Header = ({ actions, className, logoDark }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [id] = React.useState(uuid());

  return (
    <div className={cx(styles.header, className)}>
      <IconObviousLogo dark={logoDark} height={32} width={"auto"} decorative />
      <div>{actions}</div>
    </div>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
