import React from "react";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_FIRMWARES } from "./queries";
import Text from "../../../../core/src/components/Text";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Button from "../../../../core/src/components/Button";
import FirmwareList, { Firmware as FirmwareItem } from "./FirmwareList";
import Modal from "../../../../core/src/components/Modal";
import FirmwareForm from "./FirmwareForm";
import { getRolePermissions } from "../../../../helpers";
import Spacing from "../../../../core/src/components/Spacing";
import Row from "../../../../core/src/components/Row";

const Firmware = ({ match }) => {
  const permissions = getRolePermissions("product");
  const [productID] = React.useState(match.params.product);
  const [visable, setVisable] = React.useState(false);
  const { loading, data, refetch } = useQuery(GET_FIRMWARES, {
    variables: { productid: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  return loading ? (
    <LogoLoader />
  ) : (
    <>
      <Spacing bottom={2}>
        <Row
          center
          before={<Text bold>{data.allFirmware.edges.length + 1} Groups</Text>}
          after={
            permissions.firmware.includes("CREATE") && (
              <Button onClick={() => setVisable(true)}>Add</Button>
            )
          }
        ></Row>
      </Spacing>
      <>
        <FirmwareList>
          {data &&
            data.allFirmware &&
            data.allFirmware.edges.map(({ node: firmware }) => {
              return (
                <FirmwareItem
                  key={firmware.id}
                  firmware={firmware}
                  product={productID}
                >
                  <div>
                    <Text bold>{firmware.version}</Text>
                    <Text small>{firmware.originalfilename}</Text>
                  </div>
                </FirmwareItem>
              );
            })}
        </FirmwareList>
        {permissions.firmware.includes("CREATE") && visable && (
          <Modal title={"Add Firmware"} onClose={() => setVisable(false)}>
            <FirmwareForm
              product={productID}
              onClose={() => setVisable(false)}
            />
          </Modal>
        )}
      </>
      {/* <Switch>
        <Redirect
          exact
          from="/products/:product/firmware/"
          to="/products/:product/firmware/list"
        />
        <Route
          path="/products/:product/firmware/list"
          render={() => (

          )}
        /> */}
      {/* <Route
          path="/products/:product/firmware/library"
          render={props => {
            return (
              <>
                <FirmwareLibrary product={productID} />
                {libraryVisable && (
                  <Modal
                    title={"Generate SDK Library"}
                    onClose={() => setLibraryVisable(false)}
                  >
                    <FirmwareLibraryForm
                      product={productID}
                      onClose={() => setLibraryVisable(false)}
                      refetch={refetch}
                    />
                  </Modal>
                )}
              </>
            );
          }}
        />
        <Route
          path="/products/:product/firmware/dfu"
          render={props => {
            return (
              <>
                <FirmwareDFU product={productID} />
                {dfuVisable && (
                  <Modal
                    title={"Generate DFU Library"}
                    onClose={() => setDfuVisable(false)}
                  >
                    <FirmwareDFUForm
                      product={productID}
                      onClose={() => setDfuVisable(false)}
                      refetch={refetch}
                    />
                  </Modal>
                )}
              </>
            );
          }}
        /> */}
      {/* </Switch> */}
    </>
  );
};

Firmware.propTypes = {};

Firmware.defaultProps = {};

export default withRouter(Firmware);
