import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { DESTORY_MANUFACTURER_MEMBER, GET_TEAM } from "../queries";
import useStyles from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import Row from "../../../core/src/components/Row";
import MenuToggle from "../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../icons/src/interface/IconMenuDots";
import FormActions from "../../../core/src/components/FormActions";
import Modal from "../../../core/src/components/Modal";
import { getRolePermissions } from "../../../helpers";

const styleSheet = ({ color, unit }) => ({
  member: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Member = ({ children, member, product }) => {
  const permissions = getRolePermissions("team");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [destroyVisable, setDestroyVisable] = React.useState(false);
  const [editVisable, setEditVisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [destroy] = useMutation(DESTORY_MANUFACTURER_MEMBER, {
    update(
      cache,
      {
        data: { destroyManufacturerMember }
      }
    ) {
      const data = cache.readQuery({
        query: GET_TEAM
      });

      data.manufacturer.members.edges = data.manufacturer.members.edges.filter(
        edge => edge.node.id !== member.id
      );

      cache.writeQuery({
        query: GET_TEAM,
        data
      });
    }
  });

  const destroyMember = () => {
    if (permissions.members.includes("DELETE")) {
      setLoading(true);
      destroy({
        variables: { id: member.id },
        optimisticResponse: {
          __typename: "Mutation",
          destroyManufacturerMember: {
            entry: {
              id: member.id,
              __typename: "ManufacturerMemberNode"
            },
            __typename: "destroyManufacturerMember"
          }
        }
      });
      setDestroyVisable(false);
    }
  };

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.member)}>
      <Row
        after={
          <div className={cx(styles.options)}>
            {permissions.members.includes("DELETE") && (
              <MenuToggle
                accessibilityLabel="Actions"
                closeOnClick
                toggleIcon={
                  <IconMenuDots
                    decorative
                    color={theme.color.core.neutral[4]}
                  />
                }
                toggleLabel="Actions"
                zIndex={10}
                onShow={handleShow}
                onHide={handleHide}
              >
                <MenuItem danger onClick={() => setDestroyVisable(true)}>
                  Delete {member.pendingemail}
                </MenuItem>
              </MenuToggle>
            )}
          </div>
        }
      >
        {children}
      </Row>
      {permissions.members.includes("DELETE") && destroyVisable && (
        <Modal
          title={`Delete ${member.pendingemail}`}
          subtitle={"Warning, this step cannot be undone"}
          onClose={() => setDestroyVisable(false)}
        >
          <FormActions
            continueText={`Yes, remove ${member.pendingemail}`}
            processingText="Removing..."
            processing={loading}
            onCancel={() => setDestroyVisable(false)}
            onContinue={destroyMember}
          />
        </Modal>
      )}
    </div>
  );
};

export default Member;
