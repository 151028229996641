import React from "react";
import _pt from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_PRODUCT,
  GET_CATALOG_ENTRIES,
  GET_FEATURES
} from "./Entries/queries";
import useStyles from "../../../../../core/src/hooks/useStyles";

import PageHeader from "../../../../../components/PageHeader";
import LogoLoader from "../../../../../core/src/components/LogoLoader";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../../core/src/components/Breadcrumbs";
import Entries from "./Entries";
import Button from "../../../../../core/src/components/Button";
import Modal from "../../../../../core/src/components/Modal";
import Form from "./Form";
import { getRolePermissions } from "../../../../../helpers";
import Layout from "../../../../../layout/src/components/Layout";
import TopMenu from "../../../../../components/TopMenu";
import Content from "../../../../../components/Content";
import MainMenu from "../../../../../components/MainMenu";
import ProductBreadcrumb from "../../../../../components/ProductBreadcrumb";
import Spacing from "../../../../../core/src/components/Spacing";
import Title from "../../../../../core/src/components/Title";
import Text from "../../../../../core/src/components/Text/index";

const styleSheet = ({ color, unit }) => ({
  flex_center: {
    display: "flex",
    alignItems: "center"
  },
  tabs: {
    borderBottom: "solid 2px",
    borderColor: color.core.neutral[1]
  }
});

const Product = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const permissions = getRolePermissions("sales");
  const [productID] = React.useState(match.params.product);
  const [visable, setVisable] = React.useState(false);
  const { loading, data: dataProduct } = useQuery(GET_PRODUCT, {
    variables: { id: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const { loading: entriesLoading, data: dataEntries } = useQuery(
    GET_CATALOG_ENTRIES,
    {
      variables: { productid: productID },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const { loading: featuresLoading, data: dataFeatures } = useQuery(
    GET_FEATURES,
    {
      variables: { productid: productID },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <TopMenu />
      <Content>
        {loading || entriesLoading || featuresLoading ? (
          <LogoLoader />
        ) : (
          <div>
            <ProductBreadcrumb title={"Sales"}>
              <Breadcrumbs center accessibilityLabel="Breadcrumb">
                <Breadcrumb
                  highlighted
                  label="Catalog"
                  href={`/sales/catalog`}
                />
                <Breadcrumb hideIcon label={dataProduct.product.name} />
              </Breadcrumbs>
            </ProductBreadcrumb>
            <Spacing bottom={2} top={4}>
              <div className={cx(styles.flex_center)}>
                <div className={cx({ flexGrow: 1 })}>
                  <div className={cx(styles.flex_center)}>
                    <Title level={2}>{dataProduct.product.name}</Title>
                  </div>

                  <Text truncated leading="none">
                    {dataProduct.product.description}
                  </Text>
                </div>
                {permissions.catalog.includes("UPDATE") && (
                  <Button onClick={() => setVisable(true)}>Add</Button>
                )}
              </div>
            </Spacing>
            <Entries
              product={productID}
              allProductEntries={dataEntries.allProductEntries}
              allProductFeatures={dataFeatures.allProductFeatures}
              allReservedFeatures={dataFeatures.allReservedFeatures}
            />
            {permissions.catalog.includes("UPDATE") && visable && (
              <Modal
                title="Create Catalog Entry"
                onClose={() => setVisable(false)}
              >
                <Form
                  allEntries={dataEntries.allProductEntries.edges.map(
                    ({ node }) => {
                      return node.featureid;
                    }
                  )}
                  allFeatures={dataFeatures.allProductFeatures}
                  reservedFeatures={dataFeatures.allReservedFeatures}
                  product={productID}
                  onClose={() => setVisable(false)}
                />
              </Modal>
            )}
          </div>
        )}
      </Content>
    </Layout>
  );
};

Product.propTypes = {};

Product.defaultProps = {};

export default Product;
