import gql from "graphql-tag";

const GET_MANUFACTURER_INTEGRATION_TYPE = gql`
  query allManufacturerTypes {
    allManufacturerTypes {
      edges {
        node {
          id
          name
          description
          rowid
        }
      }
    }
  }
`;

const GET_MANUFACTURER_INTEGRATION_MAP = gql`
  query allManufacturerDevices {
    allManufacturerDevices {
      edges {
        node {
          id
          integrationdeviceid
          deviceid {
            serialnumber
            productid {
              id
            }
          }
        }
      }
    }
  }
`;

const GET_MANUFACTURER_INTEGRATION_CONFIG = gql`
  query allManufacturerConfigs {
    allManufacturerConfigs {
      edges {
        node {
          id
          integrationtypeid
          apikey
          clientid
          clientsecret
          datecreated
          datedeactivated
        }
      }
    }
  }
`;

const CREATE_MANUFACTURER_INTEGRATION_CONFIG = gql`
  mutation createManufacturerIntegrationConfig(
    $integrationtypeid: Int!
    $value: String!
  ) {
    createManufacturerIntegrationConfig(
      data: { integrationtypeid: $integrationtypeid, value: $value }
    ) {
      config {
        id
        integrationtypeid
        apikey
        clientid
        clientsecret
        datecreated
        datedeactivated
      }
    }
  }
`;

const UPDATE_MANUFACTURER_INTEGRATION_CONFIG = gql`
  mutation updateManufacturerIntegrationConfig($id: ID!, $value: String!) {
    updateManufacturerIntegrationConfig(data: { id: $id, value: $value }) {
      config {
        id
        integrationtypeid
        apikey
        clientid
        clientsecret
        datecreated
        datedeactivated
      }
    }
  }
`;

const DESTROY_MANUFACTURER_INTEGRATION_CONFIG = gql`
  mutation destroyManufacturerIntegrationConfig($id: ID!) {
    destroyManufacturerIntegrationConfig(data: { id: $id }) {
      success
    }
  }
`;

const GET_PRODUCTS = gql`
  query Products {
    allProducts {
      edges {
        node {
          id
          name
          description
          featureregionsize
          devicesCount
          featuresCount
          groupsCount
        }
      }
    }
  }
`;

export {
  GET_MANUFACTURER_INTEGRATION_TYPE,
  GET_MANUFACTURER_INTEGRATION_CONFIG,
  CREATE_MANUFACTURER_INTEGRATION_CONFIG,
  UPDATE_MANUFACTURER_INTEGRATION_CONFIG,
  DESTROY_MANUFACTURER_INTEGRATION_CONFIG,
  GET_PRODUCTS,
  GET_MANUFACTURER_INTEGRATION_MAP
};
