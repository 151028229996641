import React from "react";
import _pt from "prop-types";
import PageHeader from "../../../../../components/PageHeader";

const CapabilityHeader = ({ end, tabs }) => {
  return (
    <PageHeader
      title="ANT"
      subtitle="Is the wireless technology that allows your monitoring devices
            to talk to each other."
      end={end}
      logo="/static/brand/ANTPlus.png"
      tabs={tabs}
    />
  );
};

CapabilityHeader.propTypes = {};

CapabilityHeader.defaultProps = {};

export default CapabilityHeader;
