import React from "react";
import _pt from "prop-types";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import Card, {
  Content as CardContent
} from "../../../../core/src/components/Card";
import AdaptiveGrid from "../../../../core/src/components/AdaptiveGrid";
import Spacing from "../../../../core/src/components/Spacing";
import Text from "../../../../core/src/components/Text";
import Divider from "../../../../core/src/components/Divider";
import MenuToggle from "../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../icons/src/interface/IconMenuDots";
import { toLocalNumber } from "../../../../helpers";
import history from "../../../../history";

const styleSheet = ({ color, unit }) => ({
  options: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingTop: unit,
    paddingRight: unit
  }
});

const ProductItem = ({ product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  const handleClick = product => {
    history.push(`/sales/catalog/products/${product}`);
  };

  return (
    <div>
      <Card overflow>
        <div className={cx(styles.options)}>
          <MenuToggle
            accessibilityLabel="Actions"
            closeOnClick
            zIndex={50}
            toggleIcon={
              <IconMenuDots decorative color={theme.color.core.neutral[4]} />
            }
            toggleLabel="Actions"
          >
            <MenuItem href={`/sales/catalog/products/${product.id}`}>
              View
            </MenuItem>
          </MenuToggle>
        </div>
        <CardContent onClick={() => handleClick(product.id)} truncated>
          <Spacing all={2}>
            <Text truncated large bold>
              <div
                className={cx({
                  color: theme.color.core.primary[3]
                })}
              >
                {product.name}
              </div>
            </Text>
            <div className={cx({ height: 16 })}>
              <Text truncated>{product.description}</Text>
            </div>
          </Spacing>
        </CardContent>
      </Card>
    </div>
  );
};

ProductItem.propTypes = {};

ProductItem.defaultProps = {};

export default ProductItem;
