import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  UPDATE_PROFILE,
  GET_LOGIN_USER,
  UPDATE_LOCAL_PROFILE,
  GET_USER
} from "../queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import Input from "../../../../core/src/components/Input";
import Spacing from "../../../../core/src/components/Spacing";
import FormActions from "../../../../core/src/components/FormActions";
import { validateEmail } from "../../../../helpers";
import Alert from "../../../../core/src/components/Alert";

const styleSheet = ({ unit, color }) => ({
  modal: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    visibility: "visible",
    opacity: 1,
    willChange: "opacity, visibility",
    transition: "visibility 0.2s ease 0s, opacity 0.2s ease 0s",
    background: "white"
  },

  divider: {
    width: "100%",
    margin: `0px auto ${unit * 6}`,
    borderTop: `1px solid ${color.core.neutral[1]}`
  },
  buttons: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const Profile = ({ refetch }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [loading, setLoading] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState();
  const [alert, setAlert] = React.useState({
    status: false,
    msg: "",
    type: "",
    success: false
  });
  const [user, setUser] = React.useState({
    firstName: "",
    email: ""
  });
  const [error, setError] = React.useState({
    firstName: { status: false },
    email: { status: false }
  });
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setCurrentUser(user);
    setUser({
      firstName: user.firstName,
      email: user.email
    });
  }, []);

  const updateField = (value, event) => {
    setUser({
      ...user,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let submitError = false;
    var errors = {};
    if (!user.email || !validateEmail(user.email)) {
      errors = {
        ...errors,
        email: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    setLoading(true);

    updateProfile({
      variables: {
        ...user
      }
    })
      .then(res => {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        currentUser.firstName = user.firstName;
        currentUser.email = user.email;
        localStorage.setItem("user", JSON.stringify(currentUser));

        setAlert({
          status: true,
          type: "success",
          msg: "Your profile settings have been saved"
        });
        setLoading(false);
      })
      .catch(response => {
        setAlert({
          status: true,
          type: "danger",
          msg: "Something went wrong"
        });
        setLoading(false);
      });
  };

  return (
    <>
      <form>
        {alert.status && (
          <Spacing bottom={2}>
            <Alert
              danger={alert.type === "danger"}
              success={alert.type === "success"}
              title={alert.msg}
              full
              hideStatusIcon
            />
          </Spacing>
        )}
        <Spacing bottom={2}>
          <Input
            name="firstName"
            label="Full Name"
            type="text"
            value={user.firstName}
            onChange={updateField}
          />
        </Spacing>
        <Spacing bottom={2}>
          <Input
            name="email"
            label="Email"
            type="email"
            value={user.email}
            onChange={value => setUser({ ...user, email: value })}
            invalid={error.email.status}
            errorMessage="Please add a valid email address"
          />
        </Spacing>
        <div className={cx(styles.buttons)}>
          <FormActions
            continueText="Update"
            processingText="Updating.."
            processing={loading}
            onContinue={handleSubmit}
            disabled={
              (user.firstName.length === 0 ||
                user.firstName === currentUser.firstName) &&
              (user.email.length === 0 || user.email === currentUser.email)
            }
            hideCancel
          />
        </div>
      </form>
    </>
  );
};

export default Profile;
