import React from "react";
import { Link } from "react-router-dom";
import _pt from "prop-types";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import Aside from "../../layout/src/components/Aside";
import Spacing from "../../core/src/components/Spacing";
import List from "../../core/src/components/List";
import IconObviousShortLogo from "../../icons/src/interface/IconObviousShortLogo";
import MenuItem from "./Item";
import IconWatch from "../../icons/src/general/IconWatch";
import IconWallet from "../../icons/src/general/IconWallet";
import IconUserGroup from "../../icons/src/general/IconUserGroup";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";
import Dropdown from "../../core/src/components/Dropdown";
import Menu, { Item } from "../../core/src/components/Menu";
import { changeThemeColors, getRolePermissions } from "../../helpers";
import history from "../../history";
import IconArticle from "../../icons/src/general/IconArticle";
import IconExperiences from "../../icons/src/general/IconExperiences";
import IconChat from "../../icons/src/interface/IconChat";

const styleSheet = () => ({
  main_menu: {
    display: "flex",
    flexDirection: "column",
    width: "72px",
    height: "100%"
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  account: {
    height: 72,
    width: 72,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  account_item: {}
});

const MainMenu = ({ dark }) => {
  const permissions = getRolePermissions();
  const user = JSON.parse(localStorage.getItem("user"));
  const name = {
    firstName: user.firstName,
    lastName: user.lastName
  };
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Aside width={72} color={theme.color.core.neutral[9]} noPadding>
      <div className={cx(styles.main_menu)}>
        <Spacing top={4} bottom={4}>
          <div
            className={cx({
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            })}
          >
            <Link to="/products">
              <IconObviousShortLogo width="32" height="32" decorative />
            </Link>
          </div>
        </Spacing>
        <div className={cx(styles.menu)}>
          <Spacing vertical={0}>
            <List>
              {permissions.products.includes("READ") && (
                <MenuItem
                  icon={<IconWatch color={"#fff"} size={24} decorative />}
                  name="Products"
                  to={`/products`}
                />
              )}
              {Object.keys(permissions.sales).length > 0 && (
                <MenuItem
                  icon={<IconWallet color="#fff" size={24} decorative />}
                  name="Sales"
                  to={`/sales`}
                />
              )}
              {Object.keys(permissions.team).length > 0 && (
                <MenuItem
                  icon={<IconUserGroup color="#fff" size={24} decorative />}
                  name="Team"
                  to={`/team`}
                />
              )}
              {permissions.tools.includes("READ") && (
                <MenuItem
                  icon={<IconExperiences color="#fff" size={24} decorative />}
                  name="Tools"
                  to={`/tools`}
                />
              )}
              {permissions.docs.includes("READ") && (
                <MenuItem
                  icon={<IconArticle color="#fff" size={24} decorative />}
                  name="Resources"
                  to={`/docs`}
                />
              )}
            </List>
          </Spacing>
        </div>
      </div>
    </Aside>
  );
};

MainMenu.propTypes = {
  children: _pt.node
};

MainMenu.defaultProps = {
  children: null
};

export default MainMenu;
