import gql from "graphql-tag";

const VERIFY_REGEISTER_USER_EMAIL = gql`
  mutation verifyRegisterUserEmail($email: String!) {
    verifyRegisterUserEmail(email: $email) {
      success
      errors
    }
  }
`;

const CREATE_ACCOUNT = gql`
  mutation registerUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
  ) {
    registerUser(
      data: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
      }
    ) {
      success
      errors
    }
  }
`;

export { VERIFY_REGEISTER_USER_EMAIL, CREATE_ACCOUNT };
