import React from "react";
import _pt from "prop-types";
import useStyles from "../../../core/src/hooks/useStyles";
import Tool from "../Tool";
import { Switch, Route, Redirect } from "react-router-dom";
import Overview from "./Overview";
import Devices from "./Devices";
import Device from "./Device";
import Settings from "./Settings";
import Tabs, { Item } from "../../../components/Tabs";

const styleSheet = ({ color, unit }) => ({});

const NRFConnect = ({}) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <Tool
      header={{ title: "nRF Connect" }}
      tabs={
        <Tabs>
          <Item name="Overview" to={`/tools/nrfconnect/overview`} />
          <Item name="Devices" to={`/tools/nrfconnect/devices`} />
          <Item name="Settings" to={`/tools/nrfconnect/settings`} />
        </Tabs>
      }
    >
      <Switch>
        <Redirect
          exact
          from={`/tools/nrfconnect`}
          to={`/tools/nrfconnect/overview`}
        />

        <Route
          path={`/tools/nrfconnect/overview`}
          render={props => <Overview {...props} />}
        />
        <Route
          path={`/tools/nrfconnect/devices`}
          render={props => <Devices {...props} />}
        />
        <Route
          path={`/tools/nrfconnect/device/:device`}
          render={props => <Device {...props} />}
        />
        <Route
          path={`/tools/nrfconnect/settings`}
          render={props => <Settings {...props} />}
        />
      </Switch>
    </Tool>
  );
};

NRFConnect.propTypes = {};

NRFConnect.defaultProps = {};

export default NRFConnect;
