import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_FEATURE, DESTROY_FEATURE, GET_FEATURES } from "../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Row from "../../../../../core/src/components/Row";
import Switch from "../../../../../core/src/components/Switch";
import MenuToggle from "../../../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../icons/src/interface/IconMenuDots";
import FormActions from "../../../../../core/src/components/FormActions";
import Modal from "../../../../../core/src/components/Modal";
import FeatureForm from "../FeatureForm";
import { getRolePermissions } from "../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Feature = ({ children, feature, product }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [destroyVisable, setDestroyVisable] = React.useState(false);
  const [editVisable, setEditVisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mutate] = useMutation(UPDATE_FEATURE);
  const [destroy] = useMutation(DESTROY_FEATURE, {
    update(
      cache,
      {
        data: { destroyFeature }
      }
    ) {
      const data = cache.readQuery({
        query: GET_FEATURES,
        variables: { productid: product }
      });
      data.allProductFeatures.edges = data.allProductFeatures.edges.filter(
        edge => edge.node.id !== feature.id
      );

      cache.writeQuery({
        query: GET_FEATURES,
        variables: { productid: product },
        data
      });
    }
  });
  const toggleStatus = () => {
    permissions.features.includes("UPDATE") &&
      mutate({
        variables: {
          ...feature,
          productid: product,
          deactivated: !feature.deactivated
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateFeature: {
            feature: {
              ...feature,
              deactivated: !feature.deactivated,
              __typename: "FeatureNode"
            },
            __typename: "UpdateFeature"
          }
        }
      });
  };
  const toggleDeleveryType = () => {
    permissions.features.includes("UPDATE") &&
      mutate({
        variables: {
          ...feature,
          productid: product,
          defaultfeature: !feature.defaultfeature
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateFeature: {
            feature: {
              ...feature,
              defaultfeature: !feature.defaultfeature,
              __typename: "FeatureNode"
            },
            __typename: "UpdateFeature"
          }
        }
      });
  };

  const destroyFeature = () => {
    if (permissions.features.includes("DELETE")) {
      setLoading(true);
      destroy({
        variables: {
          productid: product,
          id: feature.id
        },
        optimisticResponse: {
          __typename: "Mutation",
          destroyFeature: {
            feature: {
              id: feature.id,
              __typename: "FeatureNode"
            },
            __typename: "destroyFeature"
          }
        }
      });
      setDestroyVisable(false);
    }
  };

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <Row center>
        <div className={cx({ flexGrow: 1 })}>{children}</div>
        <div className={cx(styles.options)}>
          <div className={cx(styles.options_items)}>
            {feature.defaultfeature ? "Provision" : "Purchasable"}
          </div>

          <div className={cx(styles.options_items)}>
            <Switch
              label="feature active status"
              hideLabel
              noSpacing
              checked={!feature.deactivated}
              onChange={toggleStatus}
            />
          </div>
          {(permissions.features.includes("UPDATE") ||
            permissions.features.includes("DELETE")) && (
            <MenuToggle
              accessibilityLabel="Actions"
              closeOnClick
              toggleIcon={
                <IconMenuDots decorative color={theme.color.core.neutral[4]} />
              }
              toggleLabel="Actions"
              zIndex={10}
              onShow={handleShow}
              onHide={handleHide}
            >
              {permissions.features.includes("UPDATE") && (
                <MenuItem onClick={() => setEditVisable(true)}>Edit</MenuItem>
              )}
              {permissions.features.includes("UPDATE") && (
                <MenuItem
                  onClick={() => {
                    toggleStatus();
                  }}
                >
                  {!feature.deactivated ? "Deactivate" : "Activate"}
                </MenuItem>
              )}
              {permissions.features.includes("UPDATE") && (
                <MenuItem
                  onClick={() => {
                    toggleDeleveryType();
                  }}
                >
                  Change to{" "}
                  {feature.defaultfeature ? "Purchasable" : "Provision"}
                </MenuItem>
              )}
              {permissions.features.includes("DELETE") && (
                <MenuItem danger onClick={() => setDestroyVisable(true)}>
                  Delete {feature.name}
                </MenuItem>
              )}
            </MenuToggle>
          )}
        </div>
      </Row>
      {permissions.features.includes("DELETE") && destroyVisable && (
        <Modal
          title={`Delete ${feature.name}`}
          subtitle={"Warning, this step cannot be undone"}
          onClose={() => setDestroyVisable(false)}
        >
          <FormActions
            continueText={`Yes, remove ${feature.name}`}
            processingText="Removing..."
            processing={loading}
            onCancel={() => setDestroyVisable(false)}
            onContinue={destroyFeature}
          />
        </Modal>
      )}
      {permissions.features.includes("UPDATE") && editVisable && (
        <Modal
          title={`Edit ${feature.name}`}
          onClose={() => setEditVisable(false)}
        >
          <FeatureForm
            product={product}
            selectedFeature={feature}
            onClose={() => setEditVisable(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Feature;
