import React from "react";
import _pt from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Spacing from "../../../../../core/src/components/Spacing";
import Disabled from "./Disabled";
import Active from "./Active";

const styleSheet = ({ color, unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 2
  }
});

const Capability = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  return (
    <div>
      <Spacing bottom={8}>
        <Switch>
          <Route
            path={`/products/:product/capabilities/:capability`}
            render={props => <Active {...props} />}
          />
        </Switch>
      </Spacing>
    </div>
  );
};

Capability.propTypes = {};

Capability.defaultProps = {};

export default Capability;
