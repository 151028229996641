import hoistNonReactStatics from 'hoist-non-react-statics';

export default function finishHOC(name, WrapperComponent, WrappedComponent) {
  /* istanbul ignore next */
  const wrappedName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  WrapperComponent.displayName = name + '(' + wrappedName + ')'; // @ts-ignore

  WrapperComponent.WrappedComponent = WrappedComponent;
  hoistNonReactStatics(WrapperComponent, WrappedComponent);

  return WrapperComponent;
}
