import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_ENTRY,
  GET_CATALOG_ENTRIES,
  UPDATE_ENTRY
} from "./Entries/queries";
import FormActions from "../../../../../core/src/components/FormActions";
import Select from "../../../../../core/src/components/Select";
import Input from "../../../../../core/src/components/Input/index";
import TextArea from "../../../../../core/src/components/TextArea";
import Multicomplete from "../../../../../core/src/components/Multicomplete";
import Text from "../../../../../core/src/components/Text";
import Spacing from "../../../../../core/src/components/Spacing";

const Form = ({
  product,
  reservedFeatures,
  allFeatures,
  allEntries,
  selectedEntry,
  onClose,
  refetch,
  ...props
}) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [featureList, setFeatureList] = React.useState([]);
  const [type, setType] = React.useState("create");
  const [entry, setEntry] = React.useState({
    featureid: "",
    name: "",
    description: "",
    sku: "",
    itemprice: "",
    imageurl: "",
    thumbnailurl: "",
    entrytype: 1,
    features: []
  });

  const [error, setError] = React.useState({
    featureid: { status: false },
    name: { status: false },
    description: { status: false },
    sku: { status: false },
    itemprice: { status: false },
    imageurl: { status: false },
    thumbnailurl: { status: false },
    entrytype: { status: false },
    features: { status: false }
  });

  const [createEntry] = useMutation(CREATE_ENTRY, {
    update(
      cache,
      {
        data: { createEntry }
      }
    ) {
      const data = cache.readQuery({
        query: GET_CATALOG_ENTRIES,
        variables: { productid: product }
      });

      data.allProductEntries.edges.push({
        node: {
          ...createEntry.entry
        },
        __typename: "CatalogEntryNodeEdge"
      });

      cache.writeQuery({
        query: GET_CATALOG_ENTRIES,
        variables: { productid: product },
        data
      });
    }
  });

  const [updateEntry] = useMutation(UPDATE_ENTRY);

  React.useEffect(() => {
    if (selectedEntry) {
      setEntry({
        ...selectedEntry,
        features: selectedEntry.packageentry.edges.map(({ node: feature }) => {
          return {
            value: feature.featureid,
            name: getFeatureName(feature.featureid)
          };
        }),
        entrytype: selectedEntry.entrytype.name === "ITEM" ? 1 : 2
      });
      setType("edit");
    }
    if (!selectedEntry) {
      const combined = [
        ...allFeatures.edges.map(edge => {
          return edge.node;
        }),
        ...reservedFeatures.edges.map(edge => {
          return edge.node;
        })
      ]
        .filter(node => {
          if (!allEntries.includes(node.rowid)) {
            return node;
          }
        })
        .map(node => {
          return { value: node.id, text: node.name };
        });

      if (combined.length == 0) {
        setEntry({
          ...entry,
          entrytype: 2
        });
      }
      setFeatureList(combined);
    }
  }, []);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    setEntry({
      ...entry,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!entry.entrytype) {
      errors = {
        ...errors,
        entrytype: { status: true }
      };
      submitError = true;
    }
    if (!entry.featureid && featureList.length > 0 && entry.entrytype == 1) {
      errors = {
        ...errors,
        featureid: { status: true }
      };
      submitError = true;
    }

    if (!entry.name) {
      errors = {
        ...errors,
        name: { status: true }
      };
      submitError = true;
    }

    if (!entry.description) {
      errors = {
        ...errors,
        description: { status: true }
      };
      submitError = true;
    }
    if (!entry.sku) {
      errors = {
        ...errors,
        sku: { status: true }
      };
      submitError = true;
    }
    if (!entry.itemprice) {
      errors = {
        ...errors,
        itemprice: { status: true }
      };
      submitError = true;
    }

    if (entry.itemprice < 0 || entry.itemprice > 9999999) {
      errors = {
        ...errors,
        itemprice: { status: true }
      };
      submitError = true;
    }

    if (entry.features && entry.features.length === 0 && entry.entrytype == 2) {
      errors = {
        ...errors,
        features: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const getFeatureName = featureid => {
    const features = allFeatures.edges
      .filter(({ node }) => {
        return node.rowid === featureid && node.name;
      })
      .map(({ node }) => {
        return node.name;
      })
      .join();
    const reserved = reservedFeatures.edges
      .filter(({ node }) => {
        return node.rowid === featureid && node.name;
      })
      .map(({ node }) => {
        return node.name;
      })
      .join();
    return features ? features : reserved;
  };

  const handleCreate = () => {
    setLoading(true);
    if (type === "create") {
      createEntry({
        variables: {
          ...entry,
          features: JSON.stringify(
            entry.features.map(feature => {
              return feature.value;
            })
          )
        },
        optimisticResponse: {
          __typename: "Mutation",
          createEntry: {
            entry: {
              id: -1,
              ...entry,
              __typename: "CatalogEntryNode"
            },
            __typename: "createEntry"
          }
        }
      })
        .then(res => {
          console.log(res);
          onClose();
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    } else {
      updateEntry({
        variables: {
          ...entry,
          features: JSON.stringify(
            entry.features.map(feature => {
              return feature.value;
            })
          )
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateEntry: {
            entry: {
              ...entry,
              __typename: "CatalogEntryNode"
            },
            __typename: "updateEntry"
          }
        }
      })
        .then(res => {
          console.log(res);
          onClose();
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const addFeature = (value, event) => {
    setEntry({ ...entry, features: value });
    setError({
      ...error,
      features: { status: false }
    });
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      {!selectedEntry ? (
        <Select
          name="entrytype"
          onChange={updateField}
          value={entry.entrytype}
          label={"Select type of catalog item"}
          labelDescription={
            featureList.length === 0 &&
            `All current features have been uploaded. Packages with multiple features may still be created.`
          }
          placeholder={"Select type of catalog item"}
          errorMessage="Type of catalog is needed"
          invalid={error.entrytype.status}
          disabled={featureList.length === 0}
        >
          {featureList.length > 0 && <option value={1}>Individual</option>}
          <option value={2}>Package</option>
        </Select>
      ) : (
        <Spacing bottom={4}>
          <Text large bold>
            {entry.entrytype == 1 ? "Individual" : "Package"}
          </Text>
          <Text bold>{getFeatureName(entry.featureid)}</Text>
        </Spacing>
      )}
      {entry.entrytype == 1 && featureList.length > 0 && (
        <Select
          name="featureid"
          onChange={updateField}
          value={entry.featureid}
          label={"Select a feature"}
          placeholder={"Select a feature"}
          errorMessage="A feature is needed"
          invalid={error.featureid.status}
          noSpacing={featureList.length === 0}
        >
          {featureList.map(feature => {
            return (
              <option key={feature.value} value={feature.value}>
                {feature.text}
              </option>
            );
          })}
        </Select>
      )}
      {!selectedEntry && entry.entrytype == 2 && (
        <Multicomplete
          accessibilityLabel="Add features to package"
          label="Add features to package"
          name="features"
          errorMessage="One feature needs to be selected"
          invalid={error.features.status}
          loadItemsOnFocus
          showName
          onChange={addFeature}
          onSelectItem={() => {
            console.log("selected feature");
          }}
          onLoadItems={value =>
            Promise.resolve(
              [
                ...allFeatures.edges.map(edge => {
                  return edge.node;
                }),
                ...reservedFeatures.edges.map(edge => {
                  return edge.node;
                })
              ]
                .map(node => {
                  return { value: node.id, name: node.name };
                })
                .filter(item =>
                  item.name.toLowerCase().match(value.toLowerCase())
                )
            )
          }
          renderItem={(item, highlighted, selected) => (
            <Text bold={selected}>{item.name}</Text>
          )}
        />
      )}
      <Input
        name="name"
        onChange={updateField}
        value={entry.name}
        placeholder="Blinking Light"
        label="Name"
        errorMessage="A name is needed"
        invalid={error.name.status}
      />
      <TextArea
        name="description"
        onChange={updateField}
        value={entry.description}
        placeholder="Add a description"
        label="Description"
        errorMessage="A description is needed"
        invalid={error.description.status}
      />
      <Input
        name="sku"
        onChange={updateField}
        value={entry.sku}
        placeholder="BL001"
        label="SKU"
        errorMessage="A sku is needed"
        invalid={error.sku.status}
      />
      <Input
        type="number"
        step="0.01"
        name="itemprice"
        onChange={updateField}
        value={entry.itemprice}
        placeholder="4.99"
        label="Price"
        errorMessage="Price is missing or to low or to high"
        invalid={error.itemprice.status}
        min="0"
      />
      <Input
        name="imageurl"
        onChange={updateField}
        value={entry.imageurl}
        placeholder="https://imageurl.location"
        label="Image Url"
        errorMessage="A image url is needed"
        invalid={error.imageurl.status}
        optional
      />
      <Input
        name="thumbnailurl"
        onChange={updateField}
        value={entry.thumbnailurl}
        placeholder="https://thumbnailurl.location"
        label="Thumbnail URL"
        errorMessage="A thumbnail url is needed"
        invalid={error.thumbnailurl.status}
        optional
      />

      <FormActions
        continueText={`${type === "create" ? `Add` : `Update`} ${
          entry.entrytype == 1 ? "Individual Entry" : "Package"
        }`}
        processingText={type === "create" ? `Adding...` : `Updating...`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
