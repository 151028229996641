import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import _pt from "prop-types";
import useStyles from "../../../core/src/hooks/useStyles";
import Spacing from "../../../core/src/components/Spacing";
import Grid, { Col } from "../../../core/src/components/Grid/index";
import buildDocumentStyles from "../../../core/src/themes/buildDocumentStyles";
import CodeBlock from "./CodeBlock";
import Heading from "./Heading";
import Links from "./Links";
import ScrollMenu from "./scrollMenu";
import {
  createSlug,
  isOutOfViewport,
  isAtTopOfViewport,
  isAtBottomOfViewport
} from "./helpers";

const styleSheet = ({ ...props }) => {
  const styles = buildDocumentStyles(props);
  return {
    ...styles,
    scroll_menu: {
      position: "fixed",
      width: "inherit",
      pointerEvents: "none",
      opacity: 0,
      top: props.unit * 5,
      transition: `all .2s ease-out`
    },
    scroll_menu_active: {
      opacity: 1
    },
    scroll_menu_title: {
      marginBottom: props.unit
    }
  };
};

const Document = ({ md }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [markdown, setMarkdown] = React.useState("");
  const [isOut, setIsOut] = React.useState(true);
  const [isMounted, setMounted] = React.useState(false);

  const checkIsOut = () => {
    const element = document.getElementById("contentMenu");
    const out = element && isOutOfViewport(element);
    out && out.any ? setIsOut(false) : setIsOut(true);
  };

  const createTOCHeadlines = ({ children, ...props }) => {
    const toc = children
      .filter(({ props: { level, children } }) => {
        if (level === 2) {
          console.log(children);
          return children;
        }
      })
      .map(({ props }) => {
        return props.children[0].props.value;
      });
    return <ScrollMenu headings={toc} />;
  };

  const listener = () => {
    checkIsOut();
  };

  React.useEffect(() => {
    fetch(md)
      .then(res => res.text())
      .then(text => setMarkdown(text));
    window.addEventListener("scroll", listener, true);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <Spacing vertical={8}>
      <Grid>
        <Col span={8}>
          <div className={cx(styles.content)}>
            <ReactMarkdown
              source={markdown}
              escapeHtml={false}
              renderers={{ code: CodeBlock, link: Links, heading: Heading }}
            />
          </div>
        </Col>
        <Col span={3} offset={1}>
          <div
            className={cx(
              styles.scroll_menu,
              !isOut && styles.scroll_menu_active
            )}
          >
            <h5 className={cx(styles.scroll_menu_title)}>On this page</h5>
            <ReactMarkdown
              source={markdown}
              escapeHtml={false}
              allowedTypes={["text", "heading"]}
              renderers={{ root: createTOCHeadlines }}
            />
          </div>
        </Col>
      </Grid>
    </Spacing>
  );
};

Document.propTypes = {};

Document.defaultProps = {};

export default Document;
