import React from "react";
import _pt from "prop-types";
import { matchPath, withRouter } from "react-router-dom";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import RouterNavLink from "../../core/src/components/RouterNavLink";
import Spacing from "../../core/src/components/Spacing";
import { Item } from "../../core/src/components/List";
import Text from "../../core/src/components/Text";

const styleSheet = ({ aside, color }) => ({
  flex: {
    display: "flex"
  },
  justify_center: {
    justifyContent: "center"
  },
  items_center: {
    alignItems: "center"
  },
  icon: {
    width: 16,
    height: 16
  },
  item: {
    backgroundColor: "transparent",
    ":hover": {
      backgroundColor: aside.backgroundHover
    }
  },
  item_active: {
    backgroundColor: aside.backgroundHover
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    display: "inline-block",
    width: "100%"
  }
});

const MenuItem = ({ name, to, location, noRoute, icon, ...props }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const isActive = matchPath(location.pathname, { path: to });

  const Color = {
    color: isActive ? theme.color.core.neutral[5] : theme.color.core.neutral[3]
  };

  return (
    <Item>
      <div className={cx(styles.item, isActive && styles.item_active)}>
        {noRoute ? (
          <a href={to} target="_blank" className={cx(styles.link)}>
            <Spacing left={3} vertical={2} right={6}>
              <div className={cx(styles.flex, styles.items_center)}>
                <Text bold>
                  <div className={cx(Color)}>{name}</div>
                </Text>
              </div>
            </Spacing>
          </a>
        ) : (
          <RouterNavLink to={to}>
            <Spacing left={3} vertical={2} right={6}>
              <div className={cx(styles.flex, styles.items_center)}>
                <Text bold>
                  <div className={cx(Color)}>{name}</div>
                </Text>
              </div>
            </Spacing>
          </RouterNavLink>
        )}
      </div>
    </Item>
  );
};

MenuItem.propTypes = {
  name: _pt.string.isRequired,
  to: _pt.string.isRequired,
  icon: _pt.node,
  location: _pt.object.isRequired
};

MenuItem.defaultProps = {
  icon: null
};

export default withRouter(MenuItem);
