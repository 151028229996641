import React from 'react';

function renderElementOrFunction(prop, value) {
  if (!prop) {
    return null;
  }

  const element = typeof prop === 'function' ? prop(value) : prop;

  if (React.isValidElement(element)) {
    return element;
  }

  return null;
}

export default renderElementOrFunction;
