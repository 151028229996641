import gql from "graphql-tag";

const CONFIRM_RESET_USER_PASSWORD = gql`
  mutation confirmResetUserPassword($token: String!, $password: String!) {
    confirmResetUserPassword(token: $token, password: $password) {
      success
    }
  }
`;

const VERIFY_RESET_PASSWORD = gql`
  mutation verifyResetPassword($token: String!) {
    verifyResetPassword(token: $token) {
      success
    }
  }
`;

export { CONFIRM_RESET_USER_PASSWORD, VERIFY_RESET_PASSWORD };
