import React from "react";
import _pt from "prop-types";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import Spacing from "../../../../../../core/src/components/Spacing";
import Text from "../../../../../../core/src/components/Text";
import List, { Item } from "./List";
import Price from "../../../../../../core/src/components/Price/index";
import ResponsiveImage from "../../../../../../core/src/components/ResponsiveImage";

const styleSheet = ({ color, unit }) => ({
  options_wrapper: {
    position: "relative",
    width: "16px"
  },
  options: {
    position: "absolute",
    zIndex: "50"
  }
});

const Entries = ({
  product,
  allProductEntries,
  allProductFeatures,
  allReservedFeatures
}) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <List>
      {allProductEntries &&
        allProductEntries.edges.map(({ node: entry }) => {
          console.log(entry);
          return (
            <Item
              entry={entry}
              product={product}
              key={entry.id}
              features={allProductFeatures}
              reservedFeatures={allReservedFeatures}
            >
              <div className={cx({ display: "flex", alignItems: "center" })}>
                {entry.thumbnailurl !== "" && (
                  <Spacing right={2}>
                    <ResponsiveImage
                      cover
                      noShadow
                      alt="Image"
                      maxHeight={30}
                      maxWidth={30}
                      src={entry.thumbnailurl}
                    />
                  </Spacing>
                )}
                <div>
                  <Text bold>{entry.name}</Text>
                  <Text>
                    {entry.entrytype.name === "ITEM"
                      ? "Individual"
                      : " Package"}
                  </Text>
                </div>
                <div
                  className={cx({
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-end"
                  })}
                >
                  <Spacing right={4}>
                    <Text bold>
                      {entry.itemprice === "0.00" ? (
                        "Free"
                      ) : (
                        <Price amount={entry.itemprice} />
                      )}
                    </Text>
                  </Spacing>
                </div>
              </div>
            </Item>
          );
        })}
    </List>
  );
};

Entries.propTypes = {};

Entries.defaultProps = {};

export default Entries;
