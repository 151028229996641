import React from "react";
import _pt from "prop-types";
import Core from "../../../src";

export default class Translate extends React.PureComponent {
  static defaultProps = {
    html: false
  };

  static phrase(phrase, params, options) {
    return Core.translate(phrase, params, options);
  }

  render() {
    const { translatorComponent: Translator } = Core.settings;
    const { children, k: key, phrase, context, html, ...params } = this.props;
    const options = { context, html, key };

    if (!Translator) {
      return <span>{Core.translate(phrase, params, options)}</span>;
    }

    return (
      <Translator
        k={key}
        phrase={phrase}
        context={context}
        html={html}
        {...params}
      />
    );
  }
}

Translate.propTypes = {
  children: _pt.any,
  k: _pt.any,
  phrase: _pt.any,
  context: _pt.any,
  html: _pt.any
};

Translate.defaultProps = {
  children: null,
  context: null,
  k: null,
  phrase: null
};
