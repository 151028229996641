import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { GET_CATALOG_ENTRIES, DESTROY_ENTRY } from "./queries";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import Card, {
  Content as CardContent
} from "../../../../../../core/src/components/Card";
import MenuToggle from "../../../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../../icons/src/interface/IconMenuDots";
import history from "../../../../../../history";
import Modal from "../../../../../../core/src/components/Modal";
import Form from "../Form";
import { getRolePermissions } from "../../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  options: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    paddingRight: unit,
    display: "flex",
    alignItems: "center"
  }
});

const Item = ({ entry, children, product, features, reservedFeatures }) => {
  const permissions = getRolePermissions("sales");
  const [styles, cx] = useStyles(styleSheet);
  const [visable, setVisable] = React.useState(false);
  const [destroy] = useMutation(DESTROY_ENTRY, {
    update(
      cache,
      {
        data: { destroyCatalogItem }
      }
    ) {
      const data = cache.readQuery({
        query: GET_CATALOG_ENTRIES,
        variables: { productid: product }
      });

      data.allProductEntries.edges = data.allProductEntries.edges.filter(
        edge => edge.node.id !== entry.id
      );

      cache.writeQuery({
        query: GET_CATALOG_ENTRIES,
        variables: { productid: product },
        data
      });
    }
  });

  const removeEntry = () => {
    permissions.catalog.includes("DELETE") &&
      destroy({
        variables: {
          id: entry.id
        },
        optimisticResponse: {
          __typename: "Mutation",
          destroyCatalogItem: {
            entry: {
              id: entry.id,
              __typename: "CatalogEntryNode"
            },
            __typename: "destroyCatalogItem"
          }
        }
      });
  };

  return (
    <div>
      <Card overflow>
        <div className={cx(styles.options)}>
          {(permissions.catalog.includes("DELETE") ||
            permissions.catalog.includes("UPDATE")) && (
            <MenuToggle
              accessibilityLabel="Actions"
              closeOnClick
              zIndex={50}
              toggleIcon={<IconMenuDots decorative />}
              toggleLabel="Actions"
            >
              <MenuItem
                href={`/sales/catalog/products/${product}/items/${entry.id}`}
              >
                View
              </MenuItem>
              {permissions.catalog.includes("UPDATE") && (
                <MenuItem onClick={() => setVisable(true)}>Edit</MenuItem>
              )}
              {permissions.catalog.includes("DELETE") && (
                <MenuItem onClick={removeEntry}>Remove {entry.name}</MenuItem>
              )}
            </MenuToggle>
          )}
        </div>
        <CardContent
          compact
          middleAlign
          truncated
          onClick={() => {
            history.push(
              `/sales/catalog/products/${product}/items/${entry.id}`
            );
          }}
        >
          {children}
        </CardContent>
      </Card>
      {permissions.catalog.includes("UPDATE") && visable && (
        <Modal title={`Edit ${entry.name}`} onClose={() => setVisable(false)}>
          <Form
            onClose={() => setVisable(false)}
            selectedEntry={entry}
            allFeatures={features}
            reservedFeatures={reservedFeatures}
          />
        </Modal>
      )}
    </div>
  );
};

export default Item;
