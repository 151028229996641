import React from "react";
import _pt from "prop-types";
import useStyles from "../../../core/src/hooks/useStyles";
import Text from "../../../core/src/components/Text/index";
import Spacing from "../../../core/src/components/Spacing";

const styleSheet = ({ color, unit }) => ({});

const NRFConnectOverview = ({}) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <>
      <Text bold large>
        Description
      </Text>
      <Spacing top={2}>
        <Text>
          The nRF tool allows you to access devices and query information
          remotely providing they are connected to a gateway or have and LTE-m
          connection.
        </Text>
      </Spacing>
    </>
  );
};

NRFConnectOverview.propTypes = {};

NRFConnectOverview.defaultProps = {};

export default NRFConnectOverview;
