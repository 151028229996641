import React from "react";
import _pt from "prop-types";
import axios from "axios";
import useStyles from "../../../core/src/hooks/useStyles";
import Spacing from "../../../core/src/components/Spacing";
import Text from "../../../core/src/components/Text";
import List, { Item } from "../../../core/src/components/List";
import Tool from "../Tool";
import Button from "../../../core/src/components/Button";
import IconDownload from "../../../icons/src/interface/IconDownload";
import Row from "../../../core/src/components/Row";

const styleSheet = ({ color, unit }) => ({});

const CommandLineTool = () => {
  const [styles, cx] = useStyles(styleSheet);
  const [loading, setLoading] = React.useState({
    embedded: false,
    android: false,
    ios: false
  });

  return (
    <Tool
      header={{ title: "Obvious Utility CLI" }}
      sidebar={
        <Spacing>
          <Text bold>
            <Row center>
              <IconDownload decorative size={16} />
              <Spacing left={1}>Download</Spacing>
            </Row>
          </Text>
          <Spacing vertical={4}>
            <List>
              <Item>
                <Spacing bottom={1}>
                  <Button
                    block
                    href="/static/tools/ObvUtil001.004.000.dmg"
                    download
                    external
                  >
                    MacOS
                  </Button>
                </Spacing>
              </Item>
              <Item>
                <Spacing bottom={1}>
                  <Button
                    block
                    href="/static/tools/ObvUtil001.004.000.exe"
                    download
                    external
                  >
                    Windows
                  </Button>
                </Spacing>
              </Item>
              <Item>
                <Spacing bottom={1}>
                  <Button
                    block
                    href="/static/tools/ObvUtil001.004.000.tar.bz2"
                    download
                    external
                  >
                    Linux
                  </Button>
                </Spacing>
              </Item>
            </List>
          </Spacing>
        </Spacing>
      }
    >
      <Text bold large>
        Description
      </Text>
      <Spacing top={2}>
        <Text>
          The Obvious Utility command line tool can be used to generate an
          encrypted firmware package, generate DFU settings for a firmware
          package, and initialize an Obvious-enabled device with its product
          key. The initialization and DFU settings can be output to a hex file,
          or programmed directly via SEGGER. This tool requires the J-Link
          Software and Documentation Pack.
        </Text>
      </Spacing>
    </Tool>
  );
};

CommandLineTool.propTypes = {};

CommandLineTool.defaultProps = {};

export default CommandLineTool;
