import React from "react";
import _pt from "prop-types";
import useTheme from "../../core/src/hooks/useTheme";
import useStyles from "../../core/src/hooks/useStyles";
import Aside from "../../layout/src/components/Aside";
import Spacing from "../../core/src/components/Spacing";
import IconObviousLogo from "../../icons/src/interface/IconObviousLogo";
import List, { Item } from "../../core/src/components/List";
import Link from "../../core/src/components/Link";
import AccountDropdown from "../AccountDropdown";
import Title from "../../core/src/components/Title";

const styleSheet = () => ({
  sidebar: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  menu: {
    flexGrow: 1
  }
});

const Sidebar = ({ children, loading, color, title }) => {
  const theme = useTheme();
  const [styles, cx] = useStyles(styleSheet);

  return (
    <Aside
      width={256}
      color={color === "light" ? "#ffffff" : " #000000"}
      noPadding
    >
      {!loading && (
        <div className={cx(styles.sidebar)}>
          {/* <Spacing left={2} right={4} top={4} bottom={4}>
            <Title level={3}>{title}</Title>
          </Spacing> */}
          <div className={cx(styles.menu)}>{children}</div>
          {/* <AccountDropdown color={color} /> */}
          {/* <Spacing all={4}>
            <List horizontal center>
              <Item spacious>
                <Link small href="/docs">
                  Docs
                </Link>
              </Item>
              <Item spacious>
                <Link small href="/resources">
                  Resources
                </Link>
              </Item>
              <Item spacious>
                <Link small href="/support">
                  Support
                </Link>
              </Item>
            </List>
          </Spacing> */}
        </div>
      )}
    </Aside>
  );
};

Sidebar.propTypes = {
  children: _pt.node,
  color: _pt.oneOf(["light", "dark"])
};

Sidebar.defaultProps = {
  children: null,
  color: "dark"
};

export default Sidebar;
