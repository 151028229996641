import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { DESTROY_FIRMWARE, GET_FIRMWARES } from "../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Row from "../../../../../core/src/components/Row";
import MenuToggle from "../../../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../icons/src/interface/IconMenuDots";
import FormActions from "../../../../../core/src/components/FormActions";
import Modal from "../../../../../core/src/components/Modal";
import FirmwareForm from "../FirmwareForm";
import { convertIntegerToVersion } from "../../../../../helpers";
import { getRolePermissions } from "../../../../../helpers";

const styleSheet = ({ unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  },
  options_items_max: {
    width: 100,
    display: "flex",
    justifyContent: "end",
    marginRight: unit * 4
  }
});

const Firmware = ({ children, firmware, product }) => {
  const permissions = getRolePermissions("product");
  console.log(permissions.firmware);
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [destroyVisable, setDestroyVisable] = React.useState(false);
  const [editVisable, setEditVisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [destroy] = useMutation(DESTROY_FIRMWARE, {
    update(cache, { data: { destroyFirmware } }) {
      const data = cache.readQuery({
        query: GET_FIRMWARES,
        variables: { productid: product }
      });
      data.allFirmware.edges = data.allFirmware.edges.filter(
        edge => edge.node.id !== firmware.id
      );

      cache.writeQuery({
        query: GET_FIRMWARES,
        variables: { productid: product },
        data
      });
    }
  });

  const destroyFirmware = () => {
    if (permissions.firmware.includes("DELETE")) {
      setLoading(true);
      destroy({
        variables: {
          productid: product,
          id: firmware.id
        },
        optimisticResponse: {
          __typename: "Mutation",
          destroyFirmware: {
            firmware: {
              id: firmware.id,
              __typename: "FirmwareNode"
            },
            __typename: "destroyFirmware"
          }
        }
      });
      setDestroyVisable(false);
    }
  };

  const handleHide = () => {};
  const handleShow = () => {};

  console.log(firmware.minverfirmware);

  return (
    <div className={cx(styles.feature)}>
      <Row center>
        <div className={cx({ flexGrow: 1 })}>
          <Link block to={`/products/${product}/firmware/${firmware.id}`}>
            {children}
          </Link>
        </div>
        <div className={cx(styles.options)}>
          <div className={cx(styles.options_items_max)}>
            {convertIntegerToVersion(firmware.minverfirmware)}
          </div>
          <div className={cx(styles.options_items_max)}>
            {convertIntegerToVersion(firmware.maxverfirmware)}
          </div>
          {(permissions.firmware.includes("UPDATE") ||
            permissions.firmware.includes("DELETE")) && (
            <MenuToggle
              closeOnClick
              accessibilityLabel="Actions"
              toggleIcon={
                <IconMenuDots decorative color={theme.color.core.neutral[4]} />
              }
              toggleLabel="Actions"
              zIndex={10}
              onShow={handleShow}
              onHide={handleHide}
            >
              <MenuItem href={`/products/${product}/firmware/${firmware.id}`}>
                View
              </MenuItem>
              {permissions.firmware.includes("UPDATE") && (
                <MenuItem onClick={() => setEditVisable(true)}>Edit</MenuItem>
              )}
              {permissions.firmware.includes("DELETE") && (
                <MenuItem danger onClick={() => setDestroyVisable(true)}>
                  Delete {firmware.version}
                </MenuItem>
              )}
            </MenuToggle>
          )}
        </div>
      </Row>
      {permissions.firmware.includes("DELETE") && destroyVisable && (
        <Modal
          title={`Delete ${firmware.version}`}
          subtitle={"Warning, this step cannot be undone"}
          onClose={() => setDestroyVisable(false)}
        >
          <FormActions
            continueText={`Yes, remove ${firmware.version}`}
            processingText="Removing..."
            processing={loading}
            onCancel={() => setDestroyVisable(false)}
            onContinue={destroyFirmware}
          />
        </Modal>
      )}
      {permissions.firmware.includes("UPDATE") && editVisable && (
        <Modal
          title={`Edit ${firmware.version}`}
          onClose={() => setEditVisable(false)}
        >
          <FirmwareForm
            product={product}
            selectedFirmware={firmware}
            onClose={() => setEditVisable(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Firmware;
