import React from "react";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_DEVICE } from "../queries";
import { GET_PRODUCT } from "../../../queries";
import useStyles from "../../../../../core/src/hooks/useStyles";
import PageHeader from "../../../../../components/PageHeader";
import LogoLoader from "../../../../../core/src/components/LogoLoader";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../../core/src/components/Breadcrumbs";
import Text from "../../../../../core/src/components/Text";
import FeaturesList, { Feature } from "./FeaturesList";
import Layout from "../../../../../layout/src/components/Layout";
import MainMenu from "../../../../../components/MainMenu";
import Content from "../../../../../components/Content";
import ProfilePhoto from "../../../../../core/src/components/ProfilePhoto";
import DirectionalIcon from "../../../../../core/src/components/DirectionalIcon";
import IconChevronRight from "../../../../../icons/src/interface/IconChevronRight";
import IconChevronLeft from "../../../../../icons/src/interface/IconChevronLeft";
import Spacing from "../../../../../core/src/components/Spacing";
import { getInitials } from "../../../../../helpers";
import Link from "../../../../../core/src/components/Link";
import Title from "../../../../../core/src/components/Title";
import ProductBreadcrumb from "../../../../../components/ProductBreadcrumb/index";
import TopMenu from "../../../../../components/TopMenu";

const styleSheet = ({ unit }) => ({});

const Device = ({ match }) => {
  console.log(match);
  const [styles, cx] = useStyles(styleSheet);
  const [productID] = React.useState(match.params.product);
  const [deviceID] = React.useState(match.params.device);
  console.log(productID, deviceID);

  const [features, setFeatures] = React.useState([]);
  const { loading, data } = useQuery(GET_DEVICE, {
    variables: { productid: productID, deviceid: deviceID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const { loading: productLoading, data: productData } = useQuery(GET_PRODUCT, {
    variables: { id: productID },
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  React.useEffect(() => {
    if (
      data &&
      data.device &&
      data.allProductFeatures &&
      data.allReservedFeatures &&
      !loading
    ) {
      const actives = [
        ...data.device.active.edges.map(edge => {
          return edge.node;
        })
      ];
      const features = [
        ...data.allProductFeatures.edges.map(edge => {
          return edge.node;
        }),
        ...data.allReservedFeatures.edges.map(edge => {
          return edge.node;
        })
      ]
        .map(feature => {
          const active = actives
            .filter(function(item) {
              if (item.featureid === feature.rowid) {
                return true;
              }
              return false;
            })
            .map(function(item) {
              return {
                expirydate: item.expirydate,
                activatedid: item.id
              };
            });
          return {
            ...feature,
            ...active[0]
          };
        })
        .sort(function(a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      setFeatures(features);
    }
  }, [data]);

  return (
    <Layout
      noPadding
      noMaxwidth
      relative
      before={
        <div className={cx(styles.flex)}>
          <MainMenu />
        </div>
      }
    >
      <Content>
        <TopMenu />
        {loading ? (
          <LogoLoader />
        ) : (
          <>
            <ProductBreadcrumb product={productData.product}>
              <Breadcrumbs center accessibilityLabel="Breadcrumb">
                <Breadcrumb
                  highlighted
                  label="Devices"
                  href={`/products/${productID}/devices`}
                />
                <Breadcrumb hideIcon label={data.device.serialnumber} />
              </Breadcrumbs>
            </ProductBreadcrumb>
            <Spacing vertical="2">
              <Title level={2}>{data.device.serialnumber}</Title>
            </Spacing>
            <FeaturesList>
              {features &&
                features.map(feature => (
                  <Feature
                    key={feature.id}
                    feature={feature}
                    product={productID}
                    device={deviceID}
                  >
                    <Text bold>{feature.name}</Text>
                    <Text small>{feature.description}</Text>
                  </Feature>
                ))}
            </FeaturesList>
          </>
        )}
        ;
      </Content>
    </Layout>
  );
};

Device.propTypes = {};

Device.defaultProps = {};

export default withRouter(Device);
