import focusableSelector from '../focusableSelector';

const getFirstFocusableChild = node => {
  const firstFocusableElement = node.querySelector(focusableSelector);

  if (firstFocusableElement !== null) {
    return firstFocusableElement;
  }

  return node;
};

export default getFirstFocusableChild;
