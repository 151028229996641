import React from "react";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import Feature from "./Feature";
import Text from "../../../../../../core/src/components/Text";
import Row from "../../../../../../core/src/components/Row";
import { getRolePermissions } from "../../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  options: {
    width: 24
  }
});

const FeaturesList = ({ children }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className="">
      <div className={cx(styles.header)}>
        <Row
          after={
            <div className={cx(styles.header_children)}>
              <div className={cx(styles.header_items)}>
                <Text small>Status</Text>
              </div>
              {(permissions.devices.includes("UPDATE") ||
                permissions.devices.includes("DELETE")) && (
                <div className={cx(styles.options)} />
              )}
            </div>
          }
        >
          <Text small>Name & Description</Text>
        </Row>
      </div>
      {children}
    </div>
  );
};

export { Feature };
export default FeaturesList;
