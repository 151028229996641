import React from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_TRANSACTIONS } from "./queries";
import useStyles from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import PageHeader from "../../../components/PageHeader";
import LogoLoader from "../../../core/src/components/LogoLoader";
import TransactionList, { Transaction } from "./TransactionList/index";
import Spacing from "../../../core/src/components/Spacing";
import Price from "../../../core/src/components/Price";
import Text from "../../../core/src/components/Text";
import DateTime from "../../../core/src/components/DateTime";
import StatusText from "../../../core/src/components/StatusText";
import Tooltip from "../../../core/src/components/Tooltip";
import Button from "../../../core/src/components/Button";
import Title from "../../../core/src/components/Title";
import { toLocalNumber } from "../../../helpers";
import IconSearch from "../../../icons/src/interface/IconSearch";
import Input from "../../../core/src/components/Input";
import AppLoader from "../../../core/src/components/AppLoader";
import Row from "../../../core/src/components/Row";

const styleSheet = ({ color, unit }) => ({
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  search_icon: {
    marginRight: unit
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  date: {
    width: 184,
    display: "flex",
    justifyContent: "flex-end"
  },
  product: {
    width: 128
  },
  price: {
    width: 200,
    textAlign: "center"
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 80
  },
  status_circle: {
    width: unit,
    height: unit,
    borderRadius: "100%",
    marginRight: unit
  },
  more: {
    paddingTop: unit * 4,
    paddingBottom: unit * 4,
    display: "flex",
    justifyContent: "center"
  }
});

const Transactions = ({ match, location }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const status = [
    {
      color: theme.color.core.secondary[3],
      name: "Started",
      description: "The transaction was started"
    },
    {
      color: theme.color.core.primary[3],
      name: "Paying",
      description: "The transaction is being sent to payment system"
    },
    {
      color: theme.color.core.success[3],
      name: "Success",
      description: "The transaction was completed successfully"
    },
    {
      color: theme.color.core.danger[3],
      name: "Failed",
      description: "The transaction failed"
    },
    {
      color: theme.color.core.warning[3],
      name: "Pending",
      description: "The transaction was submitted and is PENDING processing"
    }
  ];

  const { loading: transactionLoading, data, fetchMore } = useQuery(
    GET_TRANSACTIONS,
    {
      variables: { serialnumber: search },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const searchChange = value => {
    setSearch(value);
  };

  return (
    <div>
      <Spacing bottom={2}>
        <Row
          center
          before={
            !transactionLoading && (
              <Text bold>
                {`${data &&
                  toLocalNumber(
                    data.allTransactions.totalCount
                  )} transactions`}{" "}
                {search && `serial number starting with ${search}`}
              </Text>
            )
          }
          after={
            <div className={cx({ display: "flex", alignContent: "center" })}>
              <div className={cx(styles.search)}>
                <IconSearch
                  className={cx(styles.search_icon)}
                  accessibilityLabel="search"
                  width={32}
                  height={32}
                  color={theme.color.core.neutral[3]}
                />
                <Input
                  label="search"
                  hideLabel
                  autoComplete="off"
                  name="search"
                  value={search}
                  onChange={searchChange}
                  placeholder="find serial number"
                  type="search"
                />
              </div>
            </div>
          }
        ></Row>
      </Spacing>
      <AppLoader
        fetched={!transactionLoading}
        centered
        small
        loadingText={
          search
            ? `Fetching transactions with a serial number that start with ${search}`
            : "Fetching transactions"
        }
        failureText="Failed to load transactions"
      >
        <TransactionList>
          {data &&
            data.allTransactions &&
            data.allTransactions.edges.map(({ node: transaction }) => {
              return (
                <Transaction key={transaction.id} transaction={transaction}>
                  <div className={cx(styles.row)}>
                    <div className={cx({ flexGrow: 1 })}>
                      <Text bold>{transaction.serialnumber}</Text>
                      <Text small>{transaction.rowid} </Text>
                    </div>
                    <div className={cx(styles.product)}>
                      <Text truncated>{transaction.product}</Text>
                    </div>
                    <div className={cx(styles.price)}>
                      <Text bold>
                        {transaction.transactionItemsCount} Items for{" "}
                        <Price amount={transaction.transactiontotal} />
                      </Text>
                    </div>
                    <Spacing horizontal={4}>
                      <Tooltip
                        content={
                          status[transaction.transactionstatus].description
                        }
                      >
                        <div className={cx(styles.status)}>
                          <div
                            className={cx(styles.status_circle, {
                              backgroundColor:
                                status[transaction.transactionstatus].color
                            })}
                          ></div>
                          <Text>
                            {status[transaction.transactionstatus].name}
                          </Text>
                        </div>
                      </Tooltip>
                    </Spacing>
                    <div className={cx(styles.date)}>
                      <DateTime
                        at={new Date(transaction.datecreated).getTime()}
                        short
                      />
                    </div>
                  </div>
                </Transaction>
              );
            })}
        </TransactionList>
        {data &&
          data.allTransactions &&
          data.allTransactions.pageInfo.hasNextPage && (
            <div className={cx(styles.more)}>
              <Button
                loading={loading}
                onClick={() => {
                  setLoading(true);
                  fetchMore({
                    query: GET_TRANSACTIONS,
                    variables: {
                      after:
                        data &&
                        data.allTransactions &&
                        data.allTransactions.pageInfo.endCursor
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      const newEdges = fetchMoreResult.allTransactions.edges;
                      const pageInfo = fetchMoreResult.allTransactions.pageInfo;
                      setLoading(false);
                      return newEdges.length
                        ? {
                            // Put the new comments at the end of the list and update `pageInfo`
                            // so we have the new `endCursor` and `hasNextPage` values
                            allTransactions: {
                              __typename:
                                previousResult.allTransactions.__typename,
                              edges: [
                                ...previousResult.allTransactions.edges,
                                ...newEdges
                              ],
                              pageInfo
                            }
                          }
                        : previousResult;
                    }
                  });
                }}
              >
                Load More
              </Button>
            </div>
          )}
      </AppLoader>
    </div>
  );
};

export default withRouter(Transactions);
