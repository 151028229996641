import React from 'react';
import _pt from 'prop-types';
import withIcon from '../withIcon';
import useTheme from '../../../core/src/hooks/useTheme';

const IconDiode = ({ firstColor, secondColor, ...props }) => {
  const theme = useTheme();

  return (
    <svg className="fill-current" {...props} viewBox="0 0 14 20">
      <path
        style={{ color: firstColor ? firstColor : theme.color.core.primary[3] }}
        d="M12.29 11.818h-2.272V9.545c0-.272-.182-.454-.455-.454h-.909L9.472 8a.489.489 0 0 0-.09-.636.489.489 0 0 0-.637.09L7.381 9.274c-.09.136-.136.318-.045.454.09.137.227.273.409.273h1.364v1.818H4.563V8.182h1.818l-.818 1.09c-.136.183-.09.5.091.637.182.136.5.091.636-.09L7.654 8c.091-.136.136-.318.046-.455-.091-.136-.228-.272-.41-.272H4.11c-.273 0-.455.182-.455.454v4.091c-.273 0-.454.182-.454.455 0 .272.181.454.454.454h8.636c.273 0 .455.182.455.455 0 .272-.182.454-.455.454H1.381c-.272 0-.454-.182-.454-.454 0-.273.182-.455.454-.455h.455c.454 0 .454-.409.454-.727V5.455c0-2.5 2.046-4.546 4.546-4.546 2.5 0 4.545 2.046 4.545 4.546v5c0 .272.182.454.455.454s.454-.182.454-.454v-5c0-3-2.454-5.455-5.454-5.455-3 0-5.455 2.455-5.455 5.455a18644748226675984 18644748226675984 0 0 1 0 9.09h10.91a1.34 1.34 0 0 0 1.363-1.363 1.34 1.34 0 0 0-1.364-1.364z"
      />
      <path
        style={{ color: secondColor ? secondColor : '#ffffff' }}
        d="M4.109 15.455c-.273 0-.455.181-.455.454v3.636c0 .273.182.455.455.455.272 0 .454-.182.454-.455V15.91c0-.273-.182-.454-.454-.454zm5.454 0c-.273 0-.454.181-.454.454v3.636c0 .273.182.455.454.455.273 0 .455-.182.455-.455V15.91c0-.273-.182-.454-.455-.454z"
      />
    </svg>
  );
};

IconDiode.propTypes = {
  firstColor: _pt.string,
  secondColor: _pt.string,
};

IconDiode.defaultProps = {
  firstColor: 'brand-teal',
  secondColor: 'brand-teal-light',
};

export default withIcon('IconDiode')(IconDiode);
