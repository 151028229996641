import React from 'react';
import withIcon from '../withIcon';

const IconChevronLeft = ({ ...props }) => {
  return React.createElement(
    'svg',
    {
      className: `fill-current`,
      ...props,
      viewBox: '0 0 24 24',
    },
    React.createElement('path', {
      d:
        'M15 15.875l-3.88-3.88L15 8.115a.996.996 0 1 0-1.41-1.41L9 11.295a.996.996 0 0 0 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42z',
    }),
  );
};

export default withIcon('IconChevronLeft')(IconChevronLeft);
