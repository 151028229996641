import React from "react";
import _pt from "prop-types";
import axios from "axios";
import useStyles from "../../../core/src/hooks/useStyles";
import Spacing from "../../../core/src/components/Spacing";
import Text from "../../../core/src/components/Text";
import List, { Item } from "../../../core/src/components/List";
import Tool from "../Tool";
import Button from "../../../core/src/components/Button";
import IconDownload from "../../../icons/src/interface/IconDownload";
import Row from "../../../core/src/components/Row";

const styleSheet = ({ color, unit }) => ({});

const SDK = () => {
  const [styles, cx] = useStyles(styleSheet);
  const [loading, setLoading] = React.useState({
    embedded: false,
    android: false,
    ios: false
  });

  const handleDownload = type => {
    setLoading({
      ...loading,
      [type]: true
    });
    axios({
      method: "GET",
      url: `/api/v1/products/mfgsdkdownload/`,
      responseType: "blob",
      params: {
        sdkformat: type
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ocelot-token")
      },
      data: {}
    }).then(response => {
      const attachmentName = response.request.getResponseHeader(
        "Content-Disposition"
      );
      const filename = attachmentName.split(/[""]/)[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        setLoading({
          ...loading,
          [type]: false
        });
      }, 1000);
    });
  };

  return (
    <Tool
      sidebar={
        <Spacing>
          <Text bold>
            <Row center>
              <IconDownload decorative size={16} />
              <Spacing left={1}>Download</Spacing>
            </Row>
          </Text>
          <Spacing vertical={4}>
            <List>
              <Item>
                <Spacing bottom={1}>
                  <Button
                    block
                    onClick={() => handleDownload("embedded")}
                    loading={loading.embedded}
                  >
                    Embedded
                  </Button>
                </Spacing>
              </Item>
              <Item>
                <Spacing bottom={1}>
                  <Button
                    block
                    onClick={() => handleDownload("android")}
                    loading={loading.android}
                  >
                    Android
                  </Button>
                </Spacing>
              </Item>
              <Item>
                <Spacing bottom={1}>
                  <Button
                    block
                    onClick={() => handleDownload("ios")}
                    loading={loading.ios}
                  >
                    iOS
                  </Button>
                </Spacing>
              </Item>
            </List>
          </Spacing>
        </Spacing>
      }
    >
      <Text bold large>
        Description
      </Text>
      <Spacing top={2}>
        <Text>
          The Product File Generator will build you the required Obvious files
          that correlate to your products and features for iOS, Android and your
          embedded devices. When adding new features to products the
          ocelot_feature.h file will have to be regenerated. When a new product
          is added to the portal, all three files will need to be regenerated
          for the new product line. Older product files do not need to be
          modified.
        </Text>
      </Spacing>
    </Tool>
  );
};

SDK.propTypes = {};

SDK.defaultProps = {};

export default SDK;
