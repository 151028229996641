import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import useStyles from "../../../core/src/hooks/useStyles";
import useTheme from "../../../core/src/hooks/useTheme";
import PageHeader from "../../../components/PageHeader";
import Spacing from "../../../core/src/components/Spacing";
import AdaptiveGrid from "../../../core/src/components/AdaptiveGrid/index";
import ReactPlayer from "react-player";
import Text from "../../../core/src/components/Text";
import Link from "../../../core/src/components/Link";
import Card, {
  Content as CardContent
} from "../../../core/src/components/Card";
import Button from "../../../core/src/components/Button";
import Grid, { Col } from "../../../core/src/components/Grid";

const styleSheet = ({ ...props }) => ({});

const Examples = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  return (
    <div>
      <PageHeader title="Examples" />
      <Spacing vertical={8}>
        <Spacing bottom={2}>
          <Card overflow>
            <CardContent truncated>
              <Text truncated large bold>
                <div
                  className={cx({
                    color: theme.color.core.primary[3]
                  })}
                >
                  Integrated nRF Embedded SDK Examples
                </div>
              </Text>

              <Spacing top={2}>
                <Text>
                  Several BLE peripheral examples from the Nordic embedded
                  nRF5_SDK_15.3.0 with obvious completely integrated.
                </Text>
              </Spacing>

              <Spacing top={2}>
                <Button
                  openInNewWindow
                  external
                  href="https://github.com/justaddobvious/integrated_nRF5_SDK_15.3.0_59ac345"
                >
                  Github
                </Button>
              </Spacing>
            </CardContent>
          </Card>
        </Spacing>
        <Spacing bottom={2}>
          <Card overflow>
            <CardContent truncated>
              <Text truncated large bold>
                <div
                  className={cx({
                    color: theme.color.core.primary[3]
                  })}
                >
                  ANDROID EXAMPLE
                </div>
              </Text>

              <Spacing top={2}>
                <Text>
                  A reference implementation for integrating the Obvious mobile
                  SDK into an Android project.
                </Text>
              </Spacing>

              <Spacing top={2}>
                <Button
                  openInNewWindow
                  external
                  href="https://github.com/justaddobvious/Android_obvious_example"
                >
                  Github
                </Button>
              </Spacing>
            </CardContent>
          </Card>
        </Spacing>
        <Spacing bottom={2}>
          <Card overflow>
            <CardContent truncated>
              <Text truncated large bold>
                <div
                  className={cx({
                    color: theme.color.core.primary[3]
                  })}
                >
                  IOS EXAMPLE
                </div>
              </Text>

              <Spacing top={2}>
                <Text>
                  A reference implementation for integrating the Obvious mobile
                  SDK into an iOS project.
                </Text>
              </Spacing>

              <Spacing top={2}>
                <Button
                  openInNewWindow
                  external
                  href="https://github.com/justaddobvious/iOS_obvious_example"
                >
                  Github
                </Button>
              </Spacing>
            </CardContent>
          </Card>
        </Spacing>
      </Spacing>
    </div>
  );
};

export default Examples;
