import _pt from "prop-types";
import { DateTime } from "luxon";

export const FontStyleBlock = {
  fontFamily: _pt.sting,
  fontWeight: _pt.string,
  fontSize: _pt.number,
  lineHeight: _pt.number,
  paddingTop: _pt.number,
  paddingBottom: _pt.number,
  letterSpacing: _pt.number
};

export const Size = _pt.oneOf([
  0,
  0.5,
  1,
  1.5,
  2,
  2.5,
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
  7.5,
  8,
  8.5,
  9,
  9.5,
  10,
  10.5,
  11,
  11.5,
  12
]);

export const Align = _pt.oneOf([
  "center",
  "start",
  "end",
  "baseline",
  "stretch",
  "start"
]);
export const Justify = _pt.oneOf([
  "start",
  "center",
  "end",
  "between",
  "around"
]);
export const Flex = _pt.oneOf(["initial", "1", "auto", "none"]);
export const FlexDirection = _pt.oneOf([
  "row",
  "row-reverse",
  "col",
  "col-reverse"
]);
export const FlexWrap = _pt.oneOf(["no-wrap", "wrap", "wrap-reverse"]);
export const ZIndex = _pt.oneOf([
  "auto",
  "z-10",
  "z-20",
  "z-30",
  "z-40",
  "z-50"
]);
export const MinWidth = _pt.oneOf(["0", "full"]);
export const MaxHeight = _pt.oneOf(["full", "screen"]);
export const MaxWidth = _pt.oneOf([
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "2xl",
  "3xl",
  "4xl",
  "5xl",
  "6xl",
  "full"
]);

export const BorderDirection = _pt.oneOf([
  "left",
  "right",
  "top",
  "bottom",
  "vertical",
  "horizontal"
]);

// MONEY

export const Currency = _pt.string;

export const Amount = _pt.shape({
  amount: _pt.number,
  amount_formatted: _pt.string,
  amount_micros: _pt.number,
  currency: _pt.string,
  is_micros_accuracy: _pt.bool
});

// DATETIME

export const TimeZone = _pt.string;
export const DateTimeType = _pt.oneOfType([
  _pt.string,
  _pt.number,
  _pt.instanceOf(Date),
  _pt.instanceOf(DateTime)
]);

export const PriceType = _pt.oneOf(["code", "name", "symbol"]);

// LOCALIZATION

export const Locale = _pt.oneOf([
  "de",
  "en",
  "es",
  "fr",
  "it",
  "ja",
  "ko",
  "ms",
  "nl",
  "pt",
  "ru",
  "tr",
  "zh"
]);

export const LocalDefinition = _pt.shape({
  locale: _pt.string,
  label: _pt.string,
  name: _pt.string
});

// THEME

export const Hexcode = _pt.string;

export const ColorRange = [
  Hexcode,
  Hexcode,
  Hexcode,
  Hexcode,
  Hexcode,
  Hexcode,
  Hexcode
];

export const Theme = {
  color: {
    accent: {
      bg: Hexcode,
      bgHover: Hexcode,
      bgError: Hexcode,
      border: Hexcode,
      borderActive: Hexcode,
      borderHover: Hexcode,
      borderError: Hexcode,
      text: Hexcode,
      textActive: Hexcode,
      textError: Hexcode
    },
    base: Hexcode,
    brand: {
      luxury: ColorRange,
      plus: ColorRange
    },
    core: {
      danger: ColorRange,
      neutral: ColorRange,
      primary: ColorRange,
      secondary: ColorRange,
      success: ColorRange,
      warning: ColorRange
    },
    clear: Hexcode,
    muted: Hexcode
  },
  font: {
    fontFamily: _pt.string,
    title1: FontStyleBlock,
    title2: FontStyleBlock,
    title3: FontStyleBlock,
    textLarge: FontStyleBlock,
    textRegular: FontStyleBlock,
    textSmall: FontStyleBlock,
    textMicro: FontStyleBlock,
    textReset: FontStyleBlock,
    weights: {
      thin: _pt.number,
      light: _pt.number,
      medium: _pt.number,
      semibold: _pt.number,
      bold: _pt.number,
      black: _pt.number
    }
  },
  breakpoints: {
    [_pt.string]: _pt.number
  },
  responsive: {
    large: _pt.string,
    medium: _pt.string,
    small: _pt.string,
    xsmall: _pt.string
  },
  transition: {
    box: _pt.StyleBlock,
    fade: _pt.StyleBlock
  },
  ui: {
    border: _pt.string,
    borderThick: _pt.string,
    borderRadius: _pt.number,
    borderRadiusThick: _pt.number,
    borderWidth: _pt.number,
    borderWidthThick: _pt.number,
    boxShadow: _pt.string,
    boxShadowMedium: _pt.string,
    boxShadowLarge: _pt.string,
    disabledOpacity: _pt.number,
    transitionTime: _pt.string
  },
  unit: _pt.number
};
