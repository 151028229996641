import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import useStyles from "../../../core/src/hooks/useStyles";
import PageHeader from "../../../components/PageHeader";
import Spacing from "../../../core/src/components/Spacing";
import AdaptiveGrid from "../../../core/src/components/AdaptiveGrid/index";
import ReactPlayer from "react-player";
import Text from "../../../core/src/components/Text";

const styleSheet = ({ ...props }) => ({
  player_wrapper: {
    position: "relative",
    paddingTop: "56.25%"
  },

  react_player: {
    position: "absolute",
    top: 0,
    left: 0
  }
});

const Video = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <div>
      <PageHeader
        title="Video"
        subtitle="Watch the whole integration process from start to finish for Obvious 1.2"
      />
      <Spacing vertical={8}>
        <AdaptiveGrid defaultItemsPerRow={2}>
          <Spacing bottom={1}>
            <Text bold large>
              Business Case
            </Text>
            <div className={cx(styles.player_wrapper)}>
              <ReactPlayer
                className={cx(styles.react_player)}
                url="https://www.youtube.com/watch?v=3aShpbpj5Y4"
                width="100%"
                height="100%"
              />
            </div>
          </Spacing>
          <Spacing bottom={1}>
            <Text bold large>
              Portal Walkthrough
            </Text>
            <div className={cx(styles.player_wrapper)}>
              <ReactPlayer
                className={cx(styles.react_player)}
                url="https://www.youtube.com/watch?v=c9AjavcNwGk"
                width="100%"
                height="100%"
              />
            </div>
          </Spacing>
          <Spacing bottom={1}>
            <Text bold large>
              Firmware
            </Text>

            <div className={cx(styles.player_wrapper)}>
              <ReactPlayer
                className={cx(styles.react_player)}
                url="https://www.youtube.com/watch?v=9sGNPKZ52kA"
                width="100%"
                height="100%"
              />
            </div>
          </Spacing>
          <Spacing bottom={1}>
            <Text bold large>
              Mobile
            </Text>
            <div className={cx(styles.player_wrapper)}>
              <ReactPlayer
                className={cx(styles.react_player)}
                url="https://www.youtube.com/watch?v=mVGzZfI5BXk"
                width="100%"
                height="100%"
              />
            </div>
          </Spacing>
        </AdaptiveGrid>
      </Spacing>
    </div>
  );
};

export default Video;
