import React from "react";
import useStyles from "../../../../core/src/hooks/useStyles";
import Row from "../../../../core/src/components/Row";

const styleSheet = ({ color, unit }) => ({
  row: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Feature = ({ children, feature, product, entry, catalogpackage }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className={cx(styles.row)}>
      <Row>{children}</Row>
    </div>
  );
};

export default Feature;
