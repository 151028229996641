import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import axios from "axios";
import useStyles from "../../../../../core/src/hooks/useStyles";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_DEVICE, GET_DEVICES } from "../queries";
import FormActions from "../../../../../core/src/components/FormActions";
import Input from "../../../../../core/src/components/Input";
import FileInput from "../../../../../core/src/components/FileInput";
import ToggleButtonController, {
  ButtonGroup
} from "../../../../../core/src/components/ToggleButtonController";
import Spacing from "../../../../../core/src/components/Spacing";
import Alert from "../../../../../core/src/components/Alert";
import { createLexer } from "graphql/language";
import Link from "../../../../../core/src/components/Link";

const styleSheet = ({ color, unit }) => ({});

const DeviceForm = ({ product, onClose, ...props }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [device, setDevice] = React.useState({
    serialnumber: "",
    key: ""
  });
  const [file, setFile] = React.useState();
  const [serverError, setServerError] = React.useState({
    status: false,
    msg: ""
  });
  const [error, setError] = React.useState({
    serialnumber: { status: false },
    file: { status: false },
    key: { status: false }
  });
  const [type, setType] = React.useState("single");

  const [createDevice] = useMutation(CREATE_DEVICE, {
    update(
      cache,
      {
        data: {
          createDevice: { device }
        }
      }
    ) {
      const data = cache.readQuery({
        query: GET_DEVICES,
        variables: { id: product }
      });
      data.allDevices.edges.push({
        node: { ...device },
        __typename: "FirmwareNodeEdge"
      });
      cache.writeQuery({
        query: GET_DEVICES,
        variables: { id: product },
        data
      });
    }
  });

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    setDevice({
      ...device,
      [event.target.name]: value
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};
    if (!device.serialnumber && type === "single") {
      errors = {
        ...errors,
        serialnumber: { status: true }
      };
      submitError = true;
    }

    if (!device.key && type === "single") {
      errors = {
        ...errors,
        key: { status: true }
      };
      submitError = true;
    }
    if (
      !/\b[a-zA-Z0-9]{7}\b|\b[a-zA-Z0-9]{32}\b/.test(device.key) &&
      type === "single"
    ) {
      errors = {
        ...errors,
        key: { status: true }
      };
      submitError = true;
    }
    if (!file && type === "multiple") {
      errors = {
        ...errors,
        file: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  const handleCreate = () => {
    setLoading(true);
    if (type === "single") {
      createDevice({
        variables: {
          serialnumber: device.serialnumber,
          productid: product,
          key: device.key
        },
        optimisticResponse: {
          __typename: "Mutation",
          createDevice: {
            device: {
              id: -1,
              serialnumber: device.serialnumber,
              __typename: "DeviceNode"
            },
            __typename: "createDevice"
          }
        }
      })
        .then(res => {
          onClose();
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
          setServerError({
            status: true,
            msg: "Device already exist."
          });
        });
    }
    if (type === "multiple") {
      const formData = new FormData();
      formData.append("importdata", file);
      formData.append("prodid", parseInt(atob(product).replace(/[^0-9]/g, "")));

      axios
        .post("http://127.0.0.1:8000/api/v1/products/deviceimport/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("ocelot-token")
          }
        })
        .then(response => {
          if (response.data.status === "ok") {
            if (response.data.count === 0) {
              setLoading(false);
              setServerError({
                status: true,
                msg: "All devices exist already"
              });
            } else {
              setLoading(false);
              onClose();
            }
          }
        })
        .catch(response => {
          setLoading(false);
          setServerError({
            status: true,
            msg:
              "An error happen when adding devices. Please contact support@obvious.xyz"
          });
        });
    }
  };

  const handleFile = value => {
    if (value) {
      let file = value[0];
      if (file) {
        setFile(file);

        setError({
          ...error,
          file: { status: false }
        });
      }
    }
  };

  return (
    <div>
      {serverError.status && (
        <Spacing bottom={4}>
          <Alert title={serverError.msg} danger full hideStatusIcon />
        </Spacing>
      )}
      <form
        id={id}
        method="post"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <ToggleButtonController
          value="single"
          name="button-group-controller"
          label="Add one or multiple devices?"
          onChange={value => setType(value)}
        >
          {ControlledButton => (
            <ButtonGroup>
              <ControlledButton value="single" key="single">
                Single
              </ControlledButton>
              <ControlledButton value="multiple" key="multiple">
                Multiple
              </ControlledButton>
            </ButtonGroup>
          )}
        </ToggleButtonController>
        {type === "multiple" ? (
          <>
            <Spacing bottom={2}>
              <Link
                openInNewWindow
                external
                href="/static/examples/testdevimport.csv"
              >
                Example CSV File
              </Link>
            </Spacing>
            <FileInput
              name="file"
              label="Device List CSV File"
              onChange={handleFile}
              accept=".csv"
              errorMessage="Device list is needed"
              invalid={error.file.status}
            />
          </>
        ) : (
          <div className={cx({ paddingBottom: 24 })}>
            <Input
              name="serialnumber"
              label="Serial Number"
              placeholder="3hj32h2j1h3j12h3j23j"
              value={device.serialnumber}
              onChange={updateField}
              errorMessage="Serial number is needed"
              invalid={error.serialnumber.status}
            />
            <Input
              name="key"
              label="Device Key"
              labelDescription="32-character alphanumeric"
              placeholder="A1b2C3d4E5f6G7h8I9j0K1l2M3n4O5p6"
              value={device.key}
              onChange={updateField}
              errorMessage="Device key is not valid"
              invalid={error.key.status}
            />
          </div>
        )}
        <FormActions
          continueText={`${
            type === "single" ? "Create device" : "Create multiple devices"
          }`}
          processingText={`Creating....`}
          processing={loading}
          onCancel={() => onClose()}
          onContinue={validate}
        />
      </form>
    </div>
  );
};

DeviceForm.propTypes = {};

DeviceForm.defaultProps = {};

export default DeviceForm;
