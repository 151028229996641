import React from "react";
import useTheme from "../../../../../core/src/hooks/useTheme";
import useStyles from "../../../../../core/src/hooks/useStyles";
import { getRolePermissions } from "../../../../../helpers";
import Card, {
  Content as CardContent
} from "../../../../../core/src/components/Card";
import Spacing from "../../../../../core/src/components/Spacing";
import Text from "../../../../../core/src/components/Text";
import Button from "../../../../../core/src/components/Button";
import { useMutation } from "@apollo/react-hooks";
import {
  GET_DFU_REPOSITORIES,
  DESTROY_DFU_REPOSITORY
} from "../../firmware/queries";
import MenuToggle from "../../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../icons/src/interface/IconMenuDots";
import Modal from "../../../../../core/src/components/Modal";
import FormActions from "../../../../../core/src/components/FormActions/index";
import FirmwareLibraryForm from "./FirmwareLibraryForm";
import axios from "axios";
import StatusLabel from "../../../../../core/src/components/StatusLabel";
import DateTime from "../../../../../core/src/components/DateTime";
import converter from "hex2dec";

const styleSheet = ({ color, unit }) => ({
  options: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingTop: unit,
    paddingRight: unit
  },
  flex: {
    display: "flex",
    itemsAligne: "center"
  }
});

const FirmwareDFULibrary = ({ repository, product, refetch }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [destroyVisable, setDestroyVisable] = React.useState(false);
  const [createVisable, setCreateVisable] = React.useState(false);

  const handleDownload = type => {
    setDownloading(true);
    axios({
      method: "GET",
      url: `/api/v1/products/dfudownload/`,
      responseType: "blob",
      params: {
        dfurepoid: repository.rowid
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ocelot-token")
      },
      data: {}
    }).then(response => {
      const attachmentName = response.request.getResponseHeader(
        "Content-Disposition"
      );
      const filename = attachmentName.split(/[""]/)[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        setDownloading(false);
      }, 1000);
    });
  };

  const [destroy] = useMutation(DESTROY_DFU_REPOSITORY, {
    update(cache, { data: { success } }) {
      const data = cache.readQuery({
        query: GET_DFU_REPOSITORIES,
        variables: { productid: product }
      });
      data.allDfuRepositories.edges = data.allDfuRepositories.edges.filter(
        edge => edge.node.id !== repository.id
      );
      cache.writeQuery({
        query: GET_DFU_REPOSITORIES,
        variables: { productid: product },
        data
      });
    }
  });

  const refetchBuilds = () => {
    refetch();
  };

  const destroyLibrary = () => {
    if (permissions.firmware.includes("DELETE")) {
      setLoading(true);
      destroy({
        variables: {
          id: repository.id,
          productid: product
        },
        optimisticResponse: {
          __typename: "Mutation",
          destroyProductDfuRepository: {
            ProductDFURepository: {
              id: repository.id,
              __typename: "ProductNode"
            },
            __typename: "destroyProductDfuRepository"
          }
        }
      });
      setDestroyVisable(false);
    }
  };

  return (
    <>
      <Card overflow>
        <div className={cx(styles.options)}>
          <MenuToggle
            accessibilityLabel="Actions"
            closeOnClick
            onShow={() => {}}
            onHide={() => {}}
            zIndex={50}
            toggleIcon={
              <IconMenuDots decorative color={theme.color.core.neutral[4]} />
            }
            toggleLabel="Actions"
          >
            {permissions.firmware.includes("CREATE") && (
              <MenuItem onClick={() => setCreateVisable(true)}>
                Build more like this
              </MenuItem>
            )}
            {permissions.firmware.includes("DELETE") && (
              <MenuItem danger onClick={() => setDestroyVisable(true)}>
                Delete {product.name}
              </MenuItem>
            )}
          </MenuToggle>
        </div>
        <CardContent truncated>
          <Text truncated large bold>
            <div
              className={cx({
                color: theme.color.core.primary[3]
              })}
            >
              {repository.dfudisplayname}
            </div>
          </Text>
          <Spacing bottom={2} top={1}>
            <div className={cx(styles.flex)}>
              <StatusLabel
                danger={!repository.dfubuildsuccessful}
                success={repository.dfubuildsuccessful}
              >
                {repository.dfubuildsuccessful
                  ? "Build Successful"
                  : "Build Failed"}
              </StatusLabel>
            </div>
          </Spacing>
          <Spacing bottom={1}>
            <div className={cx(styles.flex)}>
              <Text small bold>
                Date Created:
              </Text>
              <Spacing left={1}>
                <DateTime
                  at={new Date(repository.datecreated).getTime()}
                  short
                />
              </Spacing>
            </div>
          </Spacing>
          <Spacing top={1}>
            <Spacing bottom={1}>
              <div className={cx(styles.flex)}>
                <Text small bold>
                  SoftDevice:
                </Text>
                <Spacing left={1}>
                  <Text light>
                    {repository.dfubuildid.softdeviceid.softdevicename}
                  </Text>
                </Spacing>
              </div>
            </Spacing>
            <Spacing bottom={1}>
              <div className={cx(styles.flex)}>
                <Text small bold>
                  {repository.dfubuildid.softdeviceid.softdevicename} Version:
                </Text>
                <Spacing left={1}>
                  <Text light>
                    {repository.dfubuildid.softdeviceid.version}
                  </Text>
                </Spacing>
              </div>
            </Spacing>
            <Spacing bottom={1}>
              <div className={cx(styles.flex)}>
                <Text small bold>
                  Chip:
                </Text>
                <Spacing left={1}>
                  <Text light>{repository.dfubuildid.builddeviceid.name}</Text>
                </Spacing>
              </div>
            </Spacing>
            <Spacing bottom={1}>
              <div className={cx(styles.flex)}>
                <Text small bold>
                  Ocelot Flash Start:
                </Text>
                <Spacing left={1}>
                  <Text light>
                    {converter.decToHex(
                      repository.dfubuildid.ocelotflashstart.toString()
                    )}
                  </Text>
                </Spacing>
              </div>
            </Spacing>
            <Spacing bottom={1}>
              <div className={cx(styles.flex)}>
                <Text small bold>
                  Ocelot Ram Start:
                </Text>
                <Spacing left={1}>
                  <Text light>
                    {converter.decToHex(
                      repository.dfubuildid.ocelotresetsaferamstart.toString()
                    )}
                  </Text>
                </Spacing>
              </div>
            </Spacing>
          </Spacing>
          <Spacing top={3}>
            {repository.dfubuildsuccessful ? (
              <Button
                small
                onClick={() => handleDownload()}
                loading={downloading}
              >
                Download
              </Button>
            ) : (
              <Button small disabled inverted borderless color="secondary">
                Invalid
              </Button>
            )}
          </Spacing>
        </CardContent>
      </Card>
      {permissions.firmware.includes("DELETE") && destroyVisable && (
        <Modal
          title={`Delete ${repository.dfudisplayname}`}
          subtitle={"Warning, this step cannot be undone"}
          onClose={() => setDestroyVisable(false)}
        >
          <FormActions
            continueText={`Yes, remove ${repository.dfudisplayname}`}
            processingText="Removing..."
            processing={loading}
            onCancel={() => setDestroyVisable(false)}
            onContinue={destroyLibrary}
          />
        </Modal>
      )}

      {permissions.firmware.includes("CREATE") && createVisable && (
        <Modal
          title={"Generate DFU Firmware Library"}
          onClose={() => setCreateVisable(false)}
        >
          <FirmwareLibraryForm
            product={product}
            rebuild={repository}
            onClose={() => setCreateVisable(false)}
            refetch={refetchBuilds}
          />
        </Modal>
      )}
    </>
  );
};

export default FirmwareDFULibrary;
