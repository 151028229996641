import React from "react";
import _pt from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { DESTROY_PRODUCT, GET_PRODUCTS, GET_PRODUCT } from "./queries";
import useStyles from "../../core/src/hooks/useStyles";
import useTheme from "../../core/src/hooks/useTheme";
import Card, { Content as CardContent } from "../../core/src/components/Card";
import AdaptiveGrid from "../../core/src/components/AdaptiveGrid";
import Spacing from "../../core/src/components/Spacing";
import Text from "../../core/src/components/Text";
import Divider from "../../core/src/components/Divider";
import MenuToggle from "../../core/src/components/MenuToggle";
import MenuItem from "../../core/src/components/Menu/Item";
import IconMenuDots from "../../icons/src/interface/IconMenuDots";
import { toLocalNumber, getRolePermissions, getInitials } from "../../helpers";
import history from "../../history";
import Modal from "../../core/src/components/Modal";
import Form from "./Form";
import FormActions from "../../core/src/components/FormActions";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";

const styleSheet = ({ color, unit }) => ({
  options: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingTop: unit,
    paddingRight: unit
  },
  center: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100",
    marginBottom: unit
  }
});

const ProductItem = ({ product, ...props }) => {
  const permissions = getRolePermissions("products");
  const [loading, setLoading] = React.useState(false);
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [destroyVisable, setDestroyVisable] = React.useState(false);
  const [editVisable, setEditVisable] = React.useState(false);
  const [getProduct, setGetProduct] = React.useState(false);
  const { data } = useQuery(GET_PRODUCTS, {
    skip: !getProduct,
    options: { fetchPolicy: "network-only" }
  });
  const [destroy] = useMutation(DESTROY_PRODUCT, {
    update(cache, { data: { destroyProduct } }) {
      const data = cache.readQuery({
        query: GET_PRODUCTS
      });
      data.allProducts.edges = data.allProducts.edges.filter(
        edge => edge.node.id !== product.id
      );
      cache.writeQuery({
        query: GET_PRODUCTS,
        data
      });
    }
  });

  const handleClick = product => {
    setGetProduct(true);
    history.push(`/products/${product}`);
  };

  const destroyProduct = () => {
    if (permissions.includes("DELETE")) {
      setLoading(true);
      destroy({
        variables: {
          id: product.id
        },
        optimisticResponse: {
          __typename: "Mutation",
          destroyProduct: {
            product: {
              id: product.id,
              __typename: "ProductNode"
            },
            __typename: "destroyProduct"
          }
        }
      });
      setDestroyVisable(false);
    }
  };

  return (
    <div>
      <Card>
        <div className={cx(styles.options)}>
          <MenuToggle
            accessibilityLabel="Actions"
            closeOnClick
            zIndex={50}
            toggleIcon={
              <IconMenuDots
                size={24}
                decorative
                color={theme.color.core.neutral[4]}
              />
            }
            toggleLabel="Actions"
          >
            <MenuItem href={`/products/${product.id}`}>View</MenuItem>
            {permissions.includes("UPDATE") && (
              <MenuItem onClick={() => setEditVisable(true)}>Edit</MenuItem>
            )}
            {permissions.includes("DELETE") && (
              <MenuItem danger onClick={() => setDestroyVisable(true)}>
                Delete {product.name}
              </MenuItem>
            )}
          </MenuToggle>
        </div>
        <CardContent middleAlign onClick={() => handleClick(product.id)}>
          <Spacing all={2}>
            <div className={cx(styles.center)}>
              <ProfilePhoto size={10}>{getInitials(product.name)}</ProfilePhoto>
            </div>
            <Text truncated large bold centerAlign>
              {product.name}
            </Text>
            <div className={cx({ height: 16 })}>
              <Text centerAlign truncated>
                {product.description}
              </Text>
            </div>
            <Divider />
            <AdaptiveGrid defaultItemsPerRow={3}>
              <div>
                <Text micro muted uppercased>
                  Devices
                </Text>
                <Text>
                  {toLocalNumber(
                    product.devicesCount ? product.devicesCount : 0
                  )}
                </Text>
              </div>
              <div>
                <Text micro muted uppercased>
                  Features
                </Text>
                <Text>
                  {toLocalNumber(
                    product.featuresCount ? product.featuresCount : 0
                  )}
                </Text>
              </div>
              <div>
                <Text micro muted uppercased>
                  Groups
                </Text>
                <Text>
                  {toLocalNumber(product.groupsCount ? product.groupsCount : 0)}
                </Text>
              </div>
            </AdaptiveGrid>
          </Spacing>
        </CardContent>
      </Card>
      {permissions.includes("UPDATE") && editVisable && (
        <Modal title={"Add Product"} onClose={() => setEditVisable(false)}>
          <Form
            selectedProduct={product}
            onClose={() => setEditVisable(false)}
          />
        </Modal>
      )}
      {permissions.includes("DELETE") && destroyVisable && (
        <Modal
          title={`Delete ${product.name}`}
          subtitle={"Warning, this step cannot be undone"}
          onClose={() => setDestroyVisable(false)}
        >
          <FormActions
            continueText={`Yes, remove ${product.name}`}
            processingText="Removing..."
            processing={loading}
            onCancel={() => setDestroyVisable(false)}
            onContinue={destroyProduct}
          />
        </Modal>
      )}
    </div>
  );
};

ProductItem.propTypes = {};

ProductItem.defaultProps = {};

export default ProductItem;
