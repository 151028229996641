import Color from "color";

const buildDocumentStyles = _ref => {
  let { color, pattern, ui, unit, transition, font } = _ref;
  const link = {
    color: color.core.primary[3]
  };
  return {
    content: {
      "> h2": {
        marginBottom: unit * 2,
        marginTop: unit * 5,
        ...font.title2,
        ":first-child": {
          marginTop: unit * 3
        }
      },
      "> h2 > a": {
        color: color.core.primary[3]
      },
      "> h3": {
        marginBottom: unit * 2,
        marginTop: unit * 5,
        ...font.title3
      },
      "> h4": {
        ...font.textLarge,
        marginTop: unit * 5
      },
      "> h5": {
        ...font.textRegular,
        marginTop: unit * 2,
        marginBottom: unit,
        fontWeight: 700,
        color: color.core.neutral[4]
      },
      "> table": {
        marginTop: unit * 2,
        borderCollapse: "collapse",
        width: "100%",
        "> thead > tr > th": {
          paddingLeft: unit,
          paddingRight: unit,
          paddingTop: unit * 2,
          paddingBottom: unit * 2,
          ...font.textMicro,
          verticalAlign: "top",
          textAlign: "left",
          ":first-child": {
            paddingLeft: unit * 2
          }
        },
        "> tbody > tr": {
          border: 0,
          borderBottom: 1,
          borderColor: color.accent.border,
          borderStyle: "solid",
          "> td": {
            paddingLeft: unit,
            paddingRight: unit,
            paddingTop: unit * 2,
            paddingBottom: unit * 2,
            verticalAlign: "top",
            ...font.textRegular,
            ":first-child": {
              paddingLeft: unit * 2
            },
            "> a": {
              ...link
            },
            "> code": {
              witeSpace: "normal",
              padding: ".1em",
              borderRadius: unit,
              background: Color(color.core.primary[3])
                .alpha(0.2)
                .hsl()
                .string(),
              color: color.core.primary[5]
            }
          }
        }
      },
      "> ul": {
        marginRight: unit * 2,
        paddingLeft: unit * 2,
        marginBottom: unit * 2,
        listStyle: "circle",
        "> li": {
          marginTop: unit,
          ":first-child": {
            marginTop: 0
          },
          ":before": {
            content: ".",
            textIndent: unit * -2,
            display: "inline-block",
            color: color.core.primary[3],
            float: "left"
          },
          "> a": {
            ...link
          }
        }
      }
    }
  };
};

export default buildDocumentStyles;
