import React from "react";
import _pt from "prop-types";
import history from "../../../../history";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import PageHeader from "../../../../components/PageHeader";
import Row from "../../../../core/src/components/Row";
import Title from "../../../../core/src/components/Title";
import Text from "../../../../core/src/components/Text";
import Copy from "../../../../core/src/components/Copy";
import Spacing from "../../../../core/src/components/Spacing";
import AdaptiveGrid from "../../../../core/src/components/AdaptiveGrid";
import Card, {
  Content as CardContent
} from "../../../../core/src/components/Card";
import MenuToggle from "../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../icons/src/interface/IconMenuDots";
import Divider from "../../../../core/src/components/Divider";
import ProfilePhoto from "../../../../core/src/components/ProfilePhoto";
import ResponsiveImage from "../../../../core/src/components/ResponsiveImage";
import Shimmer from "../../../../core/src/components/Shimmer";
import Link from "../../../../core/src/components/Link";
import IconExternal from "../../../../icons/src/interface/IconExternal";
import StatusLabel from "../../../../core/src/components/StatusLabel/index";

const styleSheet = ({ color, unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 2
  }
});

const Capabilities = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [productID] = React.useState(match.params.product);

  const handleClick = product => {
    history.push(`/products/${productID}/capabilities/ant`);
  };

  return (
    <div>
      <PageHeader
        title="Capabilities"
        subtitle="License any of these third party capabilities for use in your devices"
      />
      <AdaptiveGrid defaultItemsPerRow={3}>
        <Card overflow>
          <CardContent onClick={() => handleClick()} truncated>
            <Spacing bottom={2}>
              <ResponsiveImage
                src="/static/brand/AntPlus.png"
                alt="Image"
                maxWidth={64}
                maxHeight={64}
                borderRadius={10}
              />
            </Spacing>
            <Text truncated large bold>
              <div
                className={cx({
                  color: theme.color.core.primary[3]
                })}
              >
                ANT+
              </div>
            </Text>
            <div
              className={cx({
                display: "flex",
                alignItems: "center",
                position: "relative",
                zIndex: 20
              })}
            >
              <Spacing right={0.5}>
                <IconExternal size={10} decorative />
              </Spacing>
              <Link
                external
                openInNewWindow
                small
                href="https://www.thisisant.com"
              >
                thisisant.com
              </Link>
            </div>

            <Spacing top={2}>
              <Text>
                Is the wireless technology that allows your monitoring devices
                to talk to each other.
              </Text>
            </Spacing>

            <Spacing top={2}>
              <StatusLabel info compact>
                <Text inverted micro bold>
                  Enabled
                </Text>
              </StatusLabel>
            </Spacing>
          </CardContent>
        </Card>
      </AdaptiveGrid>
    </div>
  );
};

Capabilities.propTypes = {};

Capabilities.defaultProps = {};

export default Capabilities;
