import Color from "color";
import { aesthetic } from "./core/src/hooks/useStyles";
import { roles } from "./constants";

export const toLocalNumber = value => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0
  });
};

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const primaryColorGenerator = value => {
  const mainColor = Color(value).hsl();

  return [
    mainColor.lighten(0.4).hex(),
    mainColor.lighten(0.25).hex(),
    mainColor.lighten(0.1).hex(),
    mainColor.hex(),
    mainColor.darken(0.25).hex(),
    mainColor.darken(0.4).hex()
  ];
};

export const changeThemeColors = value => {
  const colors = primaryColorGenerator(value);
  aesthetic.themes.light.color.core.primary = colors;
  aesthetic.themes.light.color.accent.borderActive = value;
  aesthetic.themes.light.pattern.focused.borderColor = value;
};

export const slugify = string => {
  const a =
    "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;";
  const b =
    "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const lowercaseNoSpace = string => {
  const a =
    "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;";
  const b =
    "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const getRolePermissions = location => {
  if (localStorage.getItem("user")) {
    const userRole = JSON.parse(localStorage.getItem("user")).userType;
    const userRules = location ? roles[userRole][location] : roles[userRole];
    return userRules ? userRules : undefined;
  }
};

export const getAllUrlParams = url => {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];

    // split our query string into its component parts
    var arr = queryString.split("&");

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=");

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

export const convertVersionToString = version => {
  // Split a given version string into three parts.
  let parts = version.split(".");
  // Check if we got exactly three parts, otherwise throw an error.
  if (parts.length !== 3) {
    console.log(parts.length);
    throw new Error("Received invalid version string");
  }

  let number = parts
    .map((group, index) => {
      const count = 3 - group.length;
      const zero = "0";
      return zero.repeat(count) + group;
    })
    .join("");

  return parseInt(number, 10);
};

export const convertIntegerToVersion = number => {
  const minor = number % 1000;
  const major = (number / 1000) % 1000;
  const release = (number / 1000000) % 1000;

  return `${parseInt(release, 10)}.${parseInt(major, 10)}.${parseInt(
    minor,
    10
  )}`;
};

export const getInitials = string => {
  const parts = string.split(" ");
  var initials = "";
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  return initials.toUpperCase();
};
