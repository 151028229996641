import React from "react";
import _pt from "prop-types";
import Text from "../../../../../../../core/src/components/Text";
import Spacing from "../../../../../../../core/src/components/Spacing";
import Link from "../../../../../../../core/src/components/Link";
import Grid, { Col } from "../../../../../../../core/src/components/Grid";
import List, { Item } from "../../../../../../../core/src/components/List";
import AdaptiveGrid from "../../../../../../../core/src/components/AdaptiveGrid";
import Card, {
  Content as CardContent
} from "../../../../../../../core/src/components/Card";
import Row from "../../../../../../../core/src/components/Row";

const Tools = ({}) => {
  const handleClick = () => {};
  return (
    <AdaptiveGrid defaultItemsPerRow={4}>
      <Card>
        <CardContent onClick={() => handleClick()} truncated>
          <Row>
            <Text bold>Developer Tool</Text>
            <Spacing top={1}>
              <Text small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Text>
            </Spacing>
          </Row>
        </CardContent>
      </Card>
    </AdaptiveGrid>
  );
};

Tools.propTypes = {};

Tools.defaultProps = {};

export default Tools;
