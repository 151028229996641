import gql from "graphql-tag";

const GET_PRODUCTS = gql`
  query allProducts {
    allProducts {
      edges {
        node {
          id
          name
          description
          featureregionsize
          devicesCount
          featuresCount
          groupsCount
        }
      }
    }
  }
`;

const GET_PRODUCT = gql`
  query($id: ID!) {
    product(id: $id) {
      id
      name
      description
      featureregionsize
      featureregionaddress
      productkey {
        edges {
          node {
            id
            encryptionkey
          }
        }
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String!
    $description: String!
    $featureregionsize: Int!
  ) {
    createProduct(
      data: {
        name: $name
        description: $description
        featureregionsize: $featureregionsize
      }
    ) {
      product {
        id
        name
        description
        featureregionsize
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: ID!
    $name: String!
    $description: String!
    $featureregionsize: Int!
  ) {
    updateProduct(
      data: {
        id: $id
        name: $name
        description: $description
        featureregionsize: $featureregionsize
      }
    ) {
      product {
        id
        name
        description
        featureregionsize
      }
    }
  }
`;

const DESTROY_PRODUCT = gql`
  mutation destroyGroup($id: ID!) {
    destroyProduct(data: { id: $id }) {
      product {
        id
      }
    }
  }
`;

export {
  GET_PRODUCTS,
  GET_PRODUCT,
  CREATE_PRODUCT,
  DESTROY_PRODUCT,
  UPDATE_PRODUCT
};
