import React from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  UPDATE_DEVICE_FEATURES,
  CREATE_DEVICE_FEATURES,
  GET_DEVICE
} from "../../queries";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../../core/src/hooks/useTheme";
import Row from "../../../../../../core/src/components/Row";
import Switch from "../../../../../../core/src/components/Switch";
import MenuToggle from "../../../../../../core/src/components/MenuToggle";
import MenuItem from "../../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../../icons/src/interface/IconMenuDots";
import { getRolePermissions } from "../../../../../../helpers";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Feature = ({ children, feature, product, device }) => {
  const permissions = getRolePermissions("product");
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [updateDeviceFeatures] = useMutation(UPDATE_DEVICE_FEATURES);
  const [createDeviceFeatures] = useMutation(CREATE_DEVICE_FEATURES, {
    update(
      cache,
      {
        data: { createDeviceActivation }
      }
    ) {
      const data = cache.readQuery({
        query: GET_DEVICE,
        variables: {
          productid: product,
          deviceid: device
        }
      });

      data.device.active.edges.push({
        node: {
          id: createDeviceActivation
            ? createDeviceActivation.deviceActivation.id
            : -1,
          deviceid: device,
          featureid: feature.rowid,
          expirydate: "ffffffff",
          __typename: "DeviceActivationNode"
        },
        __typename: "DeviceActivationNodeEdge"
      });

      cache.writeQuery({
        query: GET_DEVICE,
        variables: {
          productid: product,
          deviceid: device
        },
        data
      });
    }
  });

  const toggleStatus = () => {
    if (permissions.devices.includes("UPDATE")) {
      feature.expirydate
        ? updateDeviceFeatures({
            variables: {
              id: feature.activatedid,
              productid: product,
              deviceid: device,
              expirydate: feature.expirydate === "ffffffff" ? "0" : "ffffffff"
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateDeviceActiviation: {
                deviceActivation: {
                  expirydate:
                    feature.expirydate === "ffffffff" ? "ffffffff" : "0",
                  featureid: feature.id,
                  id: feature.activatedid,
                  __typename: "DeviceActivationNode"
                },
                __typename: "UpdateDeviceActivation"
              }
            }
          })
        : createDeviceFeatures({
            variables: {
              featureid: feature.id,
              productid: product,
              deviceid: device
            },
            optimisticResponse: {
              __typename: "Mutation",
              createDeviceActiviation: {
                deviceActivation: {
                  expirydate: "ffffffff",
                  featureid: feature.id,
                  id: -1,
                  __typename: "DeviceActivationNode"
                },
                __typename: "CreateDeviceActivation"
              }
            }
          });
    }
  };

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <Row
        after={
          <div className={cx(styles.options)}>
            <div className={cx(styles.options_items)}>
              <Switch
                label="feature active status"
                hideLabel
                noSpacing
                checked={feature.expirydate === "ffffffff"}
                onChange={toggleStatus}
              />
            </div>
            {(permissions.devices.includes("UPDATE") ||
              permissions.devices.includes("DELETE")) && (
              <MenuToggle
                accessibilityLabel="Actions"
                closeOnClick
                toggleIcon={
                  <IconMenuDots
                    decorative
                    color={theme.color.core.neutral[4]}
                  />
                }
                toggleLabel="Actions"
                zIndex={10}
                onShow={handleShow}
                onHide={handleHide}
              >
                {permissions.devices.includes("UPDATE") && (
                  <MenuItem
                    onClick={() => {
                      toggleStatus();
                    }}
                  >
                    {feature.expirydate === "ffffffff"
                      ? "Deactivate"
                      : "Activate"}
                  </MenuItem>
                )}
              </MenuToggle>
            )}
          </div>
        }
      >
        {children}
      </Row>
    </div>
  );
};

export default Feature;
