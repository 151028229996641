import gql from "graphql-tag";

const VERIFY_DEMO_CODE = gql`
  query demoCode($democode: String) {
    demoCode(democode: $democode) {
      id
      manufacturerid
      userid
    }
  }
`;

const CREATE_DEMO_USER = gql`
  mutation createDemoUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $democodeid: ID!
    $manufacturer: Int!
  ) {
    createDemoUser(
      data: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
        democodeid: $democodeid
        manufacturer: $manufacturer
      }
    ) {
      success
      errors
    }
  }
`;

export { VERIFY_DEMO_CODE, CREATE_DEMO_USER };
