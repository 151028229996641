import React from "react";
import useStyles from "../../core/src/hooks/useStyles";
import Layout from "../../layout/src/components/Layout";
import MainMenu from "../../components/MainMenu";
import DevicesList from "./devices";
import SDK from "./sdk";
import NRFConnect from "./nrfconnect";

import Content from "../../components/Content";
import { Switch, Route, Redirect } from "react-router-dom";
import CommandLineTool from "./commandlinetool";
import TopMenu from "../../components/TopMenu";
import PageHeader from "../../components/PageHeader";
import Tabs, { Item } from "../../components/Tabs";

const styleSheet = () => ({
  flex: {
    display: "flex"
  }
});

const Tools = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  return (
    <Layout noPadding noMaxwidth relative before={<MainMenu />}>
      <TopMenu />
      <Content>
        <PageHeader
          title="Tools"
          tabs={
            <Tabs>
              <Item name="Product File Generator" to={`/tools/sdk`} />
              <Item name="Browser DFU" to={`/tools/browserdfu`} />
              <Item name="CLI" to={`/tools/commandlinetools`} />
            </Tabs>
          }
        />
        <Switch>
          <Redirect exact from={`/tools`} to={`/tools/sdk`} />
          <Route
            path={`/tools/browserdfu`}
            render={props => <DevicesList {...props} />}
          />
          <Route path={`/tools/sdk`} render={props => <SDK {...props} />} />
          <Route
            path={`/tools/nrfconnect`}
            render={props => <NRFConnect {...props} />}
          />
          <Route
            path={`/tools/commandlinetools`}
            render={props => <CommandLineTool {...props} />}
          />
        </Switch>
      </Content>
    </Layout>
  );
};

export default Tools;
