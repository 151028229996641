import React from "react";
import _pt from "prop-types";
import uuid from "uuid/v4";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_MEMBER } from "../queries";
import FormActions from "../../../core/src/components/FormActions";
import Select from "../../../core/src/components/Select";

const roles = [
  { id: 1, name: "Observer" },
  { id: 2, name: "Developer" },
  { id: 3, name: "Developer Admin" },
  { id: 4, name: "Customer Service" },
  { id: 5, name: "Product Manager" }
];

const EditForm = ({ selectedMember, onClose, ...props }) => {
  const [id] = React.useState(uuid());
  const [loading, setLoading] = React.useState(false);
  const [member, setMember] = React.useState({
    role: 1,
    roleName: "Observer"
  });
  const [error, setError] = React.useState({
    email: { status: false },
    message: { status: false },
    role: { status: false }
  });

  const [updateMember] = useMutation(UPDATE_MEMBER);

  React.useEffect(() => {
    if (selectedMember) {
      setMember({
        role: selectedMember.usertype.rowid,
        roleName: selectedMember.usertype.name
      });
    }
  }, []);

  const handleSubmit = data => {
    setLoading(true);

    return Promise.resolve(props.onSubmit(data, props)).finally(() => {
      setLoading(false);
    });
  };

  const updateField = (value, event) => {
    console.log(event.target);
    const target = document.getElementsByName("role");
    setMember({
      ...member,
      [event.target.name]: value,
      roleName: roles[value - 1].name
    });
    setError({
      ...error,
      [event.target.name]: { status: false }
    });
  };

  const validate = e => {
    e.preventDefault();
    let submitError = false;
    var errors = {};

    if (!member.role) {
      errors = {
        ...errors,
        role: { status: true }
      };
      submitError = true;
    }

    if (submitError) {
      setError({ ...error, ...errors });
      return;
    }

    handleCreate();
  };

  console.log(member);

  const handleCreate = () => {
    setLoading(true);

    updateMember({
      variables: {
        id: selectedMember.id,
        role: member.role
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateManufacturerMember: {
          manufacturerMember: {
            id: selectedMember.id,
            userType: {
              name: member.role,
              rowid: member.role
            },
            __typename: "ManufacturerMemberNode"
          },
          __typename: "UpdateManufacturerMember"
        }
      }
    })
      .then(res => {
        onClose();
      })
      .catch(error => {
        setLoading(false);
      });
  };

  return (
    <form
      id={id}
      method="post"
      encType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Select
        name="role"
        value="User Role"
        placeholder="Please select a role"
        value={member.role}
        onChange={updateField}
        errorMessage="A role is needed"
        invalid={error.role.status}
      >
        <option value="1">Observer</option>
        <option value="2">Developer</option>
        <option value="3">Developer Admin</option>
        <option value="4">Customer Service</option>
        <option value="5">Product Manager</option>
      </Select>
      <FormActions
        continueText={`Update ${selectedMember.account.firstName}`}
        processingText={`Updating...`}
        processing={loading}
        onCancel={() => onClose()}
        onContinue={validate}
      />
    </form>
  );
};

EditForm.propTypes = {};

EditForm.defaultProps = {};

export default EditForm;
