import React from "react";
import { Link } from "react-router-dom";
import useStyles from "../../../../../core/src/hooks/useStyles";
import useTheme from "../../../../../core/src/hooks/useTheme";
import Row from "../../../../../core/src/components/Row";
import MenuToggle from "../../../../../core/src/components/MenuToggle/index";
import MenuItem from "../../../../../core/src/components/Menu/Item";
import IconMenuDots from "../../../../../icons/src/interface/IconMenuDots";
import DateTime from "../../../../../core/src/components/DateTime";

const styleSheet = ({ color, unit }) => ({
  feature: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  }
});

const Device = ({ children, device, product }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();

  const handleHide = () => {};
  const handleShow = () => {};

  return (
    <div className={cx(styles.feature)}>
      <Row
        after={
          <div className={cx(styles.options)}>
            <div className={cx(styles.options_items)}>
              {<DateTime at={new Date(device.datecreated).getTime()} short />}
            </div>

            <MenuToggle
              accessibilityLabel="Actions"
              closeOnClick
              toggleIcon={
                <IconMenuDots decorative color={theme.color.core.neutral[4]} />
              }
              toggleLabel="Actions"
              zIndex={10}
              onShow={handleShow}
              onHide={handleHide}
            >
              <MenuItem href={`/products/${product}/devices/${device.id}`}>
                View
              </MenuItem>
            </MenuToggle>
          </div>
        }
      >
        <Link to={`/products/${product}/devices/${device.id}`}>{children}</Link>
      </Row>
    </div>
  );
};

export default Device;
