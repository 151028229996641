import gql from "graphql-tag";

const GET_TRANSACTIONS = gql`
  query allTransactions(
    $after: String
    $before: String
    $serialnumber: String
  ) {
    allTransactions(
      first: 20
      after: $after
      before: $before
      serialnumber_Istartswith: $serialnumber
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          id
          serialnumber
          product
          transactionItemsCount
          transactiontotal
          transactionstatus
          datecreated
          rowid
        }
      }
    }
  }
`;

const GET_TRANSACTION = gql`
  query transaction($id: ID!) {
    transaction(id: $id) {
      id
      productid {
        id
        name
      }
      serialnumber
      transactionitems {
        edges {
          node {
            id
            featureid
            itemprice
          }
        }
      }
      transactiontotal
      transactionstatus
      datecreated
      rowid
    }
  }
`;

const GET_FEATURES = gql`
  query allProductFeatures($productid: ID!) {
    allProductFeatures(productid: $productid) {
      edges {
        node {
          id
          name
          description
          deactivated
          defaultfeature
          rowid
        }
      }
    }
    allReservedFeatures {
      edges {
        node {
          rowid
          id
          name
          productreserved(productid: $productid) {
            edges {
              node {
                id
                deactivated
              }
            }
          }
        }
      }
    }
  }
`;

export { GET_TRANSACTIONS, GET_TRANSACTION, GET_FEATURES };
