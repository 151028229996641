import Core from "../../src";

export default class MessageBundle {
  constructor(messages, options) {
    if (!options) {
      options = {};
    }
    this.messages = messages;
    this.options = options;
  }

  get(locale) {
    const message = // Try to use localized message first
      this.messages[locale || Core.locale()] || // Or use default if defined
      this.options.default || // Lastly, fallback to English
      this.messages.en;

    return message;
  }
}
