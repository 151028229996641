import React from "react";
import useStyles from "../../../../../core/src/hooks/useStyles";
import Text from "../../../../../core/src/components/Text/index";
import Row from "../../../../../core/src/components/Row";
import Group from "./Group";
import BaseGroup from "./BaseGroup";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  options: {
    width: 24
  }
});

const GroupsList = ({ children }) => {
  const [styles, cx] = useStyles(styleSheet);

  return (
    <div className="">
      <div className={cx(styles.header)}>
        <Row
          after={
            <div className={cx(styles.header_children)}>
              <div className={cx(styles.header_items)}>
                <Text small>Priority</Text>
              </div>
              <div className={cx(styles.options)} />
            </div>
          }
        >
          <Text small>Name & Description</Text>
        </Row>
      </div>
      {children}
    </div>
  );
};

export { Group, BaseGroup };
export default GroupsList;
