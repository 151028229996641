import React from "react";
import useStyles from "../../../../../../core/src/hooks/useStyles";
import Item from "./Item";
import Text from "../../../../../../core/src/components/Text/index";
import Row from "../../../../../../core/src/components/Row";
import AdaptiveGrid from "../../../../../../core/src/components/AdaptiveGrid";

const styleSheet = ({ color, unit }) => ({
  header: {
    paddingLeft: unit * 1.5,
    paddingRight: unit * 1.5,
    paddingBottom: unit * 1.5
  },
  header_children: {
    display: "flex",
    alignItems: "center"
  },
  header_items: {
    marginRight: unit * 4
  },
  options: {
    width: 24
  }
});

const List = ({ children }) => {
  const [styles, cx] = useStyles(styleSheet);

  return <AdaptiveGrid defaultItemsPerRow={3}>{children}</AdaptiveGrid>;
};

export { Item };
export default List;
