import React from "react";
import _pt from "prop-types";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_TRANSACTION, GET_FEATURES } from "../queries";
import useStyles from "../../../../core/src/hooks/useStyles";
import useTheme from "../../../../core/src/hooks/useTheme";
import PageHeader from "../../../../components/PageHeader";
import LogoLoader from "../../../../core/src/components/LogoLoader";
import Breadcrumbs, {
  Breadcrumb
} from "../../../../core/src/components/Breadcrumbs";
import Text from "../../../../core/src/components/Text";
import Row from "../../../../core/src/components/Row";
import Title from "../../../../core/src/components/Title";
import Spacing from "../../../../core/src/components/Spacing";
import Price from "../../../../core/src/components/Price";
import Tooltip from "../../../../core/src/components/Tooltip";
import Item from "./Item";

const styleSheet = ({ color, unit }) => ({
  row_wrapper: {
    padding: unit * 1.5,
    backgroundColor: "#ffffff",
    borderRadius: unit,
    marginBottom: unit
  },
  options: {
    display: "flex",
    alignItems: "center"
  },
  options_items: {
    marginRight: unit * 4
  },
  status: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 80
  },
  status_circle: {
    width: unit,
    height: unit,
    borderRadius: "100%",
    marginRight: unit
  }
});

const TransactionDetail = ({ match }) => {
  const [styles, cx] = useStyles(styleSheet);
  const theme = useTheme();
  const [transactionID] = React.useState(match.params.transaction);
  const [loading, setLoading] = React.useState(true);
  const { loading: transactionLoading, data: transactionData } = useQuery(
    GET_TRANSACTION,
    {
      variables: { id: transactionID },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const { loading: featuresLoading, data } = useQuery(GET_FEATURES, {
    variables: {
      productid:
        transactionData &&
        transactionData.transaction &&
        transactionData.transaction.productid.id
    },
    skip: transactionData && !transactionData.transaction,
    options: {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    }
  });

  const status = [
    {
      color: theme.color.core.secondary[3],
      name: "Started",
      description: "The transaction was started"
    },
    {
      color: theme.color.core.primary[3],
      name: "Paying",
      description: "The transaction is being sent to payment system"
    },
    {
      color: theme.color.core.success[3],
      name: "Success",
      description: "The transaction was completed successfully"
    },
    {
      color: theme.color.core.danger[3],
      name: "Failed",
      description: "The transaction failed"
    },
    {
      color: theme.color.core.warning[3],
      name: "Pending",
      description: "The transaction was submitted and is PENDING processing"
    }
  ];

  React.useEffect(() => {
    if (data && data.allProductFeatures && data.allReservedFeatures) {
      setLoading(false);
    }
  }, [data]);

  const getFeatureName = featureid => {
    const features = data.allProductFeatures.edges
      .filter(({ node }) => {
        return node.rowid === featureid && node.name;
      })
      .map(({ node }) => {
        return node.name;
      })
      .join();
    const reserved = data.allReservedFeatures.edges
      .filter(({ node }) => {
        return node.rowid === featureid && node.name;
      })
      .map(({ node }) => {
        return node.name;
      })
      .join();
    return features ? features : reserved;
  };

  return loading ? (
    <LogoLoader />
  ) : (
    <div>
      <PageHeader
        title="Transaction"
        breadcrumbs={
          <Breadcrumbs accessibilityLabel="Breadcrumb">
            <Breadcrumb
              large
              label="Transactions"
              href={`/sales/transactions`}
            />
            <Breadcrumb
              large
              highlighted
              hideIcon
              label={transactionData.transaction.rowid}
            />
          </Breadcrumbs>
        }
      />
      <div className={cx(styles.row_wrapper)}>
        <Row>
          <Title level={3}>Transaction Details</Title>

          <Spacing vertical={2}>
            <Text bold>Serial number</Text>
            <Text>{transactionData.transaction.serialnumber}</Text>
          </Spacing>
          <Spacing bottom={2}>
            <Text bold>Product</Text>
            <Text>{transactionData.transaction.productid.name}</Text>
          </Spacing>
          <Spacing bottom={2}>
            <Text bold>Total Price</Text>
            <Text>
              <Price amount={transactionData.transaction.transactiontotal} />
            </Text>
          </Spacing>
          <Tooltip
            content={
              status[transactionData.transaction.transactionstatus].description
            }
          >
            <div className={cx(styles.status)}>
              <div
                className={cx(styles.status_circle, {
                  backgroundColor:
                    status[transactionData.transaction.transactionstatus].color
                })}
              ></div>
              <Text>
                {status[transactionData.transaction.transactionstatus].name}
              </Text>
            </div>
          </Tooltip>
        </Row>
      </div>
      <Spacing vertical={4}>
        <Title level={3}>Features</Title>
        <Spacing top={2}>
          {transactionData.transaction.transactionitems.edges.map(
            ({ node: item }) => {
              return (
                <Item key={item.id}>
                  <div
                    className={cx({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    })}
                  >
                    <Text bold>
                      {item.featureid !== null
                        ? getFeatureName(item.featureid)
                        : "Feature does not exist anymore"}
                    </Text>
                    <Text bold>{<Price amount={item.itemprice} />}</Text>
                  </div>
                </Item>
              );
            }
          )}
        </Spacing>
      </Spacing>
    </div>
  );
};

TransactionDetail.propTypes = {};

TransactionDetail.defaultProps = {};

export default withRouter(TransactionDetail);
