import React from "react";
import _pt from "prop-types";
import ErrorBoundary from "../components/ErrorBoundary";
import TrackingBoundary from "../components/TrackingBoundary";
import finishHOC from "../utils/finishHOC";
import Core from "../../src";

/* eslint-disable react/jsx-handler-names */
const withBoundary = name => {
  /* infer */
  const withBoundaryFactory = WrappedComponent => {
    const WithBoundary = ({ trackingName, ...props }) => {
      return (
        <TrackingBoundary name={trackingName || name}>
          <ErrorBoundary name={trackingName || name} onCatch={Core.log}>
            <WrappedComponent {...props} />
          </ErrorBoundary>
        </TrackingBoundary>
      );
    };

    WithBoundary.propTypes = {
      trackingName: _pt.string
    };

    WithBoundary.defaultProps = {
      trackingName: null
    };

    return finishHOC("withBoundary", WithBoundary, WrappedComponent);
  };

  return withBoundaryFactory;
};

export default withBoundary;
