import React from "react";
import _pt from "prop-types";
import useStyles from "../../core/src/hooks/useStyles";
import Chip from "../../core/src/components/Chip";
import ProfilePhoto from "../../core/src/components/ProfilePhoto";
import Tooltip from "../../core/src/components/Tooltip";

const styleSheet = ({ unit, font, color }) => ({
  page_header: {
    paddingBottom: unit * 4,
    marginBottom: unit * 6
  },
  page_header_no_padding: {
    marginBottom: 0,
    paddingBottom: 0
  },
  page_header_tabs: {
    paddingBottom: 0,
    borderBottom: "solid 2px",
    borderColor: color.core.neutral[1]
  },
  flex_center: {
    display: "flex",
    alignItems: "center"
  },
  flex_grow: {
    flexGrow: 1,
    width: "100%"
  },
  end: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexShrink: 0
  },
  logo: {
    width: "auto",
    height: unit * 8,
    marginRight: unit * 4
  },
  initial: {
    marginRight: unit * 2
  },
  page_header_title: {
    ...font.textReset,
    ...font.title3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: 1.25
  },
  page_header_subtitle: {
    ...font.textReset,
    ...font.textRegular,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginTop: 0,
    lineHeight: 1.25
  }
});

const PageHeader = ({
  initials,
  tooltip,
  tabs,
  logo,
  title,
  tags,
  subtitle,
  breadcrumbs,
  end,
  id,
  noPadding
}) => {
  const [styles, cx] = useStyles(styleSheet);
  let Initial = null;
  if (initials) Initial = <ProfilePhoto>{initials}</ProfilePhoto>;

  return (
    <div
      className={cx(
        styles.page_header,
        noPadding && styles.page_header_no_padding,
        tabs && styles.page_header_tabs
      )}
    >
      <div className={cx(styles.flex_center)}>
        {logo && !initials && (
          <img className={cx(styles.logo)} src={logo} alt={`${title} logo`} />
        )}
        {initials && !logo && (
          <div className={cx(styles.initial)}>
            {tooltip ? <Tooltip content={tooltip}>{Initial}</Tooltip> : Initial}
          </div>
        )}
        <div className={cx(styles.flex_grow)}>
          <div className={cx(styles.flex_center)}>
            {breadcrumbs ? (
              <div className={cx({ display: "flex", alignItems: "center" })}>
                {breadcrumbs}
              </div>
            ) : (
              <h1 className={cx(styles.page_header_title)}>{title}</h1>
            )}
            {tags &&
              tags.map(tag => {
                return <Chip>{tag.name}</Chip>;
              })}
          </div>
          {subtitle && (
            <p className={cx(styles.page_header_subtitle)}>{subtitle}</p>
          )}
        </div>
        {end && <div className={cx(styles.end)}>{end}</div>}
      </div>
      {tabs}
      <div id={id}></div>
    </div>
  );
};

PageHeader.propTypes = {};

PageHeader.defaultProps = {};

export default PageHeader;
