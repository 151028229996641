import React from "react";
import useTheme from "../../../../../core/src/hooks/useTheme";
import useStyles from "../../../../../core/src/hooks/useStyles";
import { getRolePermissions } from "../../../../../helpers";
import AdaptiveGrid from "../../../../../core/src/components/AdaptiveGrid";
import { useQuery } from "@apollo/react-hooks";
import { GET_DFU_REPOSITORIES } from "../../firmware/queries";
import LogoLoader from "../../../../../core/src/components/LogoLoader";
import FirmwareLibraryItem from "./FirmwareLibraryItem";

const styleSheet = ({ color, unit }) => ({});

const FirmwareLibrary = ({ children, product, fetch }) => {
  const [refetching, setRefetching] = React.useState(false);
  const { loading, data, refetch, networkStatus } = useQuery(
    GET_DFU_REPOSITORIES,
    {
      variables: { productid: product },
      options: {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
      }
    }
  );

  const refetchQuery = () => {
    setRefetching(true);
    refetch();
  };

  React.useEffect(() => {
    setRefetching(false);
  }, [data]);

  return loading || refetching ? (
    <LogoLoader />
  ) : (
    <AdaptiveGrid defaultItemsPerRow={3}>
      {data &&
        data.allDfuRepositories.edges
          .filter(({ node }) => {
            return node.dfubuildid.dfubuildtypeid.rowid === 1;
          })
          .map(({ node }) => (
            <FirmwareLibraryItem
              repository={node}
              product={product}
              key={node.id}
              refetch={refetchQuery}
            />
          ))}
    </AdaptiveGrid>
  );
};

export default FirmwareLibrary;
