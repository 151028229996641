import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import LogoLoader from "../../../core/src/components/LogoLoader";
import Button from "../../../core/src/components/Button";
import Modal from "../../../core/src/components/Modal";
import gql from "graphql-tag";
import useStyles from "../../../core/src/hooks/useStyles";
import ChromeDfu from "./dfu-lib/dfu-via-chrome";
import Input from "../../../core/src/components/Input";
import Tool from "../Tool";
import ProgressBar from "../../../core/src/components/ProgressBar/index";
import FileInput from "../../../core/src/components/FileInput";
import ButtonGroup from "../../../core/src/components/ButtonGroup";
import Text from "../../../core/src/components/Text";
import Spacing from "../../../core/src/components/Spacing";
import Title from "../../../core/src/components/Title";

const styleSheet = () => ({
  mainDfuDiv: {},
  uploadFile: {
    height: 200,
    width: "100%",
    border: "1px solid rgba(0,0,0,0.3)",
    padding: 8
  }
});

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

const STATE_PICKFILE = 1;
const STATE_PICKMETHOD = 2;
const STATE_UPLOADING = 3;
const STATE_DONE = 4;
const STATE_DONE_FAIL = 5;

const ToolsDfu = ({ match, location, inputDevice }) => {
  const [styles, cx] = useStyles(styleSheet);
  const [currentState, setCurrentState] = React.useState(STATE_PICKFILE);
  const [firmwareBase64, setCurrentFirmware] = React.useState(undefined);
  const [currentProgressString, setProgress100] = React.useState(0);
  const [currentLog, setCurrentLog] = React.useState([]);
  const [showingNameModal, setShowingNameModal] = React.useState(false);
  const [exactDfuName, setExactDfuName] = React.useState("");

  const onChangeFile = (value, event) => {
    const files = value;
    const fr = new FileReader();
    fr.onload = theFile => {
      const base64 = arrayBufferToBase64(theFile.target.result);

      console.log("firmware loaded");
      setCurrentFirmware(base64);
      console.log("no really, it's loaded");

      setCurrentState(STATE_PICKMETHOD);
    };
    fr.readAsArrayBuffer(files[0]);
  };

  const onNewStatus = (...args) => {
    setCurrentLog(currentLog.concat(args));
    console.log.apply(console, args);
  };
  const onNewProgress = progress100 => {
    console.log("progress: ", progress100);

    setProgress100(progress100.toFixed(1) + "%");
  };

  const onFreshStart = () => {
    setCurrentLog([]);
    setCurrentState(STATE_UPLOADING);
    const chromeDfu = new ChromeDfu(firmwareBase64, onNewStatus, onNewProgress);
    chromeDfu.startFresh("cycling_power").then(
      () => {
        setCurrentState(STATE_DONE);
      },
      failure => {
        setCurrentState(STATE_DONE_FAIL);
      }
    );
  };
  const onApplyToInputDevice = () => {
    setCurrentLog([]);
    setCurrentState(STATE_UPLOADING);
    const chromeDfu = new ChromeDfu(firmwareBase64, onNewStatus, onNewProgress);
    chromeDfu.startFreshOnPreconnectedDevice(inputDevice).then(
      () => {
        setCurrentState(STATE_DONE);
      },
      failure => {
        setCurrentState(STATE_DONE_FAIL);
      }
    );
  };
  const onResumeInputDevice = () => {
    setCurrentLog([]);
    setCurrentState(STATE_UPLOADING);
    const chromeDfu = new ChromeDfu(firmwareBase64, onNewStatus, onNewProgress);
    chromeDfu.resumeFromDfuMode(inputDevice.name).then(
      () => {
        setCurrentState(STATE_DONE);
      },
      failure => {
        setCurrentState(STATE_DONE_FAIL);
      }
    );
  };
  const onResumeDfu = () => {
    setShowingNameModal(true);
  };
  const doneSettingName = success => {
    setCurrentLog([]);
    setCurrentState(STATE_UPLOADING);
    setShowingNameModal(false);

    const chromeDfu = new ChromeDfu(firmwareBase64, onNewStatus, onNewProgress);

    const nameToUse = exactDfuName.length > 0 ? exactDfuName : undefined;
    chromeDfu.resumeFromDfuMode(nameToUse).then(
      () => {
        setCurrentState(STATE_DONE);
      },
      failure => {
        if (failure.name === "NotFoundError") {
          // this just means the user canceled
          console.log("User canceled the selector");
          setCurrentState(STATE_PICKMETHOD);
        } else {
          debugger;
          setCurrentState(STATE_DONE_FAIL);
        }
      }
    );
  };
  const backToStep1 = () => {
    setCurrentFirmware(undefined);
    setCurrentState(STATE_PICKFILE);
  };
  const backToStep2 = () => {
    setCurrentState(STATE_PICKMETHOD);
  };
  const updateExactDfuName = (value, event) => {
    console.log(value, event);
    setExactDfuName(value);
  };
  const cancelSettingName = () => {
    setCurrentState(STATE_PICKMETHOD);
  };

  const uploadFile =
    currentState === STATE_PICKFILE ? (
      <>
        <Spacing bottom={2}>
          <Text small bold>
            Select firmware
          </Text>
        </Spacing>
        <FileInput
          onChange={onChangeFile}
          hideFileType
          hideLastModified
          hideFileSize
        />
      </>
    ) : (
      undefined
    );

  const pickMethod =
    currentState === STATE_PICKMETHOD ? (
      <>
        {inputDevice && (
          <>
            <Spacing bottom={2}>
              <Text small bold>
                Start firmware update
              </Text>
            </Spacing>
            <ButtonGroup stacked>
              <Button block onClick={onApplyToInputDevice}>
                Update
              </Button>
              <Button block onClick={onResumeInputDevice}>
                Resume Interrupted DFU
              </Button>
            </ButtonGroup>
          </>
        )}
        {!inputDevice && (
          <>
            <p>If your device is fresh:</p>
            <Button onClick={onFreshStart}>Select Device and Upload</Button>
            <p>If your device is mid-firmware-update:</p>
            <Button onClick={onResumeDfu}>Select Device and Upload</Button>

            {showingNameModal && (
              <Modal title={"Enter Exact DFU Name"} onClose={() => {}}>
                <Input
                  name="exactDfuName"
                  autoComplete="Fliiiight DFU"
                  value={exactDfuName}
                  onChange={updateExactDfuName}
                />
                <Button onClick={cancelSettingName}>Cancel</Button>
                <Button onClick={doneSettingName}>Ok</Button>
              </Modal>
            )}
          </>
        )}
      </>
    ) : (
      undefined
    );

  const progress =
    currentState === STATE_UPLOADING ? (
      <>
        <Spacing bottom={2}>
          <Text small bold>
            {currentProgressString} uploaded
          </Text>
        </Spacing>
        <ProgressBar percent={parseInt(currentProgressString)} />
      </>
    ) : (
      undefined
    );

  const stateDone =
    currentState === STATE_DONE ? (
      <>
        <Spacing bottom={2}>
          <Text small bold>
            Your firmware update is complete!
          </Text>
        </Spacing>
        <ButtonGroup stacked>
          <Button block onClick={backToStep1}>
            Pick a New File
          </Button>
          <Button block onClick={backToStep2}>
            Upload the same file again
          </Button>
        </ButtonGroup>
      </>
    ) : (
      undefined
    );

  const stateDoneFail =
    currentState === STATE_DONE_FAIL ? (
      <>
        <Spacing bottom={2}>
          <Text small bold>
            Your firmware update is complete!
          </Text>
        </Spacing>
        <ButtonGroup stacked>
          <Button block onClick={backToStep1}>
            Pick a New File
          </Button>
          <Button block onClick={backToStep2}>
            Upload the same file again
          </Button>
        </ButtonGroup>
      </>
    ) : (
      undefined
    );

  return (
    <div>
      <Spacing bottom={2}>
        <Title level={3}>DFU</Title>
      </Spacing>

      {uploadFile}
      {pickMethod}
      {progress}
      {stateDone}
      {stateDoneFail}
    </div>
  );
};

export default withRouter(ToolsDfu);
